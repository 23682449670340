import React, { useEffect, useState } from 'react';
import styles from './EnergyPerformanceReport.module.scss';
import { useParams } from 'react-router-dom';

import OrganisationActions from '../../../actions/organisationActions';
import EnergyReviewActions from '../../../actions/energyReviewActions';
import EnergyReviewStore from '../../../stores/energyReviewStore';

import UserStore from '../../../stores/userStore';

import { DocumentTitle } from '../../../components/DocumentTitle';
import { LogoSpinner } from '../../../components/LogoSpinner';
import { Icon } from '../../../components/Icon';
import { Row, Col, Button, Select } from 'antd';
import { EnergyPerformanceTable } from './EnergyPerformanceTable';
import { EnergyPerformanceConfig } from './EnergyPerformanceConfig';
import { Modal, Input } from 'antd';

const EnergyPerformanceReport = () => {
    const { token } = useParams();
    const [isSuper] = useState(UserStore.isSuper());
    const [isAdmin] = useState(UserStore.isAdmin());
    const [isLoggedIn] = useState(UserStore.loggedIn());
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const [menuOpen, setMenuOpen] = useState(true);
    const [orgs, setOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(UserStore.getProfile().fk_organisation_id);

    const [selectedListRef, setSelectedListRef] = useState(null);
    const [selectedListForEdit, setSelectedListForEdit] = useState(null);

    const [showNewListModal, setShowNewListModal] = useState(false);

    const [reviewMeters, setReviewMeters] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [meters, setMeters] = useState([]);
    const [catalogue, setCatalogue] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            fetchReviewMeters();
            fetchBuildings();
            fetchMeters();
            if (isSuper) fetchOrgs();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, isSuper]);

    useEffect(() => {
        if (isLoggedIn) {
            fetchReviewMeters();
            fetchLists();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrg, isLoggedIn]);

    useEffect(() => {
        EnergyReviewStore.addListUpdatedListener(fetchLists);

        return () => {
            EnergyReviewStore.removeListUpdatedListener(fetchLists);
        };
        // eslint-disable-next-line
    }, [selectedOrg]);

    const fetchReviewMeters = async () => {
        setLoading(true);
        try {
            const response = await EnergyReviewActions.getReviewMeters(selectedOrg);
            setReviewMeters(response);
        } catch (error) {
            console.error('Failed to fetch review meters:', error);
        } finally {
            setLoading(false);
        }
    }

    const fetchBuildings = async () => {
        setLoading(true);
        const response = await EnergyReviewActions.getBuildings();
        setBuildings(response);
    }

    const fetchMeters = async () => {
        setLoading(true);
        const response = await EnergyReviewActions.getMeters();
        setMeters(response);
    }

    useEffect(() => {
        const catalogue = EnergyReviewStore.getCatalogue();
        setCatalogue(catalogue);
    }, [buildings, meters])

    const fetchLists = async () => {
        setLoading(true);
        const org_id = selectedOrg;

        try {
            const response = await EnergyReviewActions.getLists(org_id);
            setLists(response);
        } catch (error) {
            console.error('Failed to fetch configurations:', error.code, error.type);
        } finally {
            setLoading(false);
        }
    };

    const refreshList = async (newRef) => {
        setLoading(true);
        try {
            const response = await EnergyReviewActions.getLists(selectedOrg);
            setLists(response);
        } catch (error) {
            console.error('Failed to refresh list:', error.code, error.type);
        } finally {
            setLoading(false);
        }
    }

    const fetchOrgs = async () => {
        try {
            const orgs = await OrganisationActions.getOrganisations();
            setOrgs(orgs);
        } catch (error) {
            console.error('Failed to fetch organisations: ', error.code, error.type)
        }
    }

    const selectOrgHandler = (orgId) => {
        setSelectedListForEdit(null)
        setSelectedListRef(null)
        setSelectedOrg(orgId);
    }

    const getContextualMenu = () => {
        if (token) return null;
        const adminOrSuper = isAdmin || isSuper;

        return (
            <div className={`${styles.contextualMenu} ${menuOpen ? styles.open : styles.closed}`}>
                <div className={styles.label}>Energy Performance (EnPI) Report</div>
                {isSuper && (
                    <div className={styles.organisationSelector}>
                        <Select
                            size='large'
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            style={{
                                width: '100%',
                                borderRadius: '12px',
                                overflow: 'hidden',
                            }}
                            dropdownStyle={{
                                borderRadius: '12px',
                            }}
                            placeholder="Select an organization"
                            onChange={(value) => selectOrgHandler(value)}
                            value={selectedOrg}
                        >
                            {orgs
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((org) => (
                                    <Select.Option key={org.organisation_id} value={org.organisation_id}>
                                        {org.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                )}
                {adminOrSuper && <div className={styles.listButtons}>
                    <Button
                        className="button light-blue"
                        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
                        size="small"
                        onClick={() => setShowNewListModal(true)}
                    >New List</Button>
                </div>}
                <div className={styles.listsList}>
                    {lists.map((report) => (
                        getCollectionCard(report)
                    ))}
                    {lists.length === 0 && <div className={styles.noReports}>No lists available</div>}
                </div>
                {!token && <Button
                    className={`${styles.menuButton} ${menuOpen ? styles.open : styles.close}`}
                    onClick={() => setMenuOpen(!menuOpen)}
                    icon={<Icon name={menuOpen ? 'ArrowLeft' : 'ArrowRight'} size={30} />}
                    size="small"
                    style={{ border: 'none', outline: 'none' }}
                />}
            </div >
        );
    }

    const getCollectionCard = (list) => {
        const { ref, name, energy_review_meters_ids } = list;
        const isSelected = selectedListRef === ref;
        const adminOrSuper = isAdmin || isSuper;

        return <div key={ref} className={`${styles.reportItem} ${isSelected ? styles.highlighted : ''}`} onClick={() => selectList(list.ref)}>
            <div>
                <div className={styles.reportName}>{name}</div>
                <div className={styles.reportDescription}>{energy_review_meters_ids.length} Meters</div>
            </div>
            {adminOrSuper && <div className={styles.reportDelete}>
                <Button
                    icon={<Icon name="Edit" color={'#fff'} size={20} />}
                    style={{ background: '#d77a29', marginRight: '5px' }}
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEditList(list.ref);
                    }}
                />
                <Button
                    icon={<Icon name="Delete" color={'#fff'} size={20} />}
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        deleteList(list);
                    }}
                />
            </div>}
        </div>
    }

    const handleEditList = (ref) => {
        setSelectedListForEdit(ref);
        setSelectedListRef(null);
    };

    const selectList = (ref) => {
        setSelectedListForEdit(null);
        setSelectedListRef(ref);
    }

    const cleanUp = () => {
        setSelectedListForEdit(null);
        setSelectedListRef(null);
    }

    const deleteList = async (list) => {
        if (window.confirm("Do you wish to delete the list " + list.name + "?")) {
            try {
                EnergyReviewActions.deleteList(list.energy_review_list_id)
                    .then(() => cleanUp())
                    .then(() => fetchLists());
            } catch (error) {
                console.error('Failed to delete list:', error.code, error.type);
            }
        }
    }

    const NewListModal = () => {
        const [newListName, setNewListName] = useState("");

        const handleSaveList = () => {
            if (newListName.trim()) {
                const org_id = selectedOrg || UserStore.getProfile().fk_organisation_id;
                try {
                    EnergyReviewActions.createList({
                        name: newListName,
                        organisation_id: org_id,
                    }).then(() => fetchLists());
                } catch (error) {
                    console.error('Failed to save the list: ', error.code, error.type)
                } finally {
                    setShowNewListModal(false);
                    setNewListName("");
                }

            }
        };

        return (
            <Modal
                open={showNewListModal}
                onCancel={() => setShowNewListModal(false)}
                title="Create New Meter List"
                footer={[
                    <Button key="cancel" onClick={() => setShowNewListModal(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSaveList} disabled={!newListName.trim()}>
                        Save
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Enter new list name"
                    value={newListName}
                    onChange={(e) => setNewListName(e.target.value)}
                />
            </Modal>
        );
    };

    const refreshListAndMeters = () => {
        fetchReviewMeters();
        fetchLists();
    }

    const Placeholder = () => {
        return (
            <div className={styles.placeholder}>
                <h3>No List Selected</h3>
                <p>Please select a list from the menu to view its contents.</p>
            </div>
        );
    };

    return (
        <div className={`${styles.EnergyPerformanceReport} ${!isLoggedIn && styles.outOfLogin} br-mainpanel br-profile-page floorplan-background`}>
            <DocumentTitle title="Energy Performance Report" />
            <LogoSpinner loading={loading} />
            {getContextualMenu()}
            <NewListModal />
            <div className={`${styles.mainWrapper} ${menuOpen && !token ? '' : styles.menuClosed}`}>
                <Row gutter={[20, 20]} justify={'center'} className={styles.mainRow}>
                    <Col span={24}>
                        {(token || selectedListRef) ? (
                            <EnergyPerformanceTable
                                listRef={selectedListRef}
                                token={token}
                                isLoggedIn={isLoggedIn}
                            />
                        ) : selectedListForEdit ? (
                            <EnergyPerformanceConfig
                                selectedMeters={reviewMeters.filter(meter =>
                                    lists.find(list => list.ref === selectedListForEdit).energy_review_meters_ids.includes(meter.energy_review_meter_id)
                                )}
                                refreshList={refreshList}
                                refreshMeters={refreshListAndMeters}
                                catalogue={catalogue}
                                buildings={buildings}
                                reviewMeters={reviewMeters}
                                meters={meters}
                                selectedOrg={selectedOrg}
                                selectedList={lists.find(list => list.ref === selectedListForEdit)}
                            />
                        ) : (
                            <Placeholder />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default EnergyPerformanceReport;
