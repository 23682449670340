import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./ButtonCell.scss";
import { Icon } from "../../../../components/Icon";

/**
 * ButtonCell Component
 *
 * Renders a set of buttons within a specified wrapper element.
 * By default, it uses a <td> element, making it suitable for table cells.
 * However, it can render any other wrapper element (e.g., <div>) based on the 'wrapper' prop.
 *
 * Props:
 * - buttons: Array of button objects to render. Each button object should contain:
 *    - label: string - The tooltip text for the button.
 *    - icon: string - The name of the icon to display.
 *    - iconColor: string - The color of the icon.
 *    - clickHandler: function - The function to execute on button click.
 *    - disabled: boolean (optional) - If true, the button is disabled.
 *    - buttonColor: string (optional) - Custom color for the button (CSS variable '--buttonColor').
 * - wrapper: string (optional) - The HTML tag to use as the wrapper (e.g., 'td', 'div'). Defaults to 'td'.
 * - className: string (optional) - Additional CSS classes for the wrapper element.
 */
class ButtonCell extends PureComponent {
  render() {
    const { buttons, wrapper, className } = this.props;

    // Use React.createElement to dynamically create the specified wrapper element
    return React.createElement(
      wrapper,
      { className: `ButtonCell tx-14 ${className || ""}` },
      <div className="buttons-wrapper d-flex">
        {buttons.map((b) => (
          <button
            key={b.label}
            title={b.label}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling to parent elements
              if (b.clickHandler && !b.disabled) {
                b.clickHandler();
              }
            }}
            className="button"
            style={{
              "--buttonColor": b.buttonColor,
            }}
            disabled={b.disabled}
          >
            <Icon name={b.icon} color={b.iconColor} />
          </button>
        ))}
      </div>
    );
  }
}

// Define the expected prop types
ButtonCell.propTypes = {
  /**
   * Array of button configurations to render.
   * Each button should have a label, icon, iconColor, and a clickHandler function.
   */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,       // Tooltip text
      icon: PropTypes.string.isRequired,        // Icon name
      iconColor: PropTypes.string.isRequired,   // Icon color
      clickHandler: PropTypes.func.isRequired,  // Click handler function
      disabled: PropTypes.bool,                 // Optional: Disable button
      buttonColor: PropTypes.string,            // Optional: Custom button color
    })
  ).isRequired,

  /**
   * The HTML tag to use as the wrapper element.
   * Defaults to 'td' for table cells.
   */
  wrapper: PropTypes.string,

  /**
   * Additional CSS classes for the wrapper element.
   */
  className: PropTypes.string,
};

// Define default props
ButtonCell.defaultProps = {
  wrapper: "td", // Default wrapper is 'td'
  className: "",  // No additional classes by default
};

export default ButtonCell;
