import React, { Component } from "react";
import PropTypes from "prop-types";
import "./PropertyTable.scss";
import _ from "lodash";
import { Button, Select } from 'antd';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

class PropertyTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            inputFilter: "",
            dropdownFilters: {},
            scrollOffset: 0,
        };
        this.filterData = this.filterData.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.clearAllSelections = this.clearAllSelections.bind(this);
        this.getDropdownOptions = this.getDropdownOptions.bind(this);
        this.clearAllFilters = this.clearAllFilters.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll({ scrollOffset }) {
        this.setState({ scrollOffset });
    }

    clearAllFilters() {
        this.setState({
            inputFilter: "",
            dropdownFilters: {},
        });
    }

    clearAllSelections() {
        this.props.clearAllSelections();
    }

    getHeaders() {
        return (
            <div className="headers d-flex">
                {this.props.headers.map((hdr) => {
                    if (hdr.dropdown) {
                        const options = this.getDropdownOptions(hdr.accessor, hdr.label);

                        return (
                            <div key={hdr.label} className={`header-item wd-${hdr.width}p`}>
                                <Select
                                    options={options}
                                    style={{
                                        maxWidth: '95%',
                                        fontSize: '12px'
                                    }}
                                    filterSort={(optionA, optionB) => optionA.label.localeCompare(optionB.label)}
                                    value={this.state.dropdownFilters[hdr.accessor]}
                                    onChange={(option) => this.handleDropdownChange(hdr.accessor, option)}
                                    placeholder={`${hdr.label}`}
                                    popupMatchSelectWidth={false}
                                    showSearch
                                    filterOption={(input, option) => 
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                                        (option?.value ?? '').toString().toLowerCase().includes(input.toLowerCase())
                                    }
                                    size={'small'}
                                    allowClear
                                />
                            </div>
                        );
                    }
                    let classes = "header-item";

                    if (hdr.sticky) {
                        classes += " sticky";
                    }

                    return (
                        <div key={hdr.label} className={classes} style={{ flex: hdr.width ? `0 0 ${hdr.width}%` : '1' }}>
                            {hdr.label}
                        </div>
                    );
                })}
            </div>
        );
    }

    handleDropdownChange(accessor, selectedOption) {
        this.setState(prevState => ({
            scrollOffset: 0,
            dropdownFilters: {
                ...prevState.dropdownFilters,
                [accessor]: selectedOption
            }
        }));
    }

    getLabel(value, accessor) {
        if (accessor === 'entity.type') {
            return _.capitalize(value);
        } else if (accessor === 'property.data_type') {
            if (value === "tempC") return "°C";
            if (value === "percent") return "%";
            if (value === "kwh") return "kWh";
            if (value === "kw") return "kW";
            if (value === "degDays") return 'DD';
            if (value === "kvarh") return 'kVArh';
            if (value === "pascals") return 'pascals';
            if (value === "ppm") return "ppm";
            if (value === "level") return 'level';
            if (value === "decimal") return 'decimal';
            if (value === "lux") return 'lux';
            if (value === "m3") return 'm3';
            if (value === "integer") return 'integer';
            if (value === "pf") return 'pf';
            if (value === "pulse") return 'pulse';
            if (value === "ugm3") return 'µg/m3';
        }
        return value;
    }

    getDropdownOptions(accessor, headerLabel) {
        const otherFilters = { ...this.state.dropdownFilters };
        delete otherFilters[accessor];

        let data = this.props.data;

        // Filter by selected datatypes from selectedProperties
        const selectedDataTypes = this.props.selectedRows.map(entry => entry.property.data_type);
        const uniqueDataTypes = [...new Set(selectedDataTypes)];
        if (uniqueDataTypes.length > 1) {
            data = data.filter(item => uniqueDataTypes.includes(item.property.data_type));
        }

        Object.keys(otherFilters).forEach(key => {
            if (otherFilters[key] && otherFilters[key] !== "") {
                data = data.filter(item => _.get(item, key) === otherFilters[key]);
            }
        });

        const uniqueValues = new Set(data.map(item => _.get(item, accessor)));
        const options = Array.from(uniqueValues).sort().map(value => ({
            label: this.getLabel(value, accessor),
            value: value
        }));

        return options;
    }

    filterData() {
        let data = this.props.data;
        const { dropdownFilters, inputFilter } = this.state;

        // Filter by selected datatypes
        const selectedDataTypes = this.props.selectedRows.map(entry => entry.property.data_type);
        const uniqueDataTypes = [...new Set(selectedDataTypes)];
        if (uniqueDataTypes.length > 1) {
            data = data.filter(item => uniqueDataTypes.includes(item.property.data_type));
        }

        // Filter by Dropdowns
        Object.keys(dropdownFilters).forEach(accessor => {
            if (dropdownFilters[accessor] && dropdownFilters[accessor] !== "") {
                data = data.filter(item => _.get(item, accessor) === dropdownFilters[accessor]);
            }
        });

        // Filter by Search Input
        if (inputFilter) {
            const filterableHeaders = this.props.headers.filter(hdr => hdr.filterable).map(hdr => hdr.accessor);
            data = data.filter(item => {
                return filterableHeaders.some(accessor => {
                    const value = _.get(item, accessor);
                    return value.toString().toLowerCase().includes(inputFilter.toLowerCase());
                });
            });
        }

        return data;
    }

    getRow = ({ index, style }) => {
        const entry = this.filterData()[index];
        const isSelected = this.isSelected(entry);

        return (
            <div style={style} key={index}
                className={isSelected ? "property-row selected-row" : "property-row"}
                onClick={(e) => {
                    const scrollContainer = e.target.closest('.table-container');
                    const currentScrollOffset = scrollContainer.scrollTop;
                    this.handleScroll({ scrollOffset: currentScrollOffset });
                    this.props.onRowSelectionChange(entry);
                }}>
                {this.props.headers.map((hdr, hdrIndex) => {
                    let value = _.get(entry, hdr.accessor);
                    if (value !== undefined && value !== null && hdr.formatter) {
                        value = hdr.formatter(value);
                    }
                    let cellStyle = {
                        flex: hdr.width ? `0 0 ${hdr.width}%` : '1',
                    };

                    return (
                        <div key={hdrIndex} title={value} className="tx-14 property-cell" style={cellStyle}>
                            {this.getLabel(value, hdr.accessor)}
                        </div>
                    );
                })}
            </div>
        );
    };

    isSelected(entry) {
        return this.props.selectedRows.includes(entry);
    }

    getInputFilter() {
        let number_of_entries = this.filterData().length;

        return (
            <div className="filter-and-addnew">
                <input
                    className="input-filter"
                    type="text"
                    value={this.state.inputFilter}
                    placeholder={
                        number_of_entries
                            ? `Search in ${number_of_entries} entries...`
                            : "Search..."
                    }
                    onChange={(e) => this.handleInputFilterChange(e.target.value)}
                />
                {this.props.addNewButton && (
                    <div
                        className="add-new-button"
                        onClick={
                            this.props.addNewButton
                                ? this.props.addNewButton.clickHandler
                                : undefined
                        }
                    >
                        <span className="plus-sign">+</span>
                        {this.props.addNewButton.label}
                    </div>
                )}
            </div>
        );
    }

    handleInputFilterChange(value) {
        this.setState({ inputFilter: value });
    }

    getButtons() {
        return <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
            <Button
                size="small"
                onClick={this.clearAllFilters}
                type={'text'}
                className="clear-all-button"
            >
                Clear All Filters
            </Button>
            {this.props.clearAllSelections && <Button
                size="small"
                onClick={this.clearAllSelections}
                type={'text'}
                className="clear-all-button mg-l-5"
            >
                Clear All Selections
            </Button>}
        </div>
    }

    render() {
        const filteredData = this.filterData();
        const { scrollOffset } = this.state;

        return (
            <div className='PropertyTable' style={{ width: this.props.width, height: this.props.height }}>
                <div className="filters-container">
                    {this.getInputFilter()}
                    {this.getButtons()}
                </div>
                <div
                    className="card bd-0 mg-t-10 wd-100p table-container"
                    style={{ height: this.props.height ? this.props.height : "80vh" }}
                >
                    {this.getHeaders()}
                    <div style={{ width: '100%', flexGrow: '1' }}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    key={JSON.stringify({ filters: this.state.dropdownFilters, selectedRows: this.props.selectedRows, inputFilter: this.state.inputFilter })}
                                    height={height}
                                    itemCount={filteredData.length}
                                    itemSize={27}
                                    width={width}
                                    initialScrollOffset={scrollOffset}
                                    className="table-container"
                                >
                                    {this.getRow}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </div>
        );
    }
}

PropertyTable.defaultProps = {
    id: "",
    data: [],
    headers: [],
    height: "80vh",
    width: "100%",
    inputFilter: true,
};

PropertyTable.propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.array,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            accessor: PropTypes.string.isRequired,
            width: PropTypes.number,
            filterable: PropTypes.bool,
            unsortable: PropTypes.bool,
            formatter: PropTypes.func,
            dropdownOptions: PropTypes.array,
        })
    ),
    loading: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    inputFilter: PropTypes.bool,
    rowClickHandler: PropTypes.func,
    addNewButton: PropTypes.shape({
        label: PropTypes.string,
        clickHandler: PropTypes.func,
    }),
    selectedRows: PropTypes.array,
    onRowSelectionChange: PropTypes.func,
};

export default PropertyTable;
