import React, { Component } from 'react';
import './RegisterDevices.scss';
import GeneralUtils from '../../../utils/GeneralUtils';
import { AddSensorPanel } from './AddSensorPanel';
import _ from 'lodash';
import { toast } from 'react-toastify';

import SensorStore from '../../../stores/sensorStore';
import SensorActions from '../../../actions/sensorActions';
import { Button } from 'antd';
import { Icon } from '../../../components/Icon';

class RegisterDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_sensors_list: [],
      updated_sensors_list: [],
      sensors: [],
      device_types: SensorStore.getDeviceTypes(),
      sensors_control_group: _.cloneDeep(SensorStore.getSensors()),
      sensor_filter: '',
      type_filter: 0,
      successfull_transactions: [],
      failed_transactions: []
    };
    this._onSensorChange = this._onSensorChange.bind(this);
    this._onTransactionStatusChange = this._onTransactionStatusChange.bind(this);
    this._addNewSensor = this._addNewSensor.bind(this);
    this._deleteNewSensor = this._deleteNewSensor.bind(this);
    this._saveSensors = this._saveSensors.bind(this);
    this._deleteSensor = this._deleteSensor.bind(this);
    this._changeSensorValues = this._changeSensorValues.bind(this);
    this._changeNewSensorValues = this._changeNewSensorValues.bind(this);
    this._validateSensorInput = this._validateSensorInput.bind(this);
    this._commenceOperations = this._commenceOperations.bind(this);
  }


  // Initial state getting device types and sensors
  _onSensorChange() {
    this.setState({
      sensors: SensorStore.getSensors(),
      sensors_control_group: _.cloneDeep(SensorStore.getSensors()),
      device_types: SensorStore.getDeviceTypes(),
    });
  }

  _onTransactionStatusChange() {
    const successfull_transactions = SensorStore.getSuccessfullTransactions();
    const failed_transactions = SensorStore.getFailedTransactions();
    const sensors = SensorStore.getSensors();

    const succesfull_transaction_ids = successfull_transactions.map(transaction => transaction.sensor_id);
    const succesfull_transaction_pacs = successfull_transactions.map(transaction => transaction.pac_eui);

    const updated_sensors_list = this.state.updated_sensors_list.filter(item => !succesfull_transaction_ids.includes(item.sensor_id));
    const new_sensors_list = this.state.new_sensors_list.filter(item => !succesfull_transaction_pacs.includes(item.pac_eui));

    this.setState({
      successfull_transactions: successfull_transactions,
      failed_transactions: failed_transactions,
      sensors: sensors,
      updated_sensors_list: updated_sensors_list,
      new_sensors_list: new_sensors_list,
    }, () => {
      if (this.state.updated_sensors_list.length === 0 && this.state.new_sensors_list.length === 0) {
        SensorActions.getBuildingSigfoxSensors(this.props.building_id);
      }
    });
  }

  UNSAFE_componentWillMount() {
    SensorStore.addChangeListener(this._onSensorChange);
    SensorStore.addTransactionStatusChangeListener(this._onTransactionStatusChange)
  }

  componentWillUnmount() {
    SensorStore.clear();
    SensorStore.removeChangeListener(this._onSensorChange);
    SensorStore.removeTransactionStatusChangeListener(this._onTransactionStatusChange)
  }

  componentDidMount() {
    if (this.props.building_id && this.props.building_id > 0) {
      SensorActions.getBuildingSigfoxSensors(this.props.building_id);
      SensorActions.getSigfoxDeviceTypes('meter', 'asset', 'zone')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.building_id !== this.props.building_id) {
      SensorActions.getBuildingSigfoxSensors(this.props.building_id);
      SensorActions.getSigfoxDeviceTypes('meter', 'asset', 'zone')
    }
  }

  _deleteNewSensor(index) {
    const modified_new_sensors_list = this.state.new_sensors_list.filter((_, ind) => {
      return ind !== index;
    })
    this.setState({ new_sensors_list: modified_new_sensors_list })
  }

  _addNewSensor() {
    const new_sensor = {
      building_id: this.props.building_id,
      device_id: '',
      pac_eui: '',
      name: '',
      description: '',
      location: this.props.building_name,
      sensor_type_id: this.state.type_filter,
      external_registration: false
    }

    this.setState({ new_sensors_list: [...this.state.new_sensors_list, new_sensor] })
  }

  _changeSensorValues(sensor, target_property, index, event) {
    const updated_sensors_list = this.state.updated_sensors_list;
    const new_sensor = {
      ...sensor,
      [target_property]: event.target.value
    }

    let new_sensor_array = [...this.state.sensors]
    for (let i = 0; i < new_sensor_array.length; i++) {
      if (new_sensor_array[i].sensor_id === new_sensor.sensor_id) {
        new_sensor_array[i] = new_sensor
      }
    }

    const sensorChanged = !_.isEqual(new_sensor, this.state.sensors_control_group.filter(control_sensor => control_sensor.sensor_id === new_sensor.sensor_id)[0]);
    // check if the update list has more than 5 updates if not insert it in the updated list
    if (sensorChanged) {
      let new_updated_sensor_list = [...updated_sensors_list.filter(element => element.sensor_id !== new_sensor.sensor_id)]
      new_updated_sensor_list = [...new_updated_sensor_list, new_sensor]
      if (new_updated_sensor_list.length > 5) {
        window.alert('Sigfox allows for maximum of 5 updates per hour, please wait for an hour before updating additional sensors')
      }
      if (new_updated_sensor_list.length < 6) {
        this.setState({ updated_sensors_list: new_updated_sensor_list })
        this.setState({ sensors: new_sensor_array })
      }
    } else if (!sensorChanged) {
      const new_updated_sensors_list = updated_sensors_list.filter(element => element.sensor_id !== new_sensor.sensor_id);
      this.setState({ updated_sensors_list: new_updated_sensors_list })
      this.setState({ sensors: new_sensor_array })
    }
  }

  _changeNewSensorValues(sensor, target_property, index, event) {
    const modified_new_sensors_list = [...this.state.new_sensors_list];
    const modified_sensor = modified_new_sensors_list[index];

    if (target_property === 'sensor_type') {
      const newValue = +event.target.value;
      const sensor_config = this.state.device_types.find(type => type.sensor_type_id === newValue);
      modified_sensor[target_property] = sensor_config;
    } else if (target_property === 'external_registration') {
      modified_sensor[target_property] = event.target.checked; // Use checked for boolean
    } else {
      modified_sensor[target_property] = event.target.value;
    }

    this.setState({ new_sensors_list: modified_new_sensors_list });
  }

  _validateSensorInput(sensor) {
    let validId = GeneralUtils.stringNotEmpty(sensor.device_id);
    let validPAC = GeneralUtils.stringNotEmpty(sensor.pac_eui);
    let validDeviceTypeId = parseInt(sensor.sensor_type_id) > 0;
    let validName = GeneralUtils.stringNotEmpty(sensor.name);
    let validDescription = GeneralUtils.stringNotEmpty(sensor.description);
    return validId && validPAC && validName && validDescription && validDeviceTypeId;
  }

  _commenceOperations() {
    SensorStore.clearTransactionData()
    const all_operations = [...this.state.new_sensors_list, ...this.state.updated_sensors_list];
    // If no operations scheduled, throw a toast and return
    if (all_operations.length === 0) {
      toast.info("No Operations Scheduled")
      return;
    }

    // Checking for validity of each update/save operation
    let all_valid = true;
    let invalid_sensors = '';
    all_operations.forEach(operation => {
      if (!this._validateSensorInput(operation)) {
        all_valid = false;
        invalid_sensors += `\n${operation.name || '<name missing>'}`
      }
    })

    if (all_valid === false) {
      window.alert(`Please correct the missing information in the following sensors: ${invalid_sensors}`)
    } else if (all_valid) {
      this._saveSensors(all_operations)
    }

  }

  _cancelAllOperations() {
    if (window.confirm('This will reset this window to its initial state, are you sure?')) {
      window.location.reload();
    }
  }

  _saveSensors(operations) {
    operations.forEach((sensor, index) => {
      setTimeout(() => {
        if (this.state.new_sensors_list.includes(sensor)) {
          const save_sensor_dto = {
            building_id: sensor.building_id,
            sensor_type_id: sensor.sensor_type_id,
            pac_eui: sensor.pac_eui,
            device_id: sensor.device_id,
            name: sensor.name,
            description: sensor.description,
            external_registration: sensor.external_registration !== undefined ? sensor.external_registration : false,
          }
          SensorActions.saveSensor(save_sensor_dto)
        } else if (this.state.updated_sensors_list.includes(sensor)) {
          const update_sensor_dto = {
            sensor_id: sensor.sensor_id,
            name: sensor.name,
            description: sensor.description,
          }
          SensorActions.updateSensor(update_sensor_dto)
        }
      }, index * 1000)
    })
  }

  _deleteSensor(sensor) {
    if (sensor.sensor_id && sensor.sensor_id > 0) {
      if (window.confirm("Are you sure you wish to delete this sensor?")) {
        SensorActions.deleteSensor(sensor.device_id).then(() => {
          const updatedSensors = this.state.sensors.filter(s => s.sensor_id !== sensor.sensor_id);
          const updatedSensorsList = this.state.updated_sensors_list.filter(s => s.sensor_id !== sensor.sensor_id);
          const newSensorsList = this.state.new_sensors_list.filter(s => s.sensor_id !== sensor.sensor_id);

          this.setState({
            sensors: updatedSensors,
            updated_sensors_list: updatedSensorsList,
            new_sensors_list: newSensorsList,
          });
        }).catch(error => {
          console.error("Failed to delete sensor:", error.code, error.type);
        });
      }
    }
  }

  //Rendering methods

  getNoRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return <tr>
      <td className="valign-middle">
        <div className="d-flex align-items-center">
          <div>
            <div className="tx-inverse">No sensors available.</div>
          </div>
        </div>
      </td>
      {rows}
    </tr>
  }
  getSensorFilter = () => {
    return <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">
          <Icon name='Search' color='#868ba1' />
        </span>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Filter Sensors By Name, Description"
        value={this.state.sensor_filter}
        onChange={e => this.setState({ sensor_filter: e.target.value })}
      />
    </div>
  }
  getSensorFilterByType = () => {
    return <div className="input-group">
      <select onChange={(e) => this.setState({ type_filter: +e.target.value })} value={this.state.type_filter} className="inline-single-input" >
        <option value={0} disabled hidden>Filter By Type</option>
        {this.state.device_types.map((type, index) => {
          return <option key={type.name + type.sensor_type_id} value={type.sensor_type_id}>{type.name}</option>
        })}
        {this.state.type_filter && <option value='' >Remove filter</option>}
      </select>
    </div>
  }

  getAddSensorButton = () => {
    return <Button
      className="button green"
      icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
      size="small"
      onClick={this._addNewSensor}
    >Add Sensor</Button>
  }

  getSensorTable() {
    let sensorRows;
    let newSensorRows;
    const noSensorsPresent = this.state.sensors.length === 0 && this.state.new_sensors_list.length === 0
    const buttons = (
      <div className="sensor-button-wrapper">
        <Button
          className="button red"
          icon={<Icon name="Reset" color={'#fff'} size={18} />}
          size="small"
          onClick={this._cancelAllOperations}
        >Reset</Button>
        <Button
          className="button green"
          icon={<Icon name="Save" color={'#fff'} size={18} />}
          size="small"
          onClick={this._commenceOperations}
          style={{ marginLeft: '15px' }}
        >Save</Button>
      </div>
    )

    if (this.state.new_sensors_list.length > 0) {
      newSensorRows = this.state.new_sensors_list.map((sensor, index) => {
        return <AddSensorPanel
          key={index}
          index={index}
          device_types={this.state.device_types}
          changeSensorValues={this._changeNewSensorValues}
          deleteSensor={this._deleteSensor}
          deleteNewSensor={this._deleteNewSensor}
          sensor={sensor}
        />
      })
    }

    let sensors = this.state.sensors;

    if (sensors.length > 0) {
      sensorRows = sensors.map((sensor, index) => {

        const filter = this.state.sensor_filter.toLowerCase();
        const sensorFilterMatched = sensor.name.toLowerCase().includes(filter) || sensor.description.toLowerCase().includes(filter);
        const sensorTypeFilterMatched = this.state.type_filter ? this.state.type_filter === sensor.sensor_type_id : true;
        const generalFilter = sensorFilterMatched && sensorTypeFilterMatched;

        if (sensor.sensor_id && generalFilter) {
          const isUpdated = this.state.updated_sensors_list.includes(sensor)
          return <AddSensorPanel
            isUpdated={isUpdated}
            key={sensor.sensor_id}
            device_types={this.state.device_types}
            changeSensorValues={this._changeSensorValues}
            deleteSensor={this._deleteSensor}
            sensor={sensor}
          />
        } else {
          return null;
        }
      })
    }

    return (
      <div>
        <div className="br-pagetitle pd-l-0 pd-b-0 mg-b-15 register-devices-wrapper">
          <div className="sensor-filter-wrapper">
            <div className="sensor-filter">{this.getSensorFilter()}</div>
            <div className="sensor-filter">{this.getSensorFilterByType()}</div>
            <div>{this.getAddSensorButton()}</div>
            <div />
          </div>
        </div>
        <div className="card bd-0 shadow-base mg-t-5">
          <table className="table mg-b-0 table-contact">
            <thead>
              <tr>
                <th className="wd-20p tx-mont tx-medium">Name</th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Description
                </th>
                <th className="wd-15p tx-10-force tx-mont tx-medium">ID</th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  PAC Code
                </th>
                <th className="wd-25p tx-10-force tx-mont tx-medium">
                  Sensor Type
                </th>
                <th className="wd-5p tx-10-force tx-mont tx-medium tx-center">
                  External
                </th>
                <th className="wd-5p tx-10-force tx-mont tx-medium tx-center">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {newSensorRows}
              {noSensorsPresent && this.getNoRowsAvailable(5)}
              {sensorRows}
            </tbody>
          </table>
          {!noSensorsPresent && buttons}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row mg-b-15">
        <div className="col-12">
          {this.getSensorTable()}
        </div>
      </div>
    );
  }
}

export default RegisterDevices;
