import moment from 'moment';

class Timeframe {

    constructor(start, end) {
        if (!start || !end) {
            throw new Error("Both start and end must be provided.");
        }
        if (!this.isValidDate(start) || !this.isValidDate(end)) {
            throw new Error("start and end must be valid date-like objects.");
        }
        this.start = typeof start === 'number' ? moment.unix(start) : moment(start);
        this.end = typeof end === 'number' ? moment.unix(end) : moment(end);

        if (this.end.isBefore(this.start)) {
            throw new Error("end cannot be before start.");
        }
    }

    isValidDate(date) {
        if (typeof date === 'number') {
            return moment.unix(date).isValid();
        }
        return moment(date, moment.ISO_8601, true).isValid();
    }

    format(pattern = 'YYYY-MM-DD') {
        return {
            start: this.start.format(pattern),
            end: this.end.format(pattern),
        };
    }

    extendEndByDay() {
        const newEnd = this.end.clone().add(1, 'day');
        return new Timeframe(this.start, newEnd);
    }

    truncateEnd() { // truncate end to now if it's in the future
        const now = moment();
        const newEnd = now.isBefore(this.end) ? now : this.end;
        return new Timeframe(this.start, newEnd);
    }

    getDaysDifference() {
        return this.end.diff(this.start, 'days');
    }

    static lastWeek() {
        const start = moment().subtract(1, 'week').startOf('isoWeek');
        const end = moment().subtract(1, 'week').endOf('isoWeek');
        return new Timeframe(start, end);
    }
}
export default Timeframe;