import React, { Component } from "react";
import "./EnergyClassificationReport.scss";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants";

import AutomatedAssessmentsActions from "../../../actions/automatedAssessmentsActions";
import AutomatedAssessmentsStore from "../../../stores/automatedAssessmentsStore";

import { Spinner } from "../../../components/Spinner";
import { HighChart } from "../../../components/HighChart";
import { SupplyEmissionChart } from "../../../components/SupplyEmissionChart";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { PieChart } from "../../../components/PieChart";
import { DisclaimerModal } from "../../../components/DisclaimerModal";
import { Modal } from "../../../components/Modal";
import { Icon } from "../../../components/Icon";

import ReactHintFactory from "react-hint";
import moment from "moment";
import { toast } from "react-toastify";

import "react-hint/css/index.css";

const SAVINGS_COLOR = "#b8D4C8";
const COST_COLOR = "#3a9772";

const ReactHint = ReactHintFactory(React);
class EnergyClassificationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,

      heating_data: null,
      cooling_data: null,
      hot_water_data: null,
      ventilation_data: null,
      total_data: null,

      expanded_sections: [],
      available_categories: [],
      show_report_sharing: false,
      loading: true,
      config_modal_open: false,

      active_view: "OVERVIEW",
      overview_category: "ENERGY",
      budget_analysis_hover: "Year 3",
      filter: "",
      filter_by_category: null,
      filter_by_grade: null,
      open_action: null,

      chart_loading: null,
      action_list_loading: true,
      chart_data: {
        HEATING: [],
        COOLING: [],
        HOT_WATER: [],
        VENTILATION: [],
      },
    };
    //email ref
    this._targetEmailRef = React.createRef();

    // fetch and handlers
    this._fetchReport = this._fetchReport.bind(this);
    this._onReportFetch = this._onReportFetch.bind(this);
    this._onChartDataFetch = this._onChartDataFetch.bind(this);
    this._shareComplete = this._shareComplete.bind(this);
    this._sendReportLink = this._sendReportLink.bind(this);

    // this.getGrade = this.getGrade.bind(this);
  }

  // LIFECYCLE METHODS

  UNSAFE_componentWillMount() {
    AutomatedAssessmentsStore.addReportFetchedListener(this._onReportFetch);
    AutomatedAssessmentsStore.addReportChartDataFetchedListener(
      this._onChartDataFetch
    );
    AutomatedAssessmentsStore.addAssessmentSharedListener(this._shareComplete);
  }

  componentWillUnmount() {
    AutomatedAssessmentsStore.removeReportFetchedListener(this._onReportFetch);
    AutomatedAssessmentsStore.removeReportChartDataFetchedListener(
      this._onChartDataFetch
    );
    AutomatedAssessmentsStore.removeAssessmentSharedListener(
      this._shareComplete
    );
    AutomatedAssessmentsStore.clear();
  }

  componentDidMount() {
    const token = this.props.token;
    if (token) {
      this._fetchReport(token);
    }
  }

  // FETCHERS AND CALLBACKS

  _fetchChartData(category) {
    const token = this.props.token;
    if (token) {
      AutomatedAssessmentsActions.getEnergyPerformanceReportChartData(
        token,
        category
      );
      this.setState({ chart_loading: category });
    } else {
      toast.error("Can't retrieve the assessment charts")
    }
  }

  _fetchReport(token) {
    if (token) {
      AutomatedAssessmentsActions.getEnergyPerformanceReportWithToken(token);
    } else {
      toast.error("Can't retrieve the assessment report")
    }
  }

  _shareComplete() {
    this.setState({ show_report_sharing: false });
  }

  _onReportFetch() {
    const report =
      AutomatedAssessmentsStore.getEnergyPerformanceClassificationReport();

    const getCategoryData = (category) => {
      if (
        report &&
        report.category_checks.find((e) => e.category === category)
      ) {
        let consumption_current,
          consumption_future,
          consumption_savings,
          cost_modifier,
          carbon_modifier,
          category_data,
          label;

        if (category === "HEATING") {
          consumption_current = report.current_heating_consumption;
          consumption_future =
            report.future_heating_consumption != null
              ? report.future_heating_consumption
              : consumption_current;
          consumption_savings = consumption_current - consumption_future;
          cost_modifier = report.building.effective_gas_rate;
          carbon_modifier = report.building.effective_gas_carbon;
          category_data = report.category_checks.find(
            (e) => e.category === category
          );
          label = "Heating";
        } else if (category === "HOT_WATER") {
          consumption_current = report.current_hot_water_consumption;
          consumption_future =
            report.future_hot_water_consumption != null
              ? report.future_hot_water_consumption
              : consumption_current;
          consumption_savings = consumption_current - consumption_future;
          cost_modifier = report.building.effective_gas_rate;
          carbon_modifier = report.building.effective_gas_carbon;
          category_data = report.category_checks.find(
            (e) => e.category === category
          );
          label = "Hot Water";
        } else if (category === "VENTILATION") {
          consumption_current = report.current_ventilation_consumption;
          consumption_future =
            report.future_ventilation_consumption != null
              ? report.future_ventilation_consumption
              : consumption_current;
          consumption_savings = consumption_current - consumption_future;
          cost_modifier = report.building.effective_elec_rate;
          carbon_modifier = report.building.effective_elec_carbon;
          category_data = report.category_checks.find(
            (e) => e.category === category
          );
          label = "Ventilation";
        } else if (category === "COOLING") {
          consumption_current = report.current_cooling_consumption;
          consumption_future =
            report.future_cooling_consumption != null
              ? report.future_cooling_consumption
              : consumption_current;
          consumption_savings = consumption_current - consumption_future;
          cost_modifier = report.building.effective_elec_rate;
          carbon_modifier = report.building.effective_elec_carbon;
          category_data = report.category_checks.find(
            (e) => e.category === category
          );
          label = "Cooling";
        }

        return {
          ...category_data,
          current_consumption: Math.round(consumption_current),
          future_consumption: Math.round(consumption_future),
          savings_consumption: Math.round(consumption_savings),

          current_cost: Math.round(consumption_current * cost_modifier),
          future_cost: Math.round(consumption_future * cost_modifier),
          savings_cost: Math.round(consumption_savings * cost_modifier),

          current_emissions: Math.round(consumption_current * carbon_modifier),
          future_emissions: Math.round(consumption_future * carbon_modifier),
          savings_emissions: Math.round(consumption_savings * carbon_modifier),

          label: label,
        };
      }

      return null;
    };

    const getTotalData = () => {
      let consumption_current = 0;
      let consumption_future = 0;
      let consumption_savings = 0;

      let current_cost = 0;
      let future_cost = 0;
      let savings_cost = 0;

      let current_emissions = 0;
      let future_emissions = 0;
      let emissions_savings = 0;

      report &&
        report.category_checks.forEach((cat) => {
          consumption_current += getCategoryData(
            cat.category
          ).current_consumption;
          consumption_future += getCategoryData(
            cat.category
          ).future_consumption;
          consumption_savings += getCategoryData(
            cat.category
          ).savings_consumption;

          current_cost += getCategoryData(cat.category).current_cost;
          future_cost += getCategoryData(cat.category).future_cost;
          savings_cost += getCategoryData(cat.category).savings_cost;

          current_emissions += getCategoryData(cat.category).current_emissions;
          future_emissions += getCategoryData(cat.category).future_emissions;
          emissions_savings += getCategoryData(cat.category).savings_emissions;
        });

      return {
        current_consumption: Math.round(consumption_current),
        future_consumption: Math.round(consumption_future),
        savings_consumption: Math.round(consumption_savings),

        current_cost: Math.round(current_cost),
        future_cost: Math.round(future_cost),
        savings_cost: Math.round(savings_cost),

        current_emissions: Math.round(current_emissions),
        future_emissions: Math.round(future_emissions),
        savings_emissions: Math.round(emissions_savings),
      };
    };

    this.setState({
      report: report,
      loading: false,
      heating_data: getCategoryData("HEATING"),
      cooling_data: getCategoryData("COOLING"),
      hot_water_data: getCategoryData("HOT_WATER"),
      ventilation_data: getCategoryData("VENTILATION"),
      available_categories: [
        getCategoryData("HEATING"),
        getCategoryData("COOLING"),
        getCategoryData("HOT_WATER"),
        getCategoryData("VENTILATION"),
      ].filter((e) => e),
      total_data: getTotalData(),
    });
  }

  _onChartDataFetch() {
    const chart_data =
      AutomatedAssessmentsStore.getEnergyPerformanceChartData();
    this.setState({ chart_data: chart_data, chart_loading: null });
  }

  _sendReportLink() {
    let emails = this._targetEmailRef.current.value;
    let email_list = emails.split(",");
    let validated_list = [];
    for (let i = 0; i < email_list.length; i++) {
      if (
        email_list[i].length > 0 &&
        GeneralUtils.emailIsValid(email_list[i])
      ) {
        validated_list.push(email_list[i]);
      }
    }
    if (validated_list.length === 0) {
      toast.warn("No valid emails entered, please try again")
    } else {
      for (let i = 0; i < validated_list.length; i++) {
        // EMAIL
        const token = this.props.token;
        if (token) {
          AutomatedAssessmentsActions.shareAssessment(validated_list[i], token);
        }
      }
      toast.success("Report sent to recipients successfully")
      this._targetEmailRef.current.value = "";
      this.setState({ show_report_sharing: false });
    }
  }

  // HELPER FUNCTIONS, UI HANDLERS

  expandSection(section, category = null) {
    let modified_expanded_sections = this.state.expanded_sections;

    // If charts - fetch, unless the data is already there
    if (category && section.includes("_charts_section")) {
      if (this.state.chart_data[category].length === 0)
        this._fetchChartData(category);
    }

    // If exists- remove
    if (modified_expanded_sections.includes(section)) {
      if (section.includes("_checks_section")) {
        modified_expanded_sections = modified_expanded_sections.filter(
          (sec) => sec.includes(category) === false
        );
      } else {
        modified_expanded_sections = modified_expanded_sections.filter(
          (sec) => sec !== section
        );
      }
      // If absent - add
    } else {
      modified_expanded_sections = [...modified_expanded_sections, section];
    }

    this.setState({ expanded_sections: modified_expanded_sections });
  }

  getSharingReportForm() {
    return (
      <div className="row mg-t-0">
        <div
          className={`card mg-r-0 mg-lg-r-15 ${this.state.show_report_sharing
            ? "sharing-form-expanded"
            : "sharing-form-collapsed"
            } shadow-base bd-0`}
          style={{
            position: "relative",
            width: "100%",
            marginLeft: "15px",
            marginTop: "15px",
            borderRadius: "5px",
          }}
        >
          <div className="card-body d-flex align-items-center bg-white">
            <input
              ref={this._targetEmailRef}
              type="email"
              multiple
              className="form-control email-input tx-12"
              placeholder="Comma Seperated Emails"
            />
            <button
              onClick={this._sendReportLink}
              className="btn btn-info btn-block btn-send tx-12"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    );
  }

  getClassificationRibbon(grade, size = "", isDisabled = false) {
    if (grade === null) return null;
    const element = (
      <div
        className={`classification ${size} class${grade[0]} ${isDisabled && "disabled"
          }`}
      >
        {grade}
      </div>
    );
    return element;
  }

  getCurrency() {
    if (
      this.state.report &&
      this.state.report.building &&
      this.state.report.building.currency === "GBP"
    ) {
      return "£";
    }
    return "€";
  }

  // TOP SECTION

  getTitleSection() {
    let title = "Energy Performance Classification Report";
    let subtitle = "";
    let config_link = "";

    if (this.state.report) {
      title =
        this.state.report.building.name +
        " - Energy Performance Classification Report";
      subtitle = `Report generated for the timeframe of ${moment(this.state.report.ts_start).format("DD MMM, YYYY") +
        " - " +
        moment(this.state.report.ts_end).format("DD MMM, YYYY")
        }, based on `;
      config_link = (
        <span
          className="config-modal-link"
          onClick={() => this.setState({ config_modal_open: true })}
        >
          initial data
        </span>
      );
    }

    return (
      <div className="title-section row section">
        <div className="col-12 col-sm-8 pd-l-0">
          <h4 className="title">{title}</h4>
          <h4 className="sub-title">
            {subtitle}
            {config_link} and in accordance with{" "}
            <a
              href="https://www.iso.org/standard/65883.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              ISO 52120
            </a>
            .
          </h4>
        </div>
        <div className="col-12 col-sm-4 pd-r-0">
          <div className="buttons">
            <button
              className={
                this.state.show_report_sharing
                  ? "btn btn-warning"
                  : "btn btn-info"
              }
              onClick={() =>
                this.setState({
                  show_report_sharing: !this.state.show_report_sharing,
                })
              }
            >
              {this.state.show_report_sharing ? "Cancel" : "Share"}{" "}
              <Icon name='Send' size={20} color='#fff' style={{ verticalAlign: 'sub', paddingLeft: '5px' }} />
            </button>
            {/* <button className='btn btn-info' onClick={() => window.print()} >Print PDF</button> */}
          </div>
        </div>
        <div className="col-12 pd-r-0 pd-l-0">
          <div className="form-wrapper">{this.getSharingReportForm()}</div>
        </div>
      </div>
    );
  }

  // MAIN PANEL

  getMainSection() {
    const getSection = () => {
      const active_view = this.state.active_view;
      if (this.state.report === null) return this.getNoReportView();
      if (active_view === "OVERVIEW") return this.getOverview();
      if (active_view === "BUDGET_ANALYSIS") return this.getBudgetAnalysis();
      if (active_view === "ACTION_LIST") return this.getActionList();
      return this.getCategory(active_view);
    };

    const menuClickHandler = (active_view) => {
      this.setState({
        active_view: active_view,
        expanded_sections: [],
      });
    };

    return (
      <div className="main-container section card">
        <div className="container-header">
          {this.state.report && (
            <div
              onClick={() => menuClickHandler("OVERVIEW")}
              className={`header-section ${this.state.active_view === "OVERVIEW" && "selected"
                }`}
            >
              <span className="text">Overview</span>
            </div>
          )}
          {this.state.report && (
            <div
              onClick={() => menuClickHandler("ACTION_LIST")}
              className={`header-section ${this.state.active_view === "ACTION_LIST" && "selected"
                }`}
            >
              <span className="text">Opportunities</span>
            </div>
          )}
          {this.state.report && (
            <div
              onClick={() => menuClickHandler("BUDGET_ANALYSIS")}
              className={`header-section ${this.state.active_view === "BUDGET_ANALYSIS" && "selected"
                }`}
            >
              <span className="text">Budget Analysis</span>
            </div>
          )}
          {this.state.heating_data && (
            <div
              onClick={() => menuClickHandler("HEATING")}
              className={`header-section ${this.state.active_view === "HEATING" && "selected"
                }`}
            >
              <span className="text">Heating</span>
            </div>
          )}
          {this.state.cooling_data && (
            <div
              onClick={() => menuClickHandler("COOLING")}
              className={`header-section ${this.state.active_view === "COOLING" && "selected"
                }`}
            >
              <span className="text">Cooling</span>
            </div>
          )}
          {this.state.hot_water_data && (
            <div
              onClick={() => menuClickHandler("HOT_WATER")}
              className={`header-section ${this.state.active_view === "HOT_WATER" && "selected"
                }`}
            >
              <span className="text">Hot Water</span>
            </div>
          )}
          {this.state.ventilation_data && (
            <div
              onClick={() => menuClickHandler("VENTILATION")}
              className={`header-section ${this.state.active_view === "VENTILATION" && "selected"
                }`}
            >
              <span className="text">Ventilation</span>
            </div>
          )}

          {!this.state.report && (
            <div className="header-section disabled">Overview</div>
          )}
          {!this.state.report && (
            <div className="header-section disabled">Opportunities</div>
          )}
          {!this.state.report && (
            <div className="header-section disabled">Budget Analysis</div>
          )}
          {!this.state.heating_data && (
            <div className="header-section disabled">Heating</div>
          )}
          {!this.state.cooling_data && (
            <div className="header-section disabled">Cooling</div>
          )}
          {!this.state.hot_water_data && (
            <div className="header-section disabled">Hot Water</div>
          )}
          {!this.state.ventilation_data && (
            <div className="header-section disabled">Ventilation</div>
          )}
        </div>
        <div className="container-main-window">{getSection()}</div>
      </div>
    );
  }

  // NO REPORT VIEW

  getNoReportView() {
    return (
      <div className="no-report-wrapper">
        <img
          className="no-report-icon"
          alt="no sensor"
          src="/img/graphics/sensors-icon.png"
        />
        <div className="no-report-message">
          We could not retrieve your report at this time.
        </div>
        <div className="no-report-submessage">
          Please try again later or get in touch.
        </div>
      </div>
    );
  }

  // OVERVIEW SECTION

  getOverview() {
    const getOverviewIntroduction = () => {
      return (
        <div className="introduction row">
          <div className="col-12">
            <div className="title">Introduction</div>
          </div>
          <div className="col-12">
            <div className="paragraph">
              This assessment is based on the European standard EN ISO 52120
              which refers to the “Impact of building automation and of
              technical management on the energy consumption of buildings” and
              describes a method to estimate the efficiency of automation
              systems for all facilities that supply heating, cooling, domestic
              hot water, air conditioning and ventilation in a building.
            </div>
            <div className="paragraph">
              The automated version of this assessment by OPNBuildings uses a
              data driven approach to match the profile of behavior of each
              system to an associated grade and then estimates a savings
              potential if the automation system was setup in a way to achieve
              an A grade as per the standard.
            </div>
            <div className="paragraph">
              For detail into how the standard works refer to the{" "}
              <a
                href="https://www.iso.org/standard/65883.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                official standard documentation.
              </a>
            </div>
          </div>
        </div>
      );
    };
    const getOverviewCards = () => {
      const getValueCard = (label, value, subtext, suffix = "", iconText) => {
        let suffixElement = null;
        if (suffix) {
          suffixElement = <span className="suffix">{suffix}</span>;
        }

        return (
          <div className="overview-card base-shadow">
            {iconText && (
              <div className="icon">
                <Icon name='Info' color='#fff' data-rh={iconText} />
              </div>
            )}
            <div className="title">{label}</div>
            <div className="value">
              {value}
              {suffixElement}
            </div>
            <div className="subtext">{subtext}</div>
          </div>
        );
      };

      // needs grade in numeral or literal
      const getDiagramCard = (grade) => {
        // D      1.00 - 1.17
        // DC     1.17 - 1.5
        // CD     1.5 - 1.83
        // C      1.83 - 2.17
        // CB     2.17 - 2.5
        // BC     2.50 - 2.83
        // B      2.83 - 3.17
        // BA     3.17 - 3.5
        // AB     3.50 - 3.83
        // A      3.83 - 4.10

        // grade D 1.00 - 1.17
        let bottom = 5;
        let gradeLabel = "D";
        // grade DC
        if (grade > 1.17 && grade <= 1.5) {
          bottom = 11;
          gradeLabel = "D-C";
        } else if (grade > 1.5 && grade <= 1.83) {
          bottom = 25;
          gradeLabel = "C-D";
        } else if (grade > 1.83 && grade <= 2.17) {
          bottom = 31;
          gradeLabel = "C";
        } else if (grade > 2.17 && grade <= 2.5) {
          bottom = 36;
          gradeLabel = "C-B";
        } else if (grade > 2.5 && grade <= 2.83) {
          bottom = 50;
          gradeLabel = "B-C";
        } else if (grade > 2.83 && grade <= 3.17) {
          bottom = 57;
          gradeLabel = "B";
        } else if (grade > 3.17 && grade <= 3.5) {
          bottom = 63;
          gradeLabel = "B-A";
        } else if (grade > 3.5 && grade <= 3.83) {
          bottom = 76;
          gradeLabel = "A-B";
        } else if (grade > 3.83 && grade <= 4.1) {
          bottom = 83;
          gradeLabel = "A";
        }

        bottom = bottom + "px";

        return (
          <div className="overview-card diagram base-shadow">
            <div className="title">Grade</div>
            <div className="ribbons">
              <div className="classification classA">A</div>
              <div className="classification classB">B</div>
              <div className="classification classC">C</div>
              <div className="classification classD">D</div>
              <div className="indicator" style={{ bottom: bottom }}>
                {gradeLabel}
              </div>
            </div>
          </div>
        );
      };

      const generalCostSavings = GeneralUtils.getFormattedNumberWithUnit(
        this.state.total_data.savings_cost
      );
      const generalEmissionsSavings = GeneralUtils.getFormattedNumberWithUnit(
        this.state.total_data.savings_emissions
      );
      const generalEnergySavings = GeneralUtils.getFormattedNumberWithUnit(
        this.state.total_data.savings_consumption
      );

      const costPerGasKwh = GeneralUtils.getFormattedNumberWithUnit(
        this.state.report.building.effective_gas_rate,
        "",
        2
      );
      const costPerElectricityKwh = GeneralUtils.getFormattedNumberWithUnit(
        this.state.report.building.effective_elec_rate,
        "",
        2
      );
      const carbonPerGasKwh = GeneralUtils.getFormattedNumberWithUnit(
        this.state.report.building.effective_gas_carbon,
        " kgCO2",
        2
      );
      const carbonPerElectricityKwh = GeneralUtils.getFormattedNumberWithUnit(
        this.state.report.building.effective_elec_carbon,
        " kgCO2",
        2
      );

      return (
        <div className="cards row d-flex">
          <div className="col-12 col-sm-6 col-lg-3">
            {getDiagramCard(this.state.report.grade_value)}
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            {getValueCard(
              "Cost",
              this.getCurrency() + generalCostSavings,
              "Potential Savings",
              "",
              `Assuming ${this.getCurrency()}${costPerGasKwh} per kWh of gas, and ${this.getCurrency()}${costPerElectricityKwh} per kWh of electric power. This can be changed in building management settings.`
            )}
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            {getValueCard(
              "Emissions",
              generalEmissionsSavings,
              "Reduced Emissions",
              " kgCO2e",
              `Assuming ${carbonPerGasKwh} per kWh of gas, and ${carbonPerElectricityKwh} per kWh of electric power. This can be changed in building management settings.`
            )}
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            {getValueCard(
              "Energy",
              generalEnergySavings,
              "Energy Saved",
              " kWh"
            )}
          </div>
        </div>
      );
    };
    const getOverviewTable = () => {
      let future_variable = null;
      let current_variable = null;
      let savings_variable = null;
      let affix = null;
      let unit = null;
      let current_label = null;
      let future_label = null;
      let savings_label = null;

      if (this.state.overview_category === "ENERGY") {
        future_variable = this.state.total_data.future_consumption;
        current_variable = this.state.total_data.current_consumption;
        savings_variable = this.state.total_data.savings_consumption;
        affix = "consumption";
        unit = " kWh";
        current_label = "Current kWh";
        future_label = "Future Consumption";
        savings_label = "Reduction";
      }
      if (this.state.overview_category === "COST") {
        future_variable = this.state.total_data.future_cost;
        current_variable = this.state.total_data.current_cost;
        savings_variable = this.state.total_data.savings_cost;
        affix = "cost";
        unit = "";
        current_label = "Current Cost";
        future_label = "Future Cost";
        savings_label = "Savings";
      }
      if (this.state.overview_category === "EMISSIONS") {
        future_variable = this.state.total_data.future_emissions;
        current_variable = this.state.total_data.current_emissions;
        savings_variable = this.state.total_data.savings_emissions;
        affix = "emissions";
        unit = " kgCO2";
        current_label = "Current kgCO2e";
        future_label = "Future Emissions";
        savings_label = "Reduction";
      }

      const pieChartSeries = [
        {
          name: "Savings Breakdown",
          colorByPoint: true,
          data: [
            {
              name: future_label,
              y: future_variable,
              amount: future_variable,
              color: COST_COLOR,
            },
            {
              name: savings_label,
              y: savings_variable,
              amount: savings_variable,
              color: SAVINGS_COLOR,
            },
          ],
        },
      ];

      const savingAsPercentage = (savings_variable / current_variable) * 100;
      const centerText =
        savingAsPercentage === 0
          ? ""
          : GeneralUtils.getFormattedNumberWithUnit(savingAsPercentage, "%", 1);

      const getPieChartConfig = () => {
        return {
          plotOptions: {
            tooltip: {
              headerFormat: "",
            },
            pie: {
              dataLabels: {
                enabled: false,
              },
              cursor: "pointer",
              innerSize: "80%",
              borderWidth: 0,
              allowPointSelect: true,
            },
          },
        };
      };

      return (
        <div className="summary-table row">
          <div className="col-12 summary-header">
            <div className="savings-summary-title hidden-sm-down">
              Savings Summary
            </div>
            <div className="savings-summary-selectors">
              <div
                onClick={() => this.setState({ overview_category: "ENERGY" })}
                className={`savings-summary-selector ${this.state.overview_category === "ENERGY" ? "active" : ""
                  }`}
              >
                Energy
              </div>
              <div
                onClick={() => this.setState({ overview_category: "COST" })}
                className={`savings-summary-selector ${this.state.overview_category === "COST" ? "active" : ""
                  }`}
              >
                Cost
              </div>
              <div
                onClick={() =>
                  this.setState({ overview_category: "EMISSIONS" })
                }
                className={`savings-summary-selector ${this.state.overview_category === "EMISSIONS" ? "active" : ""
                  }`}
              >
                Emissions
              </div>
            </div>
          </div>
          <div className="col-12 row mg-0 summary-wrapper">
            <div className="col-12 col-lg-4 chart-section">
              {savingAsPercentage === 0 ? (
                <div className="optimised-chart" style={{ height: "200px" }}>
                  Optimised
                </div>
              ) : (
                <PieChart
                  innerSize={"80%"}
                  exporting={false}
                  series={pieChartSeries}
                  height={"200px"}
                  unit={unit}
                  backgroundColor={"transparent"}
                  borderWidth={0}
                  options={getPieChartConfig()}
                  centerText={"-" + centerText}
                  centerTextColor={"#3a9772"}
                  centerTextSize={30}
                />
              )}
              <table className="chart-table table">
                <tbody>
                  {pieChartSeries[0].data.map((serie) => {
                    return (
                      <tr className="table-row" key={serie.name}>
                        <td className="color">
                          <div
                            className="color-square"
                            style={{ background: serie.color }}
                          ></div>
                        </td>
                        <td className="label">{serie.name}</td>
                        <td className="value">
                          {this.state.overview_category === "COST" &&
                            this.getCurrency()}
                          {GeneralUtils.getFormattedNumberWithUnit(
                            serie.y,
                            unit,
                            0
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-12 col-lg-8 table-section">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td className="system-name">System</td>
                    <td className="current-consumption">{current_label}</td>
                    <td className="current-grade">Current Rating</td>
                    <td className="potential-savings">{savings_label}*</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.available_categories.map((cat) => {
                    let savings = (
                      <div className="savings-wrapper">
                        <div className="value">
                          {this.state.overview_category === "COST" &&
                            this.getCurrency()}
                          {GeneralUtils.getFormattedNumberWithUnit(
                            cat["savings_" + affix],
                            unit
                          )}
                        </div>
                        <div className="percentage">
                          -
                          {GeneralUtils.getFormattedNumberWithUnit(
                            (cat["savings_" + affix] /
                              (cat["savings_" + affix] +
                                cat["current_" + affix])) *
                            100,
                            "%",
                            1
                          )}
                        </div>
                      </div>
                    );

                    if (cat["savings_" + affix] === 0) {
                      savings = (
                        <div className="savings-wrapper">
                          <div className="value">No Savings</div>
                          <div className="percentage">-</div>
                        </div>
                      );
                    }

                    return (
                      <tr key={cat.label}>
                        <td className="system-name">{cat.label}</td>
                        <td className="current-consumption">
                          {this.state.overview_category === "COST" &&
                            this.getCurrency()}
                          {GeneralUtils.getFormattedNumberWithUnit(
                            cat["current_" + affix],
                            unit
                          )}
                        </td>
                        <td className="current-grade">
                          <div className="grade-wrapper">
                            {this.getClassificationRibbon(
                              cat.grade_name,
                              "uniform"
                            )}
                          </div>
                        </td>
                        <td className="potential-savings">{savings}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="disclaimer">
                <span className="asterisk">*</span> - Estimate based on
                achieving an 'A' grade.
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="overview-wrapper">
        {/* Introduction */}
        {getOverviewIntroduction()}

        {/* Cards */}
        {getOverviewCards()}

        {/* Table and Donut Chart*/}
        {getOverviewTable()}
      </div>
    );
  }

  // ACTION LIST SECTION

  getActionList() {
    let opportunities = [];

    this.state.available_categories.forEach((cat) => {
      cat.checks.forEach((check) => {
        if (check.asset_checks.length === 0) {
          opportunities.push({
            ...check,
            category: cat.category,
          });
        } else {
          check.asset_checks.forEach((asset) => {
            opportunities.push({
              ...asset,
              name: asset.asset_name + " - " + check.name,
              check: check.name,
              comment: check.comment,
              description: check.description,

              category: cat.category,
              requirements: check.requirements,
            });
          });
        }
      });
    });

    const all_opportunities = [...opportunities];

    const getNumberOfCategoryOpportunities = (category) => {
      return all_opportunities.filter((opp) => opp.category === category)
        .length;
    };

    // const getNumberOfGradeOpportunities = (grade) => {
    //   return opportunities.filter((opp) => opp.grade_name === grade).length;
    // };

    const getColorByGrade = (grade) => {
      return "color" + grade;
    };

    const getComment = (opportunity) => {
      let comment = "No comment associated with this grade was found.";
      const match = opportunity.requirements.find(
        (o) => o.grade_name === opportunity.grade_name
      );
      if (match) comment = match.comment;
      return comment;
    };

    opportunities = opportunities.map((opp, _, arr) => {
      let name = opp.name;

      if (arr.filter((e) => e.name === name).length > 1) {
        let category_formatted = "Heating";
        if (opp.category === "COOLING") category_formatted = "Cooling";
        if (opp.category === "HOT_WATER") category_formatted = "Hot Water";
        if (opp.category === "VENTILATION") category_formatted = "Ventilation";
        name += " - " + category_formatted;
      }

      return {
        ...opp,
        name: name,
      };
    });

    opportunities = opportunities
      .filter(
        (opp) =>
          this.state.filter_by_category === null ||
          this.state.filter_by_category === opp.category
      )
      .filter(
        (opp) =>
          this.state.filter_by_grade === null ||
          this.state.filter_by_grade === opp.grade_name
      )
      .filter((opp) =>
        opp.name.toLowerCase().includes(this.state.filter.toLowerCase())
      );

    opportunities = opportunities
      .sort((a, b) => a.grade_name.localeCompare(b.grade_name))
      .reverse();

    return (
      <div className="action-list-wrapper">
        <div className="action-list-filters">
          <div className="filter">
            <input
              type="text"
              className="filter-input"
              value={this.state.filter}
              placeholder={"Search"}
              onChange={(e) => this.setState({ filter: e.target.value })}
            />
            <div className="icon-wrapper">
              <Icon name='Search' color='#868ba1' />
            </div>
          </div>
          <div className="filter-by-category">
            <div className="filter-label">Filter by Category</div>
            <div className="filter-options">
              <div className="option">
                <div className="label">Heating</div>
                <div className="number-wrapper">
                  <div className="number">
                    {getNumberOfCategoryOpportunities("HEATING")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_category:
                          this.state.filter_by_category === "HEATING"
                            ? null
                            : "HEATING",
                      })
                    }
                  >
                    <div
                      className={`${this.state.filter_by_category === "HEATING" && "fill"
                        }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="option">
                <div className="label">Cooling</div>
                <div className="number-wrapper">
                  <div className="number">
                    {getNumberOfCategoryOpportunities("COOLING")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_category:
                          this.state.filter_by_category === "COOLING"
                            ? null
                            : "COOLING",
                      })
                    }
                  >
                    <div
                      className={`${this.state.filter_by_category === "COOLING" && "fill"
                        }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="option">
                <div className="label">Hot Water</div>
                <div className="number-wrapper">
                  <div className="number">
                    {getNumberOfCategoryOpportunities("HOT_WATER")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_category:
                          this.state.filter_by_category === "HOT_WATER"
                            ? null
                            : "HOT_WATER",
                      })
                    }
                  >
                    <div
                      className={`${this.state.filter_by_category === "HOT_WATER" && "fill"
                        }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="option">
                <div className="label">Ventilation</div>
                <div className="number-wrapper">
                  <div className="number">
                    {getNumberOfCategoryOpportunities("VENTILATION")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_category:
                          this.state.filter_by_category === "VENTILATION"
                            ? null
                            : "VENTILATION",
                      })
                    }
                  >
                    <div
                      className={`${this.state.filter_by_category === "VENTILATION" &&
                        "fill"
                        }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="filter-by-grade">
            <div className="filter-label">Filter by Grade</div>
            <div className="filter-options">
              <div className="option">
                <div className="label">A</div>
                <div className="number-wrapper">
                  <div className={`number ${getColorByGrade("A")}`}>
                    {getNumberOfGradeOpportunities("A")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_grade:
                          this.state.filter_by_grade === "A" ? null : "A",
                      })
                    }
                  >
                    <div
                      className={`${
                        this.state.filter_by_grade === "A" && "fill"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="option">
                <div className="label">B</div>
                <div className="number-wrapper">
                  <div className={`number ${getColorByGrade("B")}`}>
                    {getNumberOfGradeOpportunities("B")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_grade:
                          this.state.filter_by_grade === "B" ? null : "B",
                      })
                    }
                  >
                    <div
                      className={`${
                        this.state.filter_by_grade === "B" && "fill"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="option">
                <div className="label">C</div>
                <div className="number-wrapper">
                  <div className={`number ${getColorByGrade("C")}`}>
                    {getNumberOfGradeOpportunities("C")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_grade:
                          this.state.filter_by_grade === "C" ? null : "C",
                      })
                    }
                  >
                    <div
                      className={`${
                        this.state.filter_by_grade === "C" && "fill"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="option">
                <div className="label">D</div>
                <div className="number-wrapper">
                  <div className={`number ${getColorByGrade("D")}`}>
                    {getNumberOfGradeOpportunities("D")}
                  </div>
                </div>
                <div className="checkbox-wrapper">
                  <div
                    className="checkbox"
                    onClick={() =>
                      this.setState({
                        filter_by_grade:
                          this.state.filter_by_grade === "D" ? null : "D",
                      })
                    }
                  >
                    <div
                      className={`${
                        this.state.filter_by_grade === "D" && "fill"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="action-list">
          {opportunities.map((opp) => {
            return (
              <div className="action" key={opp.name + opp.category}>
                <div
                  className={`action-header ${getColorByGrade(
                    opp.grade_name
                  )} ${this.state.open_action === opp.name
                    ? "expanded"
                    : "collapsed"
                    }`}
                  onClick={() =>
                    this.setState({
                      open_action:
                        this.state.open_action === opp.name ? null : opp.name,
                    })
                  }
                >
                  <div className="expand">
                    <Icon
                      name='ArrowDown'
                      color='#000'
                      style={{ transform: this.state.open_action === opp.name ? "rotate(-90deg)" : "rotate(0deg)" }}
                    />
                  </div>
                  <div className="title">{opp.name}</div>
                </div>
                <div
                  className={`action-body ${getColorByGrade(opp.grade_name)} ${this.state.open_action === opp.name
                    ? "expanded"
                    : "collapsed"
                    }`}
                >
                  <div className="description">
                    <div className="grade">
                      <div
                        className={`circle ${getColorByGrade(opp.grade_name)}`}
                      >
                        {opp.grade_name}
                      </div>
                    </div>
                    <div className="comment">{getComment(opp)}</div>
                  </div>
                  <div className="requirements">
                    {opp.grade_name !== "A" && (
                      <div className="possible-upgrades">Possible Upgrades</div>
                    )}
                    {opp.grade_name === "A" && (
                      <div className="requirement">
                        <div className="grade">
                          <div className={`circle ${getColorByGrade("A")}`}>
                            <Icon name='Check' />
                          </div>
                        </div>
                        <div className="comment">This system is optimised.</div>
                      </div>
                    )}
                    {opp.grade_name !== "A" &&
                      opp.requirements.map((req, ind) => {
                        const grades = ["A", "B", "C", "D"];
                        const index = grades.indexOf(opp.grade_name);
                        const index_of_requirement = grades.indexOf(
                          req.grade_name
                        );

                        if (index_of_requirement >= index) {
                          return null;
                        }

                        return (
                          <div
                            className="requirement"
                            key={ind + req.grade_name}
                          >
                            <div className="grade">
                              <div
                                className={`circle ${getColorByGrade(
                                  req.grade_name
                                )}`}
                              >
                                {req.grade_name}
                              </div>
                            </div>
                            <div className="comment">{req.comment}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  // BUDGET ANALYSIS SECTION

  getBudgetAnalysis() {
    const savings_cost = this.state.total_data.savings_cost;
    const current_cost = this.state.total_data.current_cost;

    const categories = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5"];
    const savings_data = categories.map((_, index) => {
      return savings_cost * (index + 1);
    });
    const future_cost_data = categories.map((_, index) => {
      return current_cost - savings_cost * (index + 1);
    });

    const getChartAndCard = () => {
      const getColumnChart = () => {
        const options = {
          chart: {
            type: "column",
          },
          title: {
            text: null,
          },
          xAxis: {
            categories: categories,
          },
          yAxis: {
            min: 0,
            max: current_cost,
            endOnTick: false,
            title: {
              text: "Cost",
            },
            stackLabels: {
              enabled: false,
            },
          },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat: `{series.name}: ${this.getCurrency()}{point.y}`,
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: false,
              },
              stickyTracking: true,
              events: {
                mouseOut: () =>
                  this.setState({ budget_analysis_hover: "Year 3" }),
              },
              point: {
                events: {
                  mouseOver: (x) =>
                    this.setState({ budget_analysis_hover: x.target.category }),
                },
              },
            },
          },
          series: [
            {
              name: "Savings",
              data: savings_data,
              color: SAVINGS_COLOR,
            },
            {
              name: "Future Cost",
              data: future_cost_data,
              color: COST_COLOR,
            },
          ],
          exporting: {
            enabled: false,
          },
        };

        return (
          <div className="col-12 col-lg-8" style={{ height: "300px" }}>
            <HighChart
              chart_Tag={"stacked-bar-budget"}
              options={options}
              series={options.series}
            />
          </div>
        );
      };

      const getProjectCard = () => {
        const chart_category = this.state.budget_analysis_hover;
        const year = chart_category.charAt(chart_category.length - 1);
        const value = GeneralUtils.getFormattedNumberWithUnit(
          savings_data[year - 1]
        );

        return (
          <div className="col-12 col-lg-4">
            <div className="card project-card">
              <div className="project-card-title">
                Potential Budget For Improvements
              </div>
              <div className="project-card-subtitle">
                With a {year} year payback
              </div>
              <div className="project-card-value">
                {this.getCurrency()}
                {value}
              </div>
            </div>
          </div>
        );
      };

      return (
        <>
          {getColumnChart()}
          {getProjectCard()}
        </>
      );
    };

    const getPieCharts = () => {
      const getPieChart = (category) => {
        const series = [
          {
            name: "Savings Breakdown",
            colorByPoint: true,
            data: [
              {
                name: "Future Cost",
                y: category.future_cost,
                amount: category.future_cost,
                color: "#3a9772",
              },
              {
                name: "Potential Savings",
                y: category.savings_cost,
                amount: category.savings_cost,
                color: "#b8D4C8",
              },
            ],
          },
        ];

        const pieChartConfig = {
          plotOptions: {
            tooltip: {
              headerFormat: "",
              pointFormat: `{point.name}<br/> Percent: <b>{point.percentage:.1f} %</b> <br/> Value: <b>${this.getCurrency()}{point.amount:.0f}</b>`,
            },
            pie: {
              dataLabels: {
                enabled: false,
              },
              cursor: "pointer",
              innerSize: "87%",
              borderWidth: 0,
              allowPointSelect: true,
            },
          },
        };

        const data = [
          {
            name: "Current Cost",
            y: category.current_cost,
            amount: category.current_cost,
            color: ["#3a9772", "#b8D4C8"],
          },
          ...series[0].data,
        ];

        const savingAsPercentage =
          (category.savings_cost / category.current_cost) * 100;
        const centerText =
          savingAsPercentage === 0
            ? ""
            : GeneralUtils.getFormattedNumberWithUnit(
              savingAsPercentage,
              "%",
              1
            );

        return (
          <div className="col donut-chart" key={category.label}>
            <div className="title">{category.label} Savings</div>
            {savingAsPercentage === 0 ? (
              <div className="optimised-chart" style={{ height: "250px" }}>
                Optimised
              </div>
            ) : (
              <PieChart
                exporting={false}
                series={series}
                height={"200px"}
                backgroundColor={"transparent"}
                borderWidth={0}
                options={pieChartConfig}
                centerText={"-" + centerText}
                centerTextColor={"#3a9772"}
                centerTextSize={30}
                unitInFront
                unit={this.getCurrency()}
              />
            )}
            {savingAsPercentage === 0 ? null : (
              <table className="chart-table table">
                <tbody>
                  {data.map((serie) => {
                    let colorRow = (
                      <td className="color">
                        <div
                          className="color-square"
                          style={{ background: serie.color }}
                        ></div>
                      </td>
                    );
                    if (Array.isArray(serie.color)) {
                      colorRow = (
                        <td className="color">
                          <div
                            className="color-square"
                            style={{ background: serie.color[0] }}
                          >
                            <div
                              className="color-square-2"
                              style={{ background: serie.color[1] }}
                            ></div>
                          </div>
                        </td>
                      );
                    }
                    return (
                      <tr className="table-row" key={serie.name}>
                        {colorRow}
                        <td className="label">{serie.name}</td>
                        <td className="value">
                          {this.getCurrency()}
                          {GeneralUtils.getFormattedNumberWithUnit(
                            serie.y,
                            "",
                            0
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        );
      };

      return (
        <>
          {this.state.available_categories.map((data) => {
            return getPieChart(data);
          })}
        </>
      );
    };

    return (
      <div className="budget-analysis-wrapper">
        <div className="project-forecast row">{getChartAndCard()}</div>
        <div className="donut-charts row">{getPieCharts()}</div>
      </div>
    );
  }

  // CATEGORY SECTION

  getCategory(active_view) {
    let source_data = null;

    if (active_view === "HEATING") source_data = this.state.heating_data;
    if (active_view === "COOLING") source_data = this.state.cooling_data;
    if (active_view === "HOT_WATER") source_data = this.state.hot_water_data;
    if (active_view === "VENTILATION")
      source_data = this.state.ventilation_data;

    const getCategoryIntroductionAndGrade = () => {
      return (
        <div className="introduction row">
          <div className="col-12 col-lg-7">
            <div className="title">{source_data.label} Systems</div>
            <div className="paragraph">{source_data.description}</div>
            <div className="paragraph">{source_data.comment}</div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="grade-wrapper">
              {this.getClassificationRibbon(source_data.grade_name, "large")}
            </div>
          </div>
        </div>
      );
    };

    const getPieChartAndCheckTable = () => {
      const series = [
        {
          name: "Savings Breakdown",
          colorByPoint: true,
          data: [
            {
              name: "Future Consumption",
              y: source_data.future_consumption,
              amount: source_data.future_consumption,
              color: "#3a9772",
            },
            {
              name: "Potential Savings",
              y: source_data.savings_consumption,
              amount: source_data.savings_consumption,
              color: "#b8D4C8",
            },
          ],
        },
      ];

      const savingAsPercentage =
        (source_data.savings_consumption /
          (source_data.future_consumption + source_data.savings_consumption)) *
        100;
      const centerText =
        savingAsPercentage === 0
          ? ""
          : GeneralUtils.getFormattedNumberWithUnit(savingAsPercentage, "%", 1);

      const unit = "kWh";

      const pieChartConfig = {
        plotOptions: {
          tooltip: {
            headerFormat: "",
            pointFormat: `{point.name}<br/> Percent: <b>{point.percentage:.1f} %</b> <br/> Value: <b>{point.amount:.0f} ${unit}</b>`,
          },
          pie: {
            dataLabels: {
              enabled: false,
            },
            cursor: "pointer",
            innerSize: "80%",
            borderWidth: 0,
            allowPointSelect: true,
          },
        },
      };

      return (
        <div className="pie-and-table row">
          <div className="col-12 pie-and-table-wrapper">
            <div className="col-12 col-lg-5 chart-section">
              {savingAsPercentage === 0 ? (
                <div className="optimised-chart" style={{ height: "200px" }}>
                  Optimised
                </div>
              ) : (
                <PieChart
                  innerSize={"80%"}
                  exporting={false}
                  series={series}
                  height={"200px"}
                  backgroundColor={"transparent"}
                  borderWidth={0}
                  options={pieChartConfig}
                  centerText={"-" + centerText}
                  centerTextColor={"#3a9772"}
                  centerTextSize={30}
                />
              )}
              <table className="chart-table table">
                <tbody>
                  {series[0].data.map((serie) => {
                    return (
                      <tr className="table-row" key={serie.name}>
                        <td className="color">
                          <div
                            className="color-square"
                            style={{ background: serie.color }}
                          ></div>
                        </td>
                        <td className="label">{serie.name}</td>
                        <td className="value">
                          {GeneralUtils.getFormattedNumberWithUnit(
                            serie.y,
                            " kWh",
                            0
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-12 col-lg-7 check-table-section">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <td className="info-icon wd-5p"></td>
                    <td className="check-name wd-60p">Check Name</td>
                    <td className="current-grade wd-40p">Grade</td>
                  </tr>
                </thead>
                <tbody>
                  {source_data.checks.map((check) => {
                    return (
                      <tr key={check.name}>
                        <td className="info-icon">
                          <Icon name='Info' color='#fff' data-rh={check.comment} />
                        </td>
                        <td className="check-name">{check.name}</td>
                        <td className="current-grade">
                          <div className="grade-wrapper">
                            {this.getClassificationRibbon(
                              check.grade_name,
                              "uniform"
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    };

    const getDataAnalysis = (category) => {
      const section_name = category + "_charts_section";
      const section_expanded =
        this.state.expanded_sections.includes(section_name);
      const chart_loading = this.state.chart_loading === category;
      const expanded_and_chart_loading = section_expanded && chart_loading;

      const optionsConfig = {
        exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "separator",
                "downloadPNG",
                "downloadPDF",
                "downloadCSV",
              ],
            },
          },
        },
        navigation: {},
      };

      const loading_spinner = (
        <div className="spinner-wrapper">
          <Spinner text="Chart data is being loaded..." height="250px" />
        </div>
      );

      const getCharts = (category) => {
        const getHeatingCharts = (config) => {
          const charts_array = this.state.chart_data["HEATING"];
          if (
            charts_array &&
            charts_array.length === 0 &&
            this.state.chart_loading === null
          )
            return (
              <div className="chart-wrapper">
                <div className="chart-placeholder">
                  <Icon name='Warning' color='#868ba1' />
                  <div className="chart-title">No chart data received.</div>
                </div>
              </div>
            );

          const getHeatGenerationChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Heat Generation"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getDistributionChartOne = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Heat Distribution - Temperatures"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getDistributionChartTwo = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Heat Distribution - Run Status"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" %"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getSupplyEmissionChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Supply Emission Chart"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <SupplyEmissionChart
                    series={chart_data.data}
                    chart_Tag={chart_data.name}
                    valueSuffix={" Setpoints"}
                    yAxisTitle={"Number of Setpoints"}
                    options={config}
                  />
                </div>
              </div>
            );
          };

          return (
            <div className="heating-charts">
              <div className="row">{getHeatGenerationChart()}</div>
              <div className="row">{getDistributionChartOne()}</div>
              <div className="row">{getDistributionChartTwo()}</div>
              <div className="row">{getSupplyEmissionChart()}</div>
            </div>
          );
        };

        const getCoolingCharts = (config) => {
          const charts_array = this.state.chart_data["COOLING"];
          if (
            charts_array &&
            charts_array.length === 0 &&
            this.state.chart_loading === null
          )
            return (
              <div className="chart-wrapper">
                <div className="chart-placeholder">
                  <Icon name='Warning' color='#868ba1' />
                  <div className="chart-title">No chart data received.</div>
                </div>
              </div>
            );

          const getCoolingGenerationChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Cooling Generation"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getDistributionChartOne = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Cooling Distribution - Temperatures"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getDistributionChartTwo = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Cooling Distribution - Run Status"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" %"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getSupplyEmissionChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Supply Emission Chart"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <SupplyEmissionChart
                    series={chart_data.data}
                    chart_Tag={chart_data.name}
                    valueSuffix={" Setpoints"}
                    yAxisTitle={"Number of Setpoints"}
                    options={config}
                  />
                </div>
              </div>
            );
          };

          return (
            <div className="cooling-charts">
              <div className="row">{getCoolingGenerationChart()}</div>
              <div className="row">{getDistributionChartOne()}</div>
              <div className="row">{getDistributionChartTwo()}</div>
              <div className="row">{getSupplyEmissionChart()}</div>
            </div>
          );
        };

        const getHotWaterCharts = (config) => {
          const charts_array = this.state.chart_data["HOT_WATER"];
          if (
            charts_array &&
            charts_array.length === 0 &&
            this.state.chart_loading === null
          )
            return (
              <div className="chart-wrapper">
                <div className="chart-placeholder">
                  <Icon name='Warning' color='#868ba1' />
                  <div className="chart-title">No chart data received.</div>
                </div>
              </div>
            );

          const getTemperaturesChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Temperature Profiles"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getActivityChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Hot Water System Activity"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };

          return (
            <div className="hot-water-charts">
              <div className="row">{getTemperaturesChart()}</div>
              <div className="row">{getActivityChart()}</div>
            </div>
          );
        };

        const getVentilationCharts = (config) => {
          const charts_array = this.state.chart_data["VENTILATION"];
          if (
            charts_array &&
            charts_array.length === 0 &&
            this.state.chart_loading === null
          )
            return (
              <div className="chart-wrapper">
                <div className="chart-placeholder">
                  <Icon name='Warning' color='#868ba1' />
                  <div className="chart-title">No chart data received.</div>
                </div>
              </div>
            );

          const getTemperaturesChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Temperature Profiles"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" °C"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getFanSpeedsChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Fan Speed Profiles"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={"%"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };
          const getHeatRecoveryProfilesChart = () => {
            const chart_data = charts_array.find(
              (chart) => chart.name === "Heat Recovery Profiles"
            );
            if (chart_data === undefined) return null;

            return (
              <div className="col-12 column">
                <div className="chart-wrapper">
                  <div className="chart-title">{chart_data.name}</div>
                  <div className="chart-description">
                    {chart_data.description}
                  </div>
                  <HighChart
                    main_type="line"
                    interval={"day"}
                    series={chart_data.data}
                    dataType={" %"}
                    chart_Tag={chart_data.name}
                    showLegend={0}
                    options={config}
                  />
                </div>
              </div>
            );
          };

          return (
            <div className="ventilation-charts">
              <div className="row">{getTemperaturesChart()}</div>
              <div className="row">{getFanSpeedsChart()}</div>
              <div className="row">{getHeatRecoveryProfilesChart()}</div>
            </div>
          );
        };

        if (category === "HEATING") return getHeatingCharts(optionsConfig);
        if (category === "COOLING") return getCoolingCharts(optionsConfig);
        if (category === "HOT_WATER") return getHotWaterCharts(optionsConfig);
        if (category === "VENTILATION")
          return getVentilationCharts(optionsConfig);
      };

      return (
        <div className="data-analysis row">
          <div className="col-12">
            <div className="charts-header">
              <div className="title">Data Analysis</div>
              <div
                className="expand"
                onClick={() => this.expandSection(section_name, category)}
              >
                <Icon
                  name='ArrowDown'
                  color='#000'
                  style={{ transform: section_expanded ? "rotate(90deg)" : "rotate(0deg)" }}
                />
              </div>
            </div>
            <div
              className={`charts-body ${section_expanded ? "expanded" : "collapsed"
                }`}
            >
              {expanded_and_chart_loading
                ? loading_spinner
                : getCharts(category)}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="category-wrapper">
        {/* Introduction */}
        {getCategoryIntroductionAndGrade()}

        {/* Table and Donut Chart*/}
        {getPieChartAndCheckTable()}

        {/* Data Analysis Drawer */}
        {getDataAnalysis(source_data.category)}
      </div>
    );
  }

  getConfigModal() {
    if (this.state.config_modal_open) {
      const report = this.state.report;

      const formattedStartDate = moment(report.ts_start).format("DD MMM, YYYY");
      const formattedEndDate = moment(report.ts_end).format("DD MMM, YYYY");

      const categories = Constants.ASSESSMENT_OVERRIDES.filter(
        (el) => el.category === "category" && report.overrides.includes(el.code)
      );
      const overrides = Constants.ASSESSMENT_OVERRIDES.filter(
        (el) => el.category !== "category" && report.overrides.includes(el.code)
      );

      return (
        <Modal
          hasExit
          toggleOpen={() => this.setState({ config_modal_open: false })}
        >
          <div className="preview">
            {/* Building */}
            {report.building.name && (
              <div className="line">
                <span className="label">Building: </span>
                {report.building.name}
              </div>
            )}
            {/* Timeframe */}
            {report.ts_start && report.ts_end && (
              <div>
                <span className="label">Timeframe: </span> {formattedStartDate}{" "}
                - {formattedEndDate}
              </div>
            )}
            <hr />
            {/*Spend Amounts */}
            {(report.current_heating_consumption > 0 ||
              report.current_cooling_consumption > 0 ||
              report.current_ventilation_consumption > 0 ||
              report.current_hot_water_consumption > 0) && (
                <div className="line">
                  <span className="label">Energy Consumption Breakdown:</span>
                </div>
              )}
            {report.current_heating_consumption > 0 && (
              <div className="list-item">
                {" "}
                - Heating System :{" "}
                {GeneralUtils.getFormattedNumberWithUnit(
                  report.current_heating_consumption,
                  " kWh"
                )}
              </div>
            )}
            {report.current_cooling_consumption > 0 && (
              <div className="list-item">
                {" "}
                - Cooling System :{" "}
                {GeneralUtils.getFormattedNumberWithUnit(
                  report.current_cooling_consumption,
                  " kWh"
                )}
              </div>
            )}
            {report.current_ventilation_consumption > 0 && (
              <div className="list-item">
                {" "}
                - Ventilation System :{" "}
                {GeneralUtils.getFormattedNumberWithUnit(
                  report.current_ventilation_consumption,
                  " kWh"
                )}
              </div>
            )}
            {report.current_hot_water_consumption > 0 && (
              <div className="list-item">
                {" "}
                - Hot Water System :{" "}
                {GeneralUtils.getFormattedNumberWithUnit(
                  report.current_hot_water_consumption,
                  " kWh"
                )}
              </div>
            )}
            <hr />
            {/* Categories */}
            {categories.length > 0 && (
              <div className="line">
                <span className="label">Categories:</span>
              </div>
            )}
            {categories.map((cat, index) => {
              const category_label = `- ${cat.name}`;
              const present_categories = this.state.available_categories.map(
                (el) => el.category
              );
              let has_no_data = ` - (Check didn't run due to insufficient data)`;
              if (
                cat.name === "Enable Heating Checks" &&
                present_categories.includes("HEATING") === true
              )
                has_no_data = null;
              if (
                cat.name === "Enable Cooling Checks" &&
                present_categories.includes("COOLING") === true
              )
                has_no_data = null;
              if (
                cat.name === "Enable Ventilation Checks" &&
                present_categories.includes("VENTILATION") === true
              )
                has_no_data = null;
              if (
                cat.name === "Enable Hot Water Checks" &&
                present_categories.includes("HOT_WATER") === true
              )
                has_no_data = null;

              return (
                <div className="list-item" key={cat.name + index}>
                  {category_label}
                  {has_no_data}
                </div>
              );
            })}
            <hr />
            {/* Overrides */}
            {overrides.length > 0 && (
              <div className="line">
                <span className="label">Overrides:</span>
              </div>
            )}
            {overrides.map((ovr, index) => (
              <div className="list-item" key={ovr.name + index}>
                - {ovr.name}
                <br />
                {ovr.description}
              </div>
            ))}
          </div>
        </Modal>
      );
    }
    return null;
  }

  render() {
    if (this.state.loading) {
      return (
        <div id="EnergyClassificationReport">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div id="EnergyClassificationReport">
          <ReactHint events autoPosition />
          <DisclaimerModal
            localStorageKey="OPNAssessmentDisclaimer"
            text={
              "Please be aware that financial estimates provided in this report are an estimation and may not be representative to the real world findings The savings are based on assuming a grade of A is achieved for each measured system."
            }
            title={"Energy Classification Disclaimer"}
          />
          <DocumentTitle title="Energy Performance Classification Report" />

          {/* CONFIG MODAL  */}
          {this.getConfigModal()}

          {/* TOP SECTION  */}
          {this.getTitleSection()}

          {/* MAIN CARD */}
          {this.getMainSection()}
        </div>
      );
    }
  }
}

export default EnergyClassificationReport;
