import React, { Component } from 'react';
import { Row, Col, Typography, Form, Input, Button, Card } from 'antd';
import './ManagePassword.scss';
import UserActions from '../../actions/userActions';
import UserStore from '../../stores/userStore';

import {PasswordChecker} from '../../components/PasswordChecker'
import {DocumentTitle} from '../../components/DocumentTitle'

const { Title, Text } = Typography;

class ManagePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: UserStore.getUser(),
      passwordValidated: false,
      password: '',
      confirmPassword: ''
    };
    this.setPasswordValidation = this.setPasswordValidation.bind(this);
  }

  updatePassword = (values) => {
    if (this.state.passwordValidated) {
      UserActions.changePassword(this.state.user, values.password);
      this.formRef.resetFields();
    }
  };

  setPasswordValidation(boolean) {
    this.setState({passwordValidated: boolean})
  }

  formRef = React.createRef();

  render() {
    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title='Password Change' />
        <div className="br-container">
          <Row gutter={[30, 30]} className='mg-t-30'>
            <Col span={24}>
              <Title level={3}>Password Change</Title>
              <Text type='secondary'>
                Update your account password using the form below.
              </Text>
            </Col>
            <Col span={12}>
              <Card>
                <Form
                  ref={this.formRef}
                  onFinish={this.updatePassword}
                  layout="vertical"
                >
                  <Form.Item
                    label="New Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your new password!' }]}
                  >
                    <Input.Password placeholder="Enter your new password" onChange={(e) => this.setState({password: e.target.value})}/>
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Please confirm your password!' }]}
                  >
                    <Input.Password placeholder="Confirm your new password" onChange={(e) => this.setState({confirmPassword: e.target.value})}/>
                  </Form.Item>
                  <Form.Item>
                    <PasswordChecker 
                      password={this.state.password} 
                      confirmPassword={this.state.confirmPassword} 
                      setPasswordValidation={this.setPasswordValidation}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      block 
                      disabled={!this.state.passwordValidated}
                    >
                      Update
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default ManagePassword;
