import ActionTypes from "../constants";
import Dispatcher from "../dispatcher";
import GeneralUtils from "../utils/GeneralUtils";
import _ from 'lodash';


class ConsumptionAnalysisActions {
    getConsumptionAnalysisMetersList(timeframe, orgId = null) {
        const truncatedTimeframe = timeframe.truncateEnd();
        const intervalQueryParameters = GeneralUtils.toIntervalQueryParameterString(truncatedTimeframe)
        return GeneralUtils.get({
            url: orgId
                ? `energy/consumption-analysis-list/organisation/${orgId}?${intervalQueryParameters}`
                : `energy/consumption-analysis-list?${intervalQueryParameters}`,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_LIST_FETCHED,
            failToast: "Unable to fetch consumption analysis list, please try again"
        });
    }
    getConsumptionData(meter_id, timeframe) {
        const truncatedTimeframe = timeframe.truncateEnd();
        const intervalQueryParameters = GeneralUtils.toIntervalQueryParameterString(truncatedTimeframe)
        return GeneralUtils.get({
            url: `energy/consumption-analysis/meter/${meter_id}?${intervalQueryParameters}`,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_DATA_FETCHED,
        });
    }

    generateToken(tokenObject) {
        return GeneralUtils.post({
            url: "tokens/generate",
            object: tokenObject,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_TOKEN_GENERATED,
            modifyResponse: (response) => _.get(response, "token"),
            failToast: "Error creating sharing token.",
        });
    }

    getAnalysisByToken(token) {
        return GeneralUtils.get({
            url: `tokens/process?token=${token}`,
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_TOKEN_DATA_FETCHED,
            failToast: "Error fetching analysis data",
        });
    }

    setTimeframe(timeframeObject) {
        Dispatcher.dispatch({
            actionType: ActionTypes.CONSUMPTION_ANALYSIS_TIMEFRAME_SET,
            payload: {
                timeframe: timeframeObject.timeframe,
                granularity: timeframeObject.granularity
            }
        });
    }

}

const consumptionAnalysisActions = new ConsumptionAnalysisActions();
export default consumptionAnalysisActions;
