import React, { useEffect, useState } from 'react';
import './OrganisationAdmin.scss';
import UserStore from '../../stores/userStore';
import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import { DocumentTitle } from '../../components/DocumentTitle';
import { Typography, Row, Col } from "antd";
import { LogoSpinner } from '../../components/LogoSpinner';

import OrganisationManagement from './OrganisationManagement';
import EstateManagement from './EstateManagement';

const { Title, Text } = Typography;

const OrganisationAdmin = ({ history }) => {
  const [user] = useState(UserStore.getUser());
  const [orgs, setOrgs] = useState(OrganisationStore.getOrganisations());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const onOrganisationChange = () => {
      setOrgs(OrganisationStore.getOrganisations());
      setLoading(false);
    };

    OrganisationStore.addChangeListener(onOrganisationChange);

    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(false);
    }

    return () => {
      OrganisationStore.removeChangeListener(onOrganisationChange);
    };
  }, [history]);

  useEffect(() => {
    const onOrganisationOperation = () => {
      OrganisationActions.getOrganisations(false);
    };

    OrganisationStore.addOperationListener(onOrganisationOperation);

    return () => {
      OrganisationStore.removeOperationListener(onOrganisationOperation);
    };
  }, []);

  return (
    <div className="br-mainpanel br-profile-page floorplan-background">
      <DocumentTitle title='Change Organisation' />
      <LogoSpinner loading={loading} />
      <div className="br-container">
        <Row gutter={[30, 30]} className='mg-t-30'>
          <Col span={24}>
            <Title level={3}>Organisation Admin</Title>
            <Text type='secondary'>
              Add and edit organisations and estates. Change the organisation your account belongs to (SuperAdmins Only).
            </Text>
          </Col>

          <Col lg={12} xs={24}>
            <OrganisationManagement user={user} organisations={orgs} setLoading={setLoading}/>
          </Col>

          <Col lg={12} xs={24}>
            <EstateManagement user={user} organisations={orgs} setLoading={setLoading}/>
          </Col>

        </Row>
      </div>
    </div>
  );
};

export default OrganisationAdmin;
