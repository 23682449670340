import ActionTypes from "../constants";
import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to fetching organisation data. This class interfaces with the API
 * to retrieve data and dispatches actions to update the application state.
 *
 * @class
 * @example
 * const organisationActions = new OrganisationActions();
 * organisationActions.getOrganisation('org123');
 */
class OrganisationActions {
  /**
   * Fetches the details of a specific organisation by its ID from the API and dispatches an action to store this data.
   *
   * @param {string} orgId - The unique identifier of the organisation.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getOrganisation(orgId) {
    return GeneralUtils.get({
      url: `organisations/${orgId}`,
      actionType: ActionTypes.ORGANISATION_FETCHED,
      failToast: "Unable to fetch organisation, please try again",
    });
  }

  /**
   * Fetches the list of organisations from the API, optionally including their buildings, and dispatches an action to store this data.
   *
   * @param {string} [showBuildings="false"] - Whether to include buildings in the response.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getOrganisations(showBuildings = false) {
    return GeneralUtils.get({
      url: `organisations/?showBuildings=${showBuildings}`,
      actionType: ActionTypes.ORGANISATIONS_FETCHED,
      failToast: "Unable to fetch organisations, please try again",
    });
  }

  createOrganisation(organisation) {
    return GeneralUtils.post({
      url: `organisations`,
      actionType: ActionTypes.ORGANISATION_CREATED,
      object: organisation,
      successToast: "Organisation created successfully",
      failToast: "Unable to create organisation, please try again",
    });
  }

  updateOrganisation(organisation) {
    return GeneralUtils.put({
      url: `organisations`,
      actionType: ActionTypes.ORGANISATION_UPDATED,
      object: organisation,
      successToast: "Organisation updated successfully",
      failToast: "Unable to update organisation, please try again",
    });
  }

  deleteOrganisation(organisationId) {
    return GeneralUtils.delete({
      url: `organisations/${organisationId}`,
      actionType: ActionTypes.ORGANISATION_DELETED,
      successToast: "Organisation deleted successfully",
      failToast: "Unable to delete organisation, please try again",
    });
  }

  createEstate(estate) {
    return GeneralUtils.post({
      url: `estates`,
      actionType: ActionTypes.ESTATE_CREATED,
      object: estate,
      successToast: "Estate created successfully",
      failToast: "Unable to create estate, please try again",
    });
  }

  updateEstate(estate) {
    return GeneralUtils.put({
      url: `estates`,
      actionType: ActionTypes.ESTATE_UPDATED,
      object: estate,
      successToast: "Estate updated successfully",
      failToast: "Unable to update estate, please try again",
    });
  }

  deleteEstate(estateId) {
    return GeneralUtils.delete({
      url: `estates/${estateId}`,
      actionType: ActionTypes.ESTATE_DELETED,
      successToast: "Estate deleted successfully",
      failToast: "Unable to delete estate, please try again",
    });
  }
}

const organisationActions = new OrganisationActions();
export default organisationActions;
