import React, { Component } from 'react';
import './EnergyReportDetail.scss';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';


import ReportActions from '../../../../actions/reportActions';
import GeneralStore from '../../../../stores/generalStore';
import ReportStore from '../../../../stores/reportStore';
import { Spinner } from '../../../../components/Spinner';
import { Icon } from '../../../../components/Icon';
import Constants from '../../../../constants';
import GeneralUtils from '../../../../utils/GeneralUtils';
import { HighChart } from '../../../../components/HighChart';
import { PieChart } from '../../../../components/PieChart';
import { DocumentTitle } from '../../../../components/DocumentTitle';
import { BenchmarkReportTable } from '../../../../components/BenchmarkReportTable';

class EnergyReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ts_start: GeneralStore.getStartDate(),
      ts_end: GeneralStore.getEndDate(),
      selected_building_id: this.props.building_id ? this.props.building_id : this.props.match.params.building_id,
      show_all_electric_consumers: false,
      show_all_gas_consumers: false,
      show_all_water_consumers: false,
      energy_benchmark_report: ReportStore.getEnergyBenchmarkReport(),
      show_report_sharing: false,
      loading: true,
    };

    this._targetEmailRef = React.createRef();
    this._onReportChange = this._onReportChange.bind(this);
    this._onDateChange = this._onDateChange.bind(this);
    this._toggleShowAllElectricConsumers = this._toggleShowAllElectricConsumers.bind(this);
    this._toggleShowAllGasConsumers = this._toggleShowAllGasConsumers.bind(this);
    this._toggleShowAllWaterConsumers = this._toggleShowAllWaterConsumers.bind(this);
    this._openMeter = this._openMeter.bind(this);
    this._showShareForm = this._showShareForm.bind(this);
    this._sendReportLink = this._sendReportLink.bind(this);
    this._fetchData = this._fetchData.bind(this);
  }
  _onDateChange() {
    this.setState({
      ts_start: GeneralStore.getStartDate(), ts_end: GeneralStore.getEndDate(), loading: true
    });
  }
  _onReportChange() {
    this.setState({
      energy_benchmark_report: ReportStore.getEnergyBenchmarkReport(), loading: false, show_report_sharing: false
    });
  }
  UNSAFE_componentWillMount() {
    ReportStore.addChangeListener(this._onReportChange);
    GeneralStore.addChangeListener(this._onDateChange);
  }
  componentWillUnmount() {
    ReportStore.removeChangeListener(this._onReportChange);
    GeneralStore.removeChangeListener(this._onDateChange);
    ReportStore.clear();
  }
  componentDidMount() {
    let building_id = (this.props.building_id ? this.props.building_id : this.props.match.params.building_id);
    if (building_id) {
      this._fetchData(building_id, GeneralStore.getStartDate(), GeneralStore.getEndDate());
    }
  }
  componentWillUpdate() {
    let ts_start = GeneralStore.getStartDate();
    let ts_end = GeneralStore.getEndDate();
    let building_id = (this.props.building_id ? this.props.building_id : this.props.match.params.building_id);
    if (this.state.ts_start !== ts_start || this.state.ts_end !== ts_end || this.state.selected_building_id !== building_id) {
      this._fetchData(building_id, ts_start, ts_end);
    }
  }
  _fetchData(building_id, ts_start, ts_end) {
    if (this.props.token) {
      ReportActions.getEnergyBenchmarkReportWithToken(this.props.token);
    } else {
      ReportActions.getEnergyBenchmarkReport(building_id, ts_start, ts_end);
    }
  }
  _toggleShowAllElectricConsumers() {
    if (this.state.show_all_electric_consumers) {
      this.setState({ show_all_electric_consumers: false })
    } else {
      this.setState({ show_all_electric_consumers: true })
    }
  }
  _toggleShowAllGasConsumers() {
    if (this.state.show_all_gas_consumers) {
      this.setState({ show_all_gas_consumers: false })
    } else {
      this.setState({ show_all_gas_consumers: true })
    }
  }
  _toggleShowAllWaterConsumers() {
    if (this.state.show_all_water_consumers) {
      this.setState({ show_all_water_consumers: false })
    } else {
      this.setState({ show_all_water_consumers: true })
    }
  }
  _sendReportLink() {
    let emails = this._targetEmailRef.current.value;
    let email_list = emails.split(',');
    let validated_list = [];
    for (let i = 0; i < email_list.length; i++) {
      if (email_list[i].length > 0 && GeneralUtils.emailIsValid(email_list[i])) {
        validated_list.push(email_list[i]);
      }
    }
    if (validated_list.length === 0) {
      toast.warn("No valid emails entered, please try again")
    } else {
      for (let i = 0; i < validated_list.length; i++) {
        ReportActions.sendEnergyReportEmail(validated_list[i], this.state.selected_building_id, GeneralStore.getStartDate(), GeneralStore.getEndDate())
      }
      toast.success("Report sent to recipients successfully")
      this._targetEmailRef.current.value = '';
      this.setState({ show_report_sharing: false })
    }
  }
  _showShareForm() {
    if (this.state.show_report_sharing) {
      this.setState({ show_report_sharing: false })
    } else {
      this.setState({ show_report_sharing: true })
    }
  }

  getElectricChartProfile() {
    let chart_data = ReportStore.getElectricProfileData();
    if (chart_data.length > 0) {
      let chart_id = '_' + Math.random().toString(36).substr(2, 9);
      return (
        <div className="row mg-t-0 mg-b-0">
          <div className="col-12 col-sm-12">
            <div className="card card-body shadow-base bd-0 bg-white mg-t-0 mg-b-15" style={{ height: '280px', paddingBottom: '0px' }}>
              <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
                <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Electric Consumption</h6>
              </div>
              <HighChart main_type="column" exporting={true} hideYAxisLabels={false} hideXAxisLabels={false} series={chart_data} dataType={' kWh'} chart_Tag={chart_id} group_gap_only={true} showFullDayOnXAxis={true} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row mg-t-0 mg-b-0">
          <div className="col-12 col-sm-12">
            <div className="card card-body shadow-base bd-0 bg-white mg-t-0 mg-b-15" style={{ height: '280px', paddingBottom: '0px' }}>
              <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
                <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Electric Consumption</h6>
              </div>
              <span className="tx-12" style={{ opacity: '0.6' }}><img alt="no sensor" style={{ width: '160px', height: '160px' }} src="/img/graphics/sensors-icon.png" /><br />No Data Available</span>
            </div>
          </div>
        </div>
      )
    }
  }
  getGasChartProfile() {
    let chart_data = ReportStore.getGasProfileData();
    if (chart_data.length > 0) {
      let chart_id = '_' + Math.random().toString(36).substr(2, 9);
      return (
        <div className="row mg-t-0 mg-b-0">
          <div className="col-12 col-sm-12">
            <div className="card card-body shadow-base bd-0 bg-white mg-t-0 mg-b-15" style={{ height: '280px', paddingBottom: '0px' }}>
              <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
                <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Gas Consumption</h6>
              </div>
              <HighChart main_type="column" exporting={true} hideYAxisLabels={false} hideXAxisLabels={false} series={chart_data} dataType={' kWh'} chart_Tag={chart_id} group_gap_only={true} showFullDayOnXAxis={true} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row mg-t-0 mg-b-0">
          <div className="col-12 col-sm-12">
            <div className="card card-body shadow-base bd-0 bg-white mg-t-0 mg-b-15" style={{ height: '280px', paddingBottom: '0px' }}>
              <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
                <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Gas Consumption</h6>
              </div>
              <span className="tx-12" style={{ opacity: '0.6' }}><img alt="no sensor" style={{ width: '160px', height: '160px' }} src="/img/graphics/sensors-icon.png" /><br />No Data Available</span>
            </div>
          </div>
        </div>
      )
    }
  }
  getWaterChartProfile() {
    let chart_data = ReportStore.getWaterProfileData();
    if (chart_data.length > 0 && chart_data[0].data.length > 0) {
      let chart_id = '_' + Math.random().toString(36).substr(2, 9);
      return (
        <div className="row mg-t-0 mg-b-0">
          <div className="col-12 col-sm-12">
            <div className="card card-body shadow-base bd-0 bg-white mg-t-0 mg-b-15" style={{ height: '280px', paddingBottom: '0px' }}>
              <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
                <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Water Consumption</h6>
              </div>
              <HighChart main_type="column" exporting={true} hideYAxisLabels={false} hideXAxisLabels={false} series={chart_data} dataType={' Ltr'} chart_Tag={chart_id} group_gap_only={true} showFullDayOnXAxis={true} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="row mg-t-0 mg-b-0">
          <div className="col-12 col-sm-12">
            <div className="card card-body shadow-base bd-0 bg-white mg-t-0 mg-b-15" style={{ paddingBottom: '10px' }}>
              <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
                <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Water Consumption</h6>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  _openMeter(meter_id) {
    let building_id = this.state.selected_building_id;
    if (building_id === 0) {
      building_id = (this.props.building_id ? this.props.building_id : this.props.match.params.building_id);
    }
    let defaultRoute = `/building/${building_id}/energy/${meter_id}?ts_start=${GeneralStore.getStartDate()}&ts_end=${GeneralStore.getEndDate()}`;
    this.props.history.push(defaultRoute);
  }
  getElectricTopConsumers() {
    let all_meters = _.orderBy(this.state.energy_benchmark_report.all_electric_meter_total, 'percent_change', 'desc');
    let top_meters = _.take(all_meters, 5);
    let meterListButton = <span onClick={this._toggleShowAllElectricConsumers} style={{ cursor: 'pointer' }}><Icon name="ArrowDown" color="#000000" style={{ marginRight: '5px' }} />View All Meters</span>;
    let listTitle = "Top 5 Electric Increases";
    if (this.state.show_all_electric_consumers) {
      top_meters = all_meters;
      listTitle = "All Electric Consumers";
      meterListButton = <span onClick={this._toggleShowAllElectricConsumers} style={{ cursor: 'pointer' }}><Icon name="ArrowUp" color="#000000" style={{ marginRight: '5px' }} />Hide All Meters</span>;
    }
    return <BenchmarkReportTable items={top_meters} title={listTitle} itemListButton={meterListButton} unit={'electricity'} openItemHandler={this._openMeter} />
  }
  getGasTopConsumers() {
    let all_meters = _.orderBy(this.state.energy_benchmark_report.all_gas_meter_total, 'percent_change', 'desc');
    let top_meters = _.take(all_meters, 5);
    let meterListButton = <span onClick={this._toggleShowAllGasConsumers} style={{ cursor: 'pointer' }}><Icon name="ArrowDown" color="#000000" style={{ marginRight: '5px' }} />View All Meters</span>;
    let listTitle = "Top 5 Gas Increases";
    if (this.state.show_all_gas_consumers) {
      top_meters = all_meters;
      listTitle = "All Gas Consumers";
      meterListButton = <span onClick={this._toggleShowAllGasConsumers} style={{ cursor: 'pointer' }}><Icon name="ArrowUp" color="#000000" style={{ marginRight: '5px' }} />Hide All Meters</span>;
    }
    return <BenchmarkReportTable items={top_meters} title={listTitle} itemListButton={meterListButton} unit={'gas'} openItemHandler={this._openMeter} />
  }
  getWaterTopConsumers() {
    let all_meters = _.orderBy(this.state.energy_benchmark_report.all_water_meter_total, 'percent_change', 'desc');
    let top_meters = _.take(all_meters, 5);
    let meterListButton = <span onClick={this._toggleShowAllWaterConsumers} style={{ cursor: 'pointer' }}><Icon name="ArrowDown" color="#000000" style={{ marginRight: '5px' }} />View All Meters</span>;
    let listTitle = "Top 5 Water Increases";
    if (this.state.show_all_water_consumers) {
      top_meters = all_meters;
      listTitle = "All Water Consumers";
      meterListButton = <span onClick={this._toggleShowAllWaterConsumers} style={{ cursor: 'pointer' }}><Icon name="ArrowUp" color="#000000" style={{ marginRight: '5px' }} />Hide All Meters</span>;
    }
    return <BenchmarkReportTable items={top_meters} title={listTitle} itemListButton={meterListButton} unit={'water'} openItemHandler={this._openMeter} />

  }
  getElectricDayVsNight() {
    let chart_data = ReportStore.getElectricDayNightData();
    if (chart_data.length > 0) {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Operating Vs Non Operating Hours - Electric</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff" }}>
            <h6 className="card-title tx-36 tx-center pd-0 mg-0">
              <PieChart innerSize={'45%'} exporting={true} series={chart_data} height={"280"} />
            </h6>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Operating Vs Non Operating Hours - Electric</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff", height: '288px' }}>
            <p className="tx-14 tx-center pd-0 mg-0" style={{ marginTop: '120px' }}>
              No Meters Available
            </p>
          </div>
        </div>
      )
    }
  }
  getGasDayVsNight() {
    let chart_data = ReportStore.getGasDayNightData();
    if (chart_data.length > 0) {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Operating Vs Non Operating Hours - Gas</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff" }}>
            <h6 className="card-title tx-36 tx-center pd-0 mg-0">
              <PieChart innerSize={'45%'} exporting={true} series={chart_data} height={"280"} />
            </h6>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Operating Vs Non Operating Hours - Gas</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff", height: '288px' }}>
            <p className="tx-14 tx-center pd-0 mg-0" style={{ marginTop: '120px' }}>
              No Meters Available
            </p>
          </div>
        </div>
      )
    }
  }
  getWaterDayVsNight() {
    let chart_data = ReportStore.getWaterDayNightData();
    if (chart_data.length > 0 && chart_data[0].data.length > 0 && (chart_data[0].data[0].y > 0 || chart_data[0].data[1].y > 0)) {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Operating Vs Non Operating Hours - Water</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff" }}>
            <h6 className="card-title tx-36 tx-center pd-0 mg-0">
              <PieChart innerSize={'45%'} exporting={true} series={chart_data} height={"280"} unit={'litres'} />
            </h6>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Operating Vs Non Operating Hours - Water</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff", height: '288px' }}>
            <p className="tx-14 tx-center pd-0 mg-0" style={{ marginTop: '120px' }}>
              No Meters Available
            </p>
          </div>
        </div>
      )
    }
  }
  getElectricBreakdown() {
    let chart_data = ReportStore.getElectricBreakdownData();
    if (chart_data.length > 0 && chart_data[0].data.length > 0) {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Meter Breakdown - Electric</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff" }}>
            <h6 className="card-title tx-36 tx-center pd-0 mg-0">
              <PieChart innerSize={'45%'} exporting={true} series={chart_data} height={"280"} primaryColor={Constants.BLUE} />
            </h6>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Meter Breakdown - Electric</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff", height: '288px' }}>
            <p className="tx-14 tx-center pd-0 mg-0" style={{ marginTop: '120px' }}>
              No Submeters Available
            </p>
          </div>
        </div>
      )
    }
  }
  getGasBreakdown() {
    let chart_data = ReportStore.getGasBreakdownData();
    if (chart_data.length > 0 && chart_data[0].data.length > 0) {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Meter Breakdown - Gas</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff" }}>
            <h6 className="card-title tx-36 tx-center pd-0 mg-0">
              <PieChart innerSize={'45%'} exporting={true} series={chart_data} height={"280"} primaryColor={Constants.RED} />
            </h6>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Meter Breakdown - Gas</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff", height: '288px' }}>
            <p className="tx-14 tx-center pd-0 mg-0" style={{ marginTop: '120px' }}>
              No Submeters Available
            </p>
          </div>
        </div>
      )
    }
  }
  getWaterBreakdown() {
    let chart_data = ReportStore.getWaterBreakdownData();
    if (chart_data.length > 0 && chart_data[0].data.length > 0) {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Meter Breakdown - Water</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff" }}>
            <h6 className="card-title tx-36 tx-center pd-0 mg-0">
              <PieChart innerSize={'45%'} exporting={true} series={chart_data} height={"280"} primaryColor={Constants.GREEN} />
            </h6>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card bd-0 shadow-base">
          <div className="card-header bg-transparent pd-x-25 pd-y-15 bd d-flex justify-content-between align-items-center">
            <h6 className="card-title tx-uppercase tx-12 mg-b-0">Meter Breakdown - Water</h6>
          </div>
          <div className="card-body bd pd-b-0 pd-t-0" style={{ backgroundColor: "#fff", height: '288px' }}>
            <p className="tx-14 tx-center pd-0 mg-0" style={{ marginTop: '120px' }}>
              No Submeters Available
            </p>
          </div>
        </div>
      )
    }
  }
  getElectricBalances() {
    return (
      <div className="row mg-t-0">
        <div className="col-12 col-sm-12 col-md-6 mg-b-15">
          {this.getElectricDayVsNight()}
        </div>
        <div className="col-12 col-sm-12 col-md-6 mg-b-15">
          {this.getElectricBreakdown()}
        </div>
      </div>
    )
  }
  getGasBalances() {
    return (
      <div className="row mg-t-0">
        <div className="col-12 col-sm-12 col-md-6 mg-b-15">
          {this.getGasDayVsNight()}
        </div>
        <div className="col-12 col-sm-12 col-md-6 mg-b-15">
          {this.getGasBreakdown()}
        </div>
      </div>
    )
  }
  getWaterBalances() {
    return (
      <div className="row mg-t-0">
        <div className="col-12 col-sm-12 col-md-6 mg-b-15">
          {this.getWaterDayVsNight()}
        </div>
        <div className="col-12 col-sm-12 col-md-6 mg-b-15">
          {this.getWaterBreakdown()}
        </div>
      </div>
    )
  }
  getEnergyBalance() {

    if (this.state.energy_benchmark_report && this.state.energy_benchmark_report.total_kwh > 0) {
      let total_kwh = this.state.energy_benchmark_report.total_kwh;
      let gas_kwh = this.state.energy_benchmark_report.gas_kwh;
      let electric_kwh = this.state.energy_benchmark_report.electric_kwh;
      let percent_gas = (gas_kwh / total_kwh) * 100;
      let percent_elec = (electric_kwh / total_kwh) * 100;
      return <div className="card card-body shadow-base bd-0 pd-25 bg-white mg-t-0 mg-b-15">
        <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
          <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-b-0">Energy Balance</h6>
        </div>
        <div className="progress bg-white-3 rounded-0 mg-b-0" style={{ height: '30px' }}>
          <div className="progress-bar lh-6" role="progressbar" title="electrical" style={{ width: percent_elec + '%', backgroundColor: Constants.BLUE }} aria-valuenow={percent_elec} aria-valuemin="0" aria-valuemax="100">{Constants.Numberformatter().format(percent_elec)} %</div>
          <div className="progress-bar lh-6" role="progressbar" title="gas" style={{ width: percent_gas + '%', backgroundColor: Constants.RED }} aria-valuenow={percent_gas} aria-valuemin="0" aria-valuemax="100">{Constants.Numberformatter().format(percent_gas)} %</div>
        </div>
      </div>
    } else {
      return <div className="card card-body shadow-base bd-0 pd-x-10 pd-y-2 bg-white mg-t-0 mg-b-15">
        <div className="d-xs-flex justify-content-between align-items-center tx-white mg-b-10">
          <h6 className="tx-13 tx-uppercase tx-inverse tx-semibold tx-spacing-1 mg-t-10 mg-b-0">No Data Available For Report</h6>
        </div>
      </div>
    }

  }
  getWaterCharts() {
    if (this.state.energy_benchmark_report.water_consumptions && this.state.energy_benchmark_report.water_consumptions.length > 0 && this.state.energy_benchmark_report.water_total > 0) {
      return (
        <React.Fragment>
          <hr />
          {this.getWaterChartProfile()}
          {this.getWaterBalances()}
          {this.getWaterTopConsumers()}
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
  getGasCharts() {
    if (this.state.energy_benchmark_report.gas_consumptions && this.state.energy_benchmark_report.gas_consumptions.length > 0 && this.state.energy_benchmark_report.gas_kwh > 0) {
      return (
        <React.Fragment>
          <hr />
          {this.getGasChartProfile()}
          {this.getGasBalances()}
          {this.getGasTopConsumers()}
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
  getElectricCharts() {
    if (this.state.energy_benchmark_report.electric_consumptions && this.state.energy_benchmark_report.electric_consumptions.length > 0 && this.state.energy_benchmark_report.electric_kwh > 0) {
      return (
        <React.Fragment>
          <hr />
          {this.getElectricChartProfile()}
          {this.getElectricBalances()}
          {this.getElectricTopConsumers()}
        </React.Fragment>
      )
    } else {
      return null;
    }
  }
  getSharingReportForm() {
    if (this.state.show_report_sharing) {
      return (
        <React.Fragment>
          <div className="row mg-t-0">
            <div className="col-12">
              <button onClick={this._showShareForm} className="btn btn-info btn-block">Cancel <Icon name="Close" color="#ffffff" style={{ marginLeft: '5px', verticalAlign: 'sub' }} /></button>
            </div>
          </div>
          <div className="row mg-t-0" style={{ position: 'absolute', zIndex: 2, width: '100%' }}>
            <div className="card shadow-base bd-0" style={{ position: 'relative', width: '100%', marginLeft: '15px', marginRight: '15px' }}>
              <div className="card-body justify-content-between align-items-center bg-white">
                <div className="row align-items-center mg-t-5">
                  <div className="col-12 tx-12 tx-bold">
                    <input ref={this._targetEmailRef} type="email" multiple className="form-control tx-12" placeholder="Comma Seperated Emails" />
                  </div>
                </div>
                <div className="row align-items-center mg-t-5">
                  <div className="col-12 tx-12 tx-bold">
                    <button onClick={this._sendReportLink} className="btn btn-outline-info btn-block tx-12">Send <Icon name="Mail" color="#17A2B8" style={{ marginLeft: '5px', verticalAlign: 'text-top' }} /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>)
    } else {
      return (
        <div className="row mg-t-0">
          <div className="col-12">
            <button onClick={this._showShareForm} className="btn btn-outline-info btn-block">Share Selected Report <Icon name="Mail" color="#17A2B8" style={{ marginLeft: '5px', verticalAlign: 'text-top' }} /></button>
          </div>
        </div>)
    }
  }
  render() {

    const start = new Date(this.state.energy_benchmark_report.ts_start)
    const end = new Date(this.state.energy_benchmark_report.ts_end)
    const subtitle = `Showing the data from ${moment(new Date(start)).format("MM-DD-YYYY")} to ${moment(end).format("MM-DD-YYYY")}.`

    if (this.state.loading) {
      return (
        <div className="br-mainpanel br-profile-page" style={{ scrollY: 'scroll' }}>
          <div className="br-pagebody">
            <Spinner />
          </div>
        </div>
      );
    } else {
      if (this.props.token) {
        return (
          <div id='EnergyReportDetail' style={{ marginTop: '0px', paddingTop: '0px', paddingLeft: '20px', paddingRight: '20px' }}>
            <DocumentTitle title={this.state.energy_benchmark_report.name ? `Energy Report | ${this.state.energy_benchmark_report.name}` : 'Energy Report'} />
            <div className="tab-pane fade active show" id="posts">
              <div className="row">
                <div className="col-12 col-sm-12">
                  <div className="br-pagetitle mg-b-30 mg-t-0 pd-l-0" style={{ width: '100%', float: "left" }}>
                    <div style={{ paddingLeft: '0px' }}>
                      <h4>Detailed Energy Report for {this.state.energy_benchmark_report.name}</h4>
                      {subtitle}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.getEnergyBalance()}
                  {this.getElectricCharts()}
                  {this.getGasCharts()}
                  {this.getWaterCharts()}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="br-mainpanel br-profile-page" style={{ scrollY: 'scroll' }}>
            <div id='EnergyReportDetail' style={{ marginTop: '0px', paddingTop: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
              <DocumentTitle title={this.state.energy_benchmark_report.name ? `Energy Report | ${this.state.energy_benchmark_report.name}` : 'Energy Report'} />
              <div className="tab-pane fade active show" id="posts">
                <div className="row">
                  <div className="col-12 col-sm-9">
                    <div className="br-pagetitle mg-b-30 mg-t-0 pd-l-0" style={{ width: '100%', float: "left" }}>
                      <div style={{ paddingLeft: '0px' }}>
                        <h4>Detailed Energy Report for {this.state.energy_benchmark_report.name}</h4>
                        {subtitle}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 pd-t-20">
                    {this.getSharingReportForm()}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.getEnergyBalance()}
                    {this.getElectricCharts()}
                    {this.getGasCharts()}
                    {this.getWaterCharts()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

    }
  }
}

export default EnergyReportDetail;