import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { Icon } from '../Icon';

import nativeStyles from './EditableNameInput.module.scss';

function EditableNameInput({
  name,
  onNameChange,
  onNameChangeComplete,
  styles
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [initialName, setInitialName] = useState(name);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
      completionFunction();
    } else if (event.key === 'Escape') {
      onNameChange(initialName);
      setIsEditing(false);
    }
  };

  const onBlur = () => {
    setIsEditing(false);
    completionFunction();
  }

  const completionFunction = () => {
    if (onNameChangeComplete && name !== initialName) onNameChangeComplete(name);
  }

  if (isEditing) {
    return (
      <div className={styles ? styles.configName : nativeStyles.configName}>
        <Input
          value={name}
          onChange={e => onNameChange(e.target.value)}
          style={{ width: 'auto' }}
          onBlur={onBlur}
          autoFocus
          allowClear
          onKeyDown={handleKeyPress}
        />
      </div>
    );
  }

  return (
    <div className={styles ? styles.configName : nativeStyles.configName} onClick={() => {
      setIsEditing(true);
      setInitialName(name);
    }}>
      <span style={{ fontSize: '20px' }}>{name}</span>
      <Button
        icon={<Icon name="Edit" color={'#5e656e'} size={15} />}
        size="small"
        style={{ marginLeft: '20px', border: 'none', outline: 'none' }}
      />
    </div>
  );
}

export default EditableNameInput;