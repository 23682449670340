import React, { useState } from 'react';
import { Card, Button, Modal, Form, Input, Select, Alert, Row, Col } from 'antd';
import OrganisationActions from '../../actions/organisationActions';

const EstateManagement = ({ organisations=[], user, estates = [], setLoading }) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedEstate, setSelectedEstate] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [deleteForm] = Form.useForm();

  const formattedOrgs = organisations.map(org => ({
    value: org.organisation_id,
    label: org.name,
    ...org,
  }));

  const handleCreate = (values) => {
    setLoading(true);

    const newEstate = {
      organisation_id: values.organisation_id,
      name: values.name,
      description: values.description,
      status: values.status,
    }

    OrganisationActions.createEstate(newEstate)
    .then(() => {
      setCreateModalVisible(false);
      createForm.resetFields();
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleUpdate = (values) => {
    setLoading(true);

    const updatedValues = {
      organisation_id: selectedOrg?.organisation_id,
      estate_id: selectedEstate?.estate_id,
      name: values.name,
      description: values.description,
      status: values.status,
    }

    OrganisationActions.updateEstate(updatedValues)
    .then(() => {
      setUpdateModalVisible(false);
      updateForm.resetFields();
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleDelete = () => {
    setLoading(true);

    OrganisationActions.deleteEstate(selectedEstate.estate_id)
    .then(() => {
      setDeleteModalVisible(false);
      setSelectedEstate(null);
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleEstateSelect = (value, formInstance) => {
    const selectedOption = selectedOrg.estates.find(estate => estate.estate_id === value);
    setSelectedEstate(selectedOption);
    formInstance.setFieldsValue({
      name: selectedOption.name,
      description: selectedOption.description,
      status: selectedOption.status
    });
  };

  const handleOrgSelect = (value, formInstance) => {
    const selectedOption = formattedOrgs.find(org => org.value === value);
    setSelectedOrg(selectedOption);
    setSelectedEstate(null);
    formInstance.resetFields(['estate', 'name', 'description', 'status']);
  };

  const getFilteredEstates = () => {
    if (!selectedOrg) return [];

    const estates = selectedOrg.estates;
    const options = estates.map(estate => ({
        value: estate.estate_id,
        label: estate.name,
        ...estate
    }));

    return options
  };

  const handleShowModal = (modalSetter) => {
    resetForm(modalSetter);
    setSelectedEstate(null);
    modalSetter(true);
  };

  const handleModalClose = (modalSetter) => {
    resetForm(modalSetter);
    setSelectedEstate(null);
    modalSetter(false);
  };

  const resetForm = (formInstance) => {
    if (formInstance === setUpdateModalVisible) {
      updateForm.resetFields();
    } else if (formInstance === setDeleteModalVisible) {
      deleteForm.resetFields();
    } else {
      createForm.resetFields();
    }
  };

  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];

  const CreateForm = () => (
    <Form
      form={createForm}
      layout="vertical"
      initialValues={{
        name: '',
        description: '',
        status: 'active'
      }}
    >
      <Form.Item
        name="organisation_id"
        label="Organization"
        rules={[{ required: true, message: 'Please select an organization' }]}
      >
        <Select
          showSearch
          options={formattedOrgs}
          placeholder="Select organization"
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name="name"
        label="Estate Name"
        rules={[{ required: true, message: 'Please input estate name' }]}
      >
        <Input placeholder="Enter estate name" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Please input description' }]}
      >
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>
      
      <Form.Item
        name="status"
        label="Status"
        rules={[{ required: true, message: 'Please select status' }]}
      >
        <Select
          style={{ width: 200 }}
          options={statusOptions}
          placeholder="Select status"
        />
      </Form.Item>
    </Form>
  );

  const UpdateForm = () => (
    <Form
      form={updateForm}
      layout="vertical"
      values={selectedEstate}
    >
      <Form.Item
        name="organisation_id"
        label="Organization"
        rules={[{ required: true, message: 'Please select an organization' }]}
      >
        <Select
          showSearch
          options={formattedOrgs}
          placeholder="Select organization"
          optionFilterProp="label"
          onChange={(value) => handleOrgSelect(value, updateForm)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      
        <Form.Item
          name="estate"
          label="Select Estate"
          rules={[{ required: true, message: 'Please select an estate' }]}
        >
          <Select
            showSearch
            options={getFilteredEstates()}
            value={selectedEstate?.estate_id}
            placeholder="Select estate"
            disabled={!selectedOrg}
            optionFilterProp="label"
            onChange={(value) => handleEstateSelect(value, updateForm)}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>

        <Form.Item
          name="name"
          label="Estate Name"
          rules={[{ required: true, message: 'Please input estate name' }]}
          >
            <Input placeholder="Enter estate name" disabled={!selectedEstate}/>
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please input description' }]}
          >
          <Input.TextArea placeholder="Enter description" disabled={!selectedEstate}/>
        </Form.Item>
          
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Please select status' }]}

          >
          <Select
            disabled={!selectedEstate}
            style={{ width: 200 }}
            options={statusOptions}
            placeholder="Select status"
          />
        </Form.Item>

    </Form>
  );

  const DeleteForm = () => (
    <Form
      form={deleteForm}
      layout="vertical"
    >
      <Form.Item
        name="organisation_id"
        label="Organization"
        rules={[{ required: true, message: 'Please select an organization' }]}
      >
        <Select
          showSearch
          options={formattedOrgs}
          placeholder="Select organization"
          optionFilterProp="label"
          onChange={(value) => handleOrgSelect(value, deleteForm)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      
      <Form.Item
        name="estate"
        label="Select Estate"
        rules={[{ required: true, message: 'Please select an estate' }]}
      >
        <Select
          showSearch
          options={getFilteredEstates()}
          placeholder="Select estate"
          disabled={!selectedOrg}
          optionFilterProp="label"
          onChange={(value) => handleEstateSelect(value, deleteForm)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      {selectedEstate && (
        <Alert
          message="Warning"
          description={`Are you sure you want to delete ${selectedEstate.name}?`}
          type="warning"
          showIcon
        />
      )}
    </Form>
  );

  return (
    <Card title="Estate Management">
      <Row gutter={[15,15]} style={{ marginBottom: '20px' }}>
        <Col lg={8} md={8} xs={24}>
          <Button 
            type="primary" 
            style={{ 
              backgroundColor: 'green',
              width: '100%',
              height: '50px'
            }} 
            onClick={() => handleShowModal(setCreateModalVisible)}
          >
            Create Estate
          </Button>
        </Col>
        <Col lg={8} md={8} xs={24}>
          <Button 
            type="primary" 
            style={{ 
              width: '100%',
              height: '50px'
            }}
            onClick={() => handleShowModal(setUpdateModalVisible)}
          >
            Update Estate
          </Button>
        </Col>
        <Col lg={8} md={8} xs={24}>
          <Button 
            type="primary" 
            danger 
            style={{ 
              width: '100%',
              height: '50px'
            }}
            onClick={() => handleShowModal(setDeleteModalVisible)}
          >
            Delete Estate
          </Button>
        </Col>
      </Row>

      <Modal
        title="Create Estate"
        open={createModalVisible}
        onOk={() => createForm.validateFields().then(handleCreate)}
        onClose={() => handleModalClose(setCreateModalVisible)}
        onCancel={() => handleModalClose(setCreateModalVisible)}
      >
        <CreateForm />
      </Modal>

      <Modal
        title="Update Estate"
        open={updateModalVisible}
        onOk={() => updateForm.validateFields().then(handleUpdate)}
        onClose={() => handleModalClose(setUpdateModalVisible)}
        onCancel={() => handleModalClose(setUpdateModalVisible)}
      >
        <UpdateForm />
      </Modal>

      <Modal
        title="Delete Estate"
        open={deleteModalVisible}
        onOk={handleDelete}
        onClose={() => handleModalClose(setDeleteModalVisible)}
        onCancel={() => handleModalClose(setDeleteModalVisible)}
        okText="Delete"
        okButtonProps={{ danger: true }}
      >
        <DeleteForm />
      </Modal>
    </Card>
  );
};

export default EstateManagement; 