import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber, 
  Select,
  Checkbox,
  Button,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import Constants from "../../../constants";
import VirtualCalculationBuilder from "../VirtualCalculationBuilder";
import isEqual from "lodash/isEqual";

/**
 * Determines allowed virtual meter types based on the base meter type.
 *
 * @param {string} baseType - The selected base meter type.
 * @returns {array} - Array of allowed virtual meter types.
 */
const getAllowedVirtualTypes = (baseType) => {
  if (baseType === 'ELECTRIC' || baseType === 'GAS') return ['ELECTRIC', 'GAS'];
  return [baseType];
};

/**
 * Determines meter type config based on the base meter type.
 *
 * @param {string} meterType - The selected base meter type.
 * @returns {object} - Meter type config object.
 */
const getMeterTypeConfig = (meterType) => {
  return Constants.METER_TYPE_CONFIG[meterType] || {};
};

/**
 * MeterModal Component
 *
 * Handles both adding and editing meters, including Pulse Rate and Conversion Rate.
 */
const MeterModal = ({
  isSuperUser,
  open,
  onCancel,
  onSubmit,
  meter = null,
  buildings = [],
  allMeters = [],
}) => {
  const [form] = Form.useForm();
  const builderRef = useRef(null);
  const [parentMeters, setParentMeters] = useState([]);
  const [virtualMeterOptions, setVirtualMeterOptions] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  // Determine if the modal is in edit mode based on presence of meter ID
  const isEditMode = !!meter?.meter?.id;

  /**
   * Populate form fields and set meter options when the modal becomes open.
   */
  useEffect(() => {
    if (open) {
      if (isEditMode) {
        // Store initial form values for dirty check
        const initialValues = {
          meter_id: meter.meter.id,
          building_id: meter.building.id,
          name: meter.meter.name,
          type: meter.meter.type,
          category: meter.meter.category,
          main: meter.meter.main,
          parent_id: meter.meter.parent_id,
          conversion_rate: meter.meter.conversion_rate,
          virtual_calc: (meter.meter.virtual_calc ?? '').replace(/\s/g, ''), // Strip white space
          ext_ref: meter.meter.ext_ref,
        };

        form.setFieldsValue({ ...initialValues });

        // Filter meters belonging to the selected building for parent meter options
        const buildingId = meter.building.id;
        const buildingMeters = allMeters.filter((m) => m.building.id === buildingId);
        setParentMeters(buildingMeters);

        // Determine allowed virtual meter types based on selected type
        const baseType = meter.meter.type;
        const allowedVirtualTypes = getAllowedVirtualTypes(baseType);
        const virtualMeters = buildingMeters.filter(m => allowedVirtualTypes.includes(m.meter.type));
        setVirtualMeterOptions(virtualMeters);

        setInitialFormValues(initialValues);
        setIsDirty(false);
      } else {
        // Reset form for adding a new meter
        form.resetFields();
        setParentMeters([]);
        setVirtualMeterOptions([]);

        // Store initial form values (empty)
        setInitialFormValues({});
        setIsDirty(false);
      }
    } else {
      // Reset form and meters when modal is closed
      form.resetFields();
      setParentMeters([]);
      setVirtualMeterOptions([]);

      // Reset initial form values and dirty state
      setInitialFormValues({});
      setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isEditMode, meter, allMeters, form]);

  /**
   * Handle changes in form values.
   */
  const handleValuesChange = (changedValues, allValues) => {
    let buildingChanged = false;
    let typeChanged = false;

    if (changedValues.building_id !== undefined) {
      buildingChanged = true;
    }

    if (changedValues.type !== undefined) {
      typeChanged = true;
    }

    if (buildingChanged || typeChanged) {
      const selectedBuildingId = allValues.building_id;
      const selectedType = allValues.type;

      if (selectedBuildingId) {
        // Filter parent meters based on selected building
        const buildingMeters = allMeters.filter(m => m.building.id === selectedBuildingId);
        setParentMeters(buildingMeters);

        // Determine allowed virtual meter types based on selected type
        const allowedVirtualTypes = getAllowedVirtualTypes(selectedType);

        // Filter virtual meters based on allowed types
        const virtualMeters = buildingMeters.filter(m => allowedVirtualTypes.includes(m.meter.type));
        setVirtualMeterOptions(virtualMeters);
      } else {
        setParentMeters([]);
        setVirtualMeterOptions([]);
      }

      // Clear fields based on changes
      if (buildingChanged) {
        form.setFieldsValue({
          parent_id: undefined,
          virtual_calc: "",
          pulse_rate: undefined,
          unit_type: undefined,
          conversion_rate: undefined,
        });
        builderRef.current?.setValue?.("");
      }

      if (typeChanged) {
        form.setFieldsValue({
          virtual_calc: "",
          pulse_rate: undefined,
          unit_type: undefined,
          conversion_rate: undefined,
        });
        builderRef.current?.setValue?.("");
      }
    }

    // Recalculate Conversion Rate when pulse_rate or unit_type changes
    if (changedValues.pulse_rate !== undefined || changedValues.unit_type !== undefined) {
      const pulseRate = allValues.pulse_rate;
      const unitType = allValues.unit_type;

      if (pulseRate && unitType && meterTypeConfig.unitTypes[unitType]) {
        const conversionRate = pulseRate * meterTypeConfig.unitTypes[unitType];
        allValues.conversion_rate = conversionRate; // Ensure its available from comparison
        form.setFieldsValue({ conversion_rate: conversionRate });
      } else {
        form.setFieldsValue({ conversion_rate: undefined });
      }
    }

    // Determine if form has been modified by comparing with initial values
    // Exclude helper fields from comparison
    const { pulse_rate, unit_type, ...currentValues } = allValues;
    const { ...initialValues } = initialFormValues;

    const changed = !isEqual(currentValues, initialValues);
    setIsDirty(changed);
  };

  /**
   * Handle form submission.
   */
  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Validate virtual calculation formula
      const isValid = builderRef.current?.validateTokens();
      if (!isValid) {
        // Not valid: Prevent form submission
        return;
      }

      let payload = {
        meter_id: values.meter_id || null,
        building_id: values.building_id,
        name: values.name,
        parent_id: values.parent_id || null,
        conversion_rate: parseFloat(values.conversion_rate),
        virtual_calc: values.virtual_calc,
        main: values.main,
        type: values.type,
        category: isSuperUser ? values.category : initialFormValues.category || null, // Keep existing or default if not super user,
        ext_ref: isSuperUser ? values.ext_ref : initialFormValues.ext_ref || null, // Keep existing or default if not super user,
      };

      if (isSuperUser) {
        // Super users can set ext_ref and category
        payload = {
          ...payload,
          ext_ref: values.ext_ref,
          category: values.category,
        };
      } else if (isEditMode && meter?.meter) {
        // Non-super users editing: preserve existing ext_ref and category
        payload = {
          ...payload,
          ext_ref: meter.meter.ext_ref,
          category: meter.meter.category,
        };
      }

      onSubmit(payload);
      form.resetFields();
      setParentMeters([]);
      setVirtualMeterOptions([]);
      setIsDirty(false);
    } catch (error) {
      console.error("MeterModal - Validation failed:", error);
    }
  };

  /**
   * Handle modal cancellation.
   */
  const handleCancel = () => {
    form.resetFields();
    setParentMeters([]);
    setVirtualMeterOptions([]);
    setIsDirty(false);
    onCancel();
  };

  // Current Meter Type Configuration
  const selectedMeterType = form.getFieldValue('type');
  const meterTypeConfig = getMeterTypeConfig(selectedMeterType);
  const currentBaseUnit = meterTypeConfig?.baseUnit || '';
  const currentUnitTypes = meterTypeConfig.unitTypes ? Object.keys(meterTypeConfig.unitTypes) : [];

  return (
    <Modal
      centered
      destroyOnClose
      open={open}
      title={isEditMode ? "Edit Meter" : "Add New Meter"}
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={!isDirty}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
      width={800}
    >
      <Form
        layout="vertical"
        form={form}
        onValuesChange={handleValuesChange}
      >
        <Row gutter={24}>
          {/* Left Column */}
          <Col span={12}>
            {/* Hidden Field: Meter ID (for editing) */}
            <Form.Item name="meter_id" hidden>
              <Input />
            </Form.Item>

            {/* Building Selection: Required before enabling other fields */}
            <Form.Item
              label="Building"
              name="building_id"
              rules={[{ required: true, message: "Please select a building" }]}
            >
              <Select
                showSearch
                placeholder="Select a building"
                options={buildings.map((b) => ({
                  label: b.name,
                  value: b.building_id,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>

            {/* Meter Type Selection */}
            <Form.Item
              label="Type"
              name="type"
              rules={[
                { required: true, message: "Please select a meter type" },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a meter type"
                options={Object.values(Constants.METER_TYPES).map(({ type, label }) => ({
                  label: label,
                  value: type,
                }))}
                disabled={!form.getFieldValue('building_id')}
              />
            </Form.Item>

            {/* Parent Meter Selection: Filtered by selected building */}
            <Form.Item
              label="Parent Meter"
              name="parent_id"
            >
              <Select
                showSearch
                allowClear
                placeholder="Select a parent meter"
                options={parentMeters.map((m) => ({
                  label: m.meter.name,
                  value: m.meter.id,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                disabled={!form.getFieldValue('building_id')}
              />
            </Form.Item>

            {/* Meter Category Input for Super Users */}
            {isSuperUser && (
              <Form.Item
                label="Category"
                name="category"
              >
                <Select
                  showSearch
                  placeholder="Select a meter category"
                  options={Constants.METER_CATEGORIES.map(({ type, label }) => ({
                    label: label,
                    value: type,
                  }))}
                  disabled={!form.getFieldValue('building_id')}
                />
              </Form.Item>
            )}
          </Col>

          {/* Right Column */}
          <Col span={12}>
            {/* Meter Name */}
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter a name" },
              ]}
            >
              <Input placeholder="E.g. Main Water Meter" disabled={!form.getFieldValue('building_id')} />
            </Form.Item>

            {/* Main Meter Checkbox */}
            <Form.Item label="Main" name="main" valuePropName="checked">
              <Checkbox disabled={!form.getFieldValue('building_id')}>Is this a main meter?</Checkbox>
            </Form.Item>

            {/* Conditionally Rendered External Reference Input for Super Users */}
            {isSuperUser && (
              <Form.Item
                label="Temetra Meter Reference"
                name="ext_ref"
              >
                <Input placeholder="Used for temetra meters" disabled={!form.getFieldValue('building_id')} />
              </Form.Item>
            )}
          </Col>

          <Divider />

          {/* Conversion Rate Configuration Section Full Width */}
          <Col span={24}>
            <Typography.Title level={5}>Conversion Rate Configuration</Typography.Title>
            <Row gutter={16}>
              {/* Pulse Rate Input */}
              <Col span={7}>
                <Form.Item
                  label="Pulse Rate"
                  name="pulse_rate"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="e.g., 0.001, 1"
                    disabled={!form.getFieldValue('type')}
                  />
                </Form.Item>
              </Col>

              {/* Unit Type Selection */}
              <Col span={7}>
                <Form.Item
                  label="Pulse Unit"
                  name="unit_type"
                >
                  <Select
                    showSearch
                    placeholder="Select a pulse unit"
                    options={currentUnitTypes.map(unit => ({
                      label: unit,
                      value: unit,
                    }))}
                    disabled={!form.getFieldValue('type')}
                  />
                </Form.Item>
              </Col>

              {/* Conversion Rate Input */}
              <Col span={10}>
                <Form.Item
                  label={`Conversion Rate (1 pulse = X ${currentBaseUnit})`}
                  name="conversion_rate"
                  rules={[
                    { required: true, message: "Please input pulse rate and unit type to generate" },
                    {
                      type: 'number',
                      min: 0,
                      message: "Conversion rate must be a positive number",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Generated from pulse rate and unit"
                    readOnly
                    disabled={!form.getFieldValue('type')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />

        {/* Virtual Calculation Builder Wrapped in Form.Item */}
        <Row>
          <Col span={24}>
            <Typography.Title level={5}>Virtual Calculation</Typography.Title>
            <Form.Item
              name="virtual_calc"
              noStyle
            >
              <VirtualCalculationBuilder
                ref={builderRef}
                meterOptions={virtualMeterOptions}
                initValue={meter?.meter?.virtual_calc || ""}
                value={meter?.meter?.virtual_calc || ""}
                onChange={(val) => {
                  form.setFieldsValue({ virtual_calc: val });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default MeterModal;
