import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from 'moment';
import Timeframe from "../../../utils/Timeframe";

import "./Meter.scss";
import { DocumentTitle } from "../../../components/DocumentTitle";
import { Icon } from "../../../components/Icon";
import { Chart } from "../../../components/Chart";
import { LogoSpinner } from "../../../components/LogoSpinner";
import GeneralStore from "../../../stores/generalStore";
import GeneralActions from "../../../actions/generalActions";
import MeterDetailsStore from "../../../stores/meterDetailsStore";
import MeterDetailsActions from "../../../actions/meterDetailsActions";
import GeneralUtils from "../../../utils/GeneralUtils";
import Constants from "../../../constants";

function Meter() {
  const { id: meterId } = useParams();
  const history = useHistory();

  const initialStart = GeneralStore.getStartDate();
  const initialEnd = GeneralStore.getEndDate();
  const initialTimeframe = new Timeframe(initialStart, initialEnd);

  const daysDifference = GeneralUtils.getDifferenceBetweenDates(
    initialStart,
    initialEnd
  );

  let initialBucket = Constants.BUCKET_FIFTEEN_MIN;
  if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
    initialBucket = Constants.BUCKET_DAY;
  } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
    initialBucket = Constants.BUCKET_HOUR;
  }

  const [state, setState] = useState({
    entity: {},
    consumptionData: [],
    consumptionUnit: "",
    kwData: [],
    kvarhData: [],
    timeframe: initialTimeframe,
    bucket: initialBucket,
    loading: true,
  });

  const fetchConsumptionData = useCallback(() => {
    if (meterId && state.timeframe && state.bucket) {
      setState((prevState) => ({ ...prevState, loading: true }));
      MeterDetailsActions.getMeterConsumption(
        meterId,
        state.timeframe,
        state.bucket
      );
    }
  }, [meterId, state.bucket, state.timeframe]);

  const onDateChange = () => {
    const newTimeframe = new Timeframe(GeneralStore.getStartDate(), GeneralStore.getEndDate());
    const daysDifference = newTimeframe.getDaysDifference();

    setState((prevState) => {
      let newBucket = prevState.bucket;
      if (daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET) {
        newBucket = Constants.BUCKET_DAY;
      } else if (daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) {
        newBucket = Constants.BUCKET_HOUR;
      }

      return {
        ...prevState,
        timeframe: newTimeframe,
        bucket: newBucket,
      };
    });
  };

  const onConsumptionFetch = () => {
    setState((prevState) => ({
      ...prevState,
      entity: MeterDetailsStore.getEntity(),
      consumptionData: MeterDetailsStore.getConsumptionData(),
      consumptionUnit: MeterDetailsStore.getConsumptionUnit(),
      kwData: MeterDetailsStore.getKwData(),
      kvarhData: MeterDetailsStore.getKvarhData(),
      loading: false,
    }));
  };

  useEffect(() => {
    MeterDetailsStore.addMeterDetailsFetchListener(onConsumptionFetch);
    GeneralStore.addChangeListener(onDateChange);

    return () => {
      MeterDetailsStore.removeMeterDetailsFetchListener(onConsumptionFetch);
      GeneralStore.removeChangeListener(onDateChange);
    };
  }, []);

  useEffect(() => {
    fetchConsumptionData();
  }, [fetchConsumptionData, state.bucket, state.timeframe]);

  const setBucketSize = (bucket) => {
    setState((prevState) => ({ ...prevState, bucket }));
  };

  const isBucketDisabled = (bucket) => {
    const daysDifference = GeneralUtils.getDifferenceBetweenDates(
      state.timeframe.start.unix(),
      state.timeframe.end.unix()
    );

    return (
      (bucket === Constants.BUCKET_FIFTEEN_MIN &&
        daysDifference > Constants.MAX_DAYS_FOR_HOUR_BUCKET) ||
      (bucket === Constants.BUCKET_HOUR &&
        daysDifference > Constants.MAX_DAYS_FOR_DAY_BUCKET)
    );
  };

  const getSection = (title) => {
    const { consumptionData, consumptionUnit, kwData, kvarhData, bucket } = state;
    const dataMapping = {
      Consumption: {
        data: consumptionData,
        unit: consumptionUnit,
        tooltipDecimals: 0,
      },
      Kilowatts: { data: kwData, unit: " kW", tooltipDecimals: 0 },
      "Kilovolt-ampere hour": {
        data: kvarhData,
        unit: " kVarh",
        tooltipDecimals: 0,
      },
    };

    const { data, unit, tooltipDecimals } = dataMapping[title] || {};

    if (!data || data.length === 0) return null;

    const buttons = [
      {
        label: "15 min",
        activeCondition: bucket === Constants.BUCKET_FIFTEEN_MIN,
        disabledCondition: isBucketDisabled(Constants.BUCKET_FIFTEEN_MIN),
        handler: () => setBucketSize(Constants.BUCKET_FIFTEEN_MIN),
      },
      {
        label: "Hour",
        activeCondition: bucket === Constants.BUCKET_HOUR,
        disabledCondition: isBucketDisabled(Constants.BUCKET_HOUR),
        handler: () => setBucketSize(Constants.BUCKET_HOUR),
      },
      {
        label: "Day",
        activeCondition: bucket === Constants.BUCKET_DAY,
        disabledCondition: false,
        handler: () => setBucketSize(Constants.BUCKET_DAY),
      },
    ];

    return (
      <div className="col-12 mg-t-30">
        <Chart
          title={title}
          data={data}
          tooltipDecimals={tooltipDecimals}
          unit={unit}
          buttons={buttons}
        />
      </div>
    );
  };

  const getNoDataMessage = () => {
    const { consumptionData, kwData, kvarhData, loading } = state;
    const series = [...consumptionData, ...kwData, ...kvarhData];

    if (!series.length && !loading) {
      return (
        <div className="col-12 mg-t-30">
          <Chart noData />
        </div>
      );
    }
    return null;
  };

  const getPageTitle = () => {
    return state.entity ? state.entity.name : "No Data";
  };

  const getTitle = () => {
    const lastLocation = sessionStorage.getItem("meterLastLoc");
    const defaultLocation = "/meters";
    const backText = state.entity ? state.entity.name : "Back";

    return (
      <div
        className="title tx-18 tx-archivo-semibold shadow-base"
        onClick={() => history.push(lastLocation || defaultLocation)}
      >
        <span className="icon-wrapper">
          <Icon name='ArrowLeft' size={40} color='#000' />
        </span>
        {backText}
      </div>
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const fromDate = searchParams.get('from');
    const toDate = searchParams.get('to');

    if (fromDate && toDate) {
      const fromTimestamp = moment(fromDate).unix();
      const toTimestamp = moment(toDate).subtract(1, 'seconds').unix();
      const urlTimeframe = new Timeframe(fromTimestamp, toTimestamp);
      GeneralActions.setDates(urlTimeframe.start.unix(), urlTimeframe.end.unix());
    }
  }, [history.location.search]);

  return (
    <div
      id="Meter"
      className="br-mainpanel br-profile-page floorplan-background"
      style={{ overflowY: "scroll" }}
    >
      <DocumentTitle title={getPageTitle()} />
      <LogoSpinner loading={state.loading} />
      <div className="br-container">
        <div className="header row">
          <div className="col mg-t-30">{getTitle()}</div>
        </div>
        <div className="charts row">
          {getNoDataMessage()}
          {getSection("Consumption")}
          {getSection("Kilowatts")}
          {getSection("Kilovolt-ampere hour")}
        </div>
      </div>
    </div>
  );
}

export default Meter;
