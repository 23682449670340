import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import Timeframe from "../utils/Timeframe";

const CONSUMPTION_ANALYSIS_LIST_FETCHED = Constants.CONSUMPTION_ANALYSIS_LIST_FETCHED;
const CONSUMPTION_ANALYSIS_TIMEFRAME_SET = Constants.CONSUMPTION_ANALYSIS_TIMEFRAME_SET;

let _consumptionAnalysisList = [];
let _timeframe = {
    timeframe: Timeframe.lastWeek(),
    granularity: 'week'
}

class ConsumptionAnalysisStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case CONSUMPTION_ANALYSIS_LIST_FETCHED:
        this._storeData(action.payload);
        break;
      case CONSUMPTION_ANALYSIS_TIMEFRAME_SET:
        this._storeTimeframe(action.payload);
        break;
      default:
        break;
    }
  }

  clear() {
    _consumptionAnalysisList = [];
    _timeframe = {
        timeframe: Timeframe.lastWeek(),
        granularity: 'week'
    }
  }

  _storeData(json) {
    _consumptionAnalysisList = json;
    this.emit(CONSUMPTION_ANALYSIS_LIST_FETCHED);
  }

  _storeTimeframe(timeframeObject) {
    _timeframe = timeframeObject;
    this.emit(CONSUMPTION_ANALYSIS_TIMEFRAME_SET);
  }

  getTimeframe() {
    return _timeframe;
  }

  getConsumptionAnalysisList() {
    return _consumptionAnalysisList;
  }
}

const consumptionAnalysisStore = new ConsumptionAnalysisStore();
export default consumptionAnalysisStore;
