// VirtualCalculationBuilder.jsx
import React, { forwardRef, useImperativeHandle, useState, useCallback } from "react";
import { Select, Button, Space, Typography } from "antd";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

/**
 * VirtualCalculationBuilder Component
 *
 * A controlled component that allows users to build virtual meter calculations using meters and operators.
 *
 * Props:
 * - meterOptions: Array of available meters to include in the calculation.
 * - value: The current formula string.
 * - onChange: Callback to update the formula string in the parent component.
 * - initValue: The initial formula string to reset to.
 */
const VirtualCalculationBuilder = forwardRef(({ meterOptions, initValue, value, onChange }, ref) => {
  const [errorMessage, setErrorMessage] = useState(null);

  // Centralized error clearing
  const handleChange = useCallback((newValue) => {
    setErrorMessage(null);
    onChange(newValue);
  }, [onChange]);

  // Helper to parse the formula string into tokens
  const parseFormula = (formula) => {
    if (!formula) return [];
  
    // Regex to match numbers, operators, and parentheses
    const tokenRegex = /(\d+|\+|-|\*|\/|\(|\))/g;
    const rawTokens = formula.match(tokenRegex) || [];
  
    return rawTokens.map((token) => {
      if (["+", "-", "*", "/"].includes(token)) {
        return { type: "operator", value: token };
      }
      if (token === "(" || token === ")") {
        return { type: "paren", value: token };
      }
      // Assume any other token is a meter ID
      const meter = meterOptions.find((m) => String(m.meter.id) === String(token));
      return {
        type: "meter",
        id: token,
        label: meter ? meter.meter.name : `Unknown Meter ${token}`,
      };
    });
  };

  // Convert tokens back to formula string
  const buildFormula = (tokens) => {
    return tokens.map((token) => (token.type === "meter" ? token.id : token.value)).join("");
  };

  // Expose a validation method to the parent component via ref
  useImperativeHandle(ref, () => ({
    /**
     * Validates the current formula.
     * @returns {boolean} - True if valid, false otherwise.
     */
    validateTokens: () => {
      if (!value) {
        setErrorMessage(null);
        return true;
      }

      const tokens = parseFormula(value);

      // Validation rules
      let balance = 0;
      for (const token of tokens) {
        if (token.value === "(") balance++;
        if (token.value === ")") balance--;
        if (balance < 0) {
          setErrorMessage("A closing parenthesis ')' has no matching '('.");
          return false;
        }
      }
      if (balance > 0) {
        setErrorMessage("An opening parenthesis '(' is not closed.");
        return false;
      }

      const firstToken = tokens[0];
      const lastToken = tokens[tokens.length - 1];

      if (firstToken.type === "operator" && firstToken.value !== "(") {
        setErrorMessage("Cannot start the formula with an operator.");
        return false;
      }
      if (lastToken.type === "operator" && lastToken.value !== ")") {
        setErrorMessage("Cannot end the formula with an operator.");
        return false;
      }

      for (let i = 0; i < tokens.length - 1; i++) {
        const current = tokens[i];
        const next = tokens[i + 1];

        // Two consecutive meters require an operator
        if (current.type === "meter" && next.type === "meter") {
          setErrorMessage("You must have an operator between meters.");
          return false;
        }

        // Two consecutive operators are invalid unless it's ')('
        if (
          current.type === "operator" &&
          next.type === "operator" &&
          !(current.value === ")" && next.value === "(")
        ) {
          setErrorMessage("Cannot have consecutive operators.");
          return false;
        }

        // Meter followed by '(' requires an operator
        if (current.type === "meter" && next.type === "paren" && next.value === "(") {
          setErrorMessage("You must have an operator before an opening parenthesis.");
          return false;
        }
      }

      // If all validations pass
      setErrorMessage(null);
      return true;
    },
  }));

  // Handle adding a meter
  const handleAddMeter = (meterId) => {
    if (!meterId) return;
    const newFormula = value ? `${value} ${meterId}` : meterId;
    handleChange(buildFormula(parseFormula(newFormula)));
  };

  // Handle adding an operator
  const handleAddOperator = (operator) => {
    if (!operator) return;
    const newFormula = value ? `${value} ${operator}` : operator;
    handleChange(buildFormula(parseFormula(newFormula)));
  };

  // Handle removing the last token
  const handleRemoveLast = () => {
    if (!value) return;
    const tokens = parseFormula(value);
    tokens.pop();
    const newFormula = buildFormula(tokens);
    handleChange(newFormula);
  };

  // Handle resetting to the initial value
  const handleReset = () => {
    handleChange(initValue || "");
  };

  // Handle clearing the entire formula
  const handleClearAll = () => {
    handleChange("");
  };

  // Parse the current formula into tokens
  const tokens = parseFormula(value);

  // Determine if the current value differs from the initial value
  const isModified = !isEqual(initValue, value);

  // Are meters available
  const isMeterOptionsAvailable = meterOptions.length > 0;

  return (
    <div style={{ height: "100%", boxSizing: "border-box" }}>
      {/* Meter Selection and Operator Buttons */}
      <div style={{ marginBottom: 8 }}>
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Add Meter"
          value={null}
          onSelect={handleAddMeter}
          options={meterOptions.map((m) => ({
            label: m.meter.name,
            value: String(m.meter.id),
          }))}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          disabled={!isMeterOptionsAvailable}
        />

        {/* Operator Buttons */}
        <Space style={{ marginLeft: 8 }}>
          <Button onClick={() => handleAddOperator("(")} disabled={!isMeterOptionsAvailable}>
            (
          </Button>
          <Button onClick={() => handleAddOperator(")")} disabled={!isMeterOptionsAvailable}>
            )
          </Button>
          {["+", "-", "*", "/"].map((op) => (
            <Button
              key={op}
              onClick={() => handleAddOperator(op)}
              disabled={!isMeterOptionsAvailable}
            >
              {op}
            </Button>
          ))}
        </Space>
      </div>

      {/* Display Tokens */}
      <div
        style={{
          minHeight: "50px",
          overflowY: "auto",
          border: "1px solid #d9d9d9",
          borderRadius: "6px",
          marginBottom: "8px",
          padding: "8px",
        }}
      >
        <Space wrap>
          {tokens.map((token, idx) => {
            const key = `${token.type}-${idx}`;
            let bgColor = "#ffdca8"; // Default color for operators
            if (token.type === "meter") bgColor = "#daf7dc"; // Green for meters
            if (token.type === "paren") bgColor = "#d0d0ff"; // Blue for parentheses

            return (
              <span
                key={key}
                style={{
                  padding: "2px 6px",
                  background: bgColor,
                  borderRadius: 4,
                }}
              >
                {token.label || token.value}
              </span>
            );
          })}
        </Space>
      </div>

      {/* Control Buttons */}
      <Space>
        <Button size="small" onClick={handleRemoveLast} disabled={!isMeterOptionsAvailable}>
          Remove Last
        </Button>
        <Button size="small" onClick={handleReset} disabled={!isMeterOptionsAvailable || !isModified}>
          Reset
        </Button>
        <Button size="small" onClick={handleClearAll} disabled={!isMeterOptionsAvailable} danger>
          Clear All
        </Button>
      </Space>

      {/* Display Validation Error */}
      {errorMessage && (
        <Typography.Text type="danger" style={{ marginTop: '8px', display: 'block' }}>
          {errorMessage}
        </Typography.Text>
      )}
    </div>
  );
});

VirtualCalculationBuilder.propTypes = {
  meterOptions: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initValue: PropTypes.string,
};

export default VirtualCalculationBuilder;
