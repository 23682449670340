import React, { Component } from "react";
import "./ManageDoorways.scss";

import ZonesActions from "../../actions/zonesActions";
import ZonesStore from "../../stores/zonesStore";
import DoorwayActions from "../../actions/doorwayActions";
import DoorwayStore from "../../stores/doorwayStore";
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import UserStore from "../../stores/userStore";

import GeneralUtils from "../../utils/GeneralUtils";
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { ModifyDoorwayPanel } from "./ModifyDoorwayPanel";
import _ from "lodash";

import { toast } from "react-toastify";


class ManageDoorways extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: ZonesStore.getZones(),
      buildings: OrganisationStore.getBuildings(),
      doorway_creation: false,
      selected_building: null,
      selected_zone: null,
      items_expanded: [],
      doorways: [],
      new_doorway: {
        name: "",
        description: "",
        building_id: 0,
        links: [],
      },
    };
    // listener events
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._onZonesChange = this._onZonesChange.bind(this);
    this._onDoorwayChange = this._onDoorwayChange.bind(this);
    this._onDoorwayOperation = this._onDoorwayOperation.bind(this);

    // // filter setters
    this._setTargetBuilding = this._setTargetBuilding.bind(this);
    this._setTargetZone = this._setTargetZone.bind(this);

    // //helpers and controllers
    this._changeDoorwayValues = this._changeDoorwayValues.bind(this);
    this._changeDoorwayZones = this._changeDoorwayZones.bind(this);
    this._validateDoorwayInput = this._validateDoorwayInput.bind(this);
    this._toggleDoorwayCreation = this._toggleDoorwayCreation.bind(this);
    this._resetFiltersHandler = this._resetFiltersHandler.bind(this);

    // // Operation actions
    this._saveDoorway = this._saveDoorway.bind(this);
    this._updateDoorway = this._updateDoorway.bind(this);
    this._deleteDoorway = this._deleteDoorway.bind(this);
  }
  UNSAFE_componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    ZonesStore.addChangeListener(this._onZonesChange);
    DoorwayStore.addChangeListener(this._onDoorwayChange);
    DoorwayStore.addDoorwayOperationListener(this._onDoorwayOperation);
    DoorwayStore.addLinkOperationListener(this._onDoorwayOperation);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
    });
  }
  _onZonesChange() {
    this.setState({
      zones: ZonesStore.getZones(),
    });
  }

  _onDoorwayChange() {
    this.setState({ doorways: DoorwayStore.getDoorways() });
  }

  _onDoorwayOperation() {
    this._toggleDoorwayCreation(false);
    DoorwayActions.getBuildingDoorways(
      this.state.selected_building && this.state.selected_building.building_id
    );
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    ZonesStore.removeChangeListener(this._onZonesChange);
    DoorwayStore.removeChangeListener(this._onDoorwayChange);
    DoorwayStore.removeDoorwayOperationListener(this._onDoorwayOperation);
    DoorwayStore.removeLinkOperationListener(this._onDoorwayOperation);

    ZonesStore.clear();
    DoorwayStore.clear();
  }

  _setTargetBuilding(selected_building) {
    ZonesActions.getZones(selected_building.building_id);
    DoorwayActions.getBuildingDoorways(selected_building.building_id);

    this.setState({
      selected_building: selected_building,
      new_doorway: {
        ...this.state.new_doorway,
        building_id: selected_building.building_id,
      },
    });
  }

  _setTargetZone(selected_zone) {
    this.setState({
      selected_zone: selected_zone,
    });
  }

  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return (
      <tr>
        <td className="valign-middle" colSpan={5}>
          <div className="d-flex align-items-center">
            <div>
              <div className="tx-inverse">
                {this.state.selected_building == null
                  ? "No doorways available, please select a target building."
                  : "No doorways available. Please contact support to add new doorways."}
              </div>
            </div>
          </div>
        </td>
        {rows}
      </tr>
    );
  }
  _validateDoorwayInput(doorway) {
    let validBuilding = parseInt(doorway.building_id) > 0;
    let validName = GeneralUtils.stringNotEmpty(doorway.name);
    let validDescription = GeneralUtils.stringNotEmpty(doorway.description);
    return validBuilding && validName && validDescription;
  }

  _changeDoorwayValues(doorway, target_property, event) {
    let doorways = this.state.doorways;
    let new_doorway = this.state.new_doorway;
    let value = event.target.value;

    let editExisting = false;
    for (let i = 0; i < doorways.length; i++) {
      if (doorways[i].doorway_id === doorway.doorway_id) {
        doorways[i][target_property] = value;
        editExisting = true;
        break;
      }
    }
    if (!editExisting) {
      new_doorway[target_property] = value;
    }

    this.setState({ new_doorway: new_doorway, doorways: doorways });
  }

  _changeDoorwayZones(doorway, property, values) {
    let doorways = this.state.doorways;
    let new_doorway = this.state.new_doorway;

    for (let i = 0; i < doorways.length; i++) {
      if (doorways[i].doorway_id === doorway.doorway_id) {
        if (property === "interior_zone") {
          const exteriorLinks = doorways[i].links.filter(
            (link) => link.sensor_placement === -1
          );
          const interiorLinks = values.map((zone_id) => {
            return {
              zone_id: zone_id,
              sensor_placement: 1,
              doorway_id: doorway.doorway_id,
            };
          });
          doorways[i].links = [...exteriorLinks, ...interiorLinks];
        } else if (property === "exterior_zone") {
          const interiorLinks = doorways[i].links.filter(
            (link) => link.sensor_placement === 1
          );
          const exteriorLinks = values.map((zone_id) => {
            return {
              zone_id: zone_id,
              sensor_placement: -1,
              doorway_id: doorway.doorway_id,
            };
          });
          doorways[i].links = [...exteriorLinks, ...interiorLinks];
        }
      } else if (doorway.doorway_id === undefined) {
        if (property === "interior_zone") {
          const exteriorLinks = new_doorway.links.filter(
            (link) => link.sensor_placement === -1
          );
          const interiorLinks = values.map((zone_id) => {
            return {
              zone_id: zone_id,
              sensor_placement: 1,
            };
          });
          new_doorway.links = [...exteriorLinks, ...interiorLinks];
        } else if (property === "exterior_zone") {
          const interiorLinks = new_doorway.links.filter(
            (link) => link.sensor_placement === 1
          );
          const exteriorLinks = values.map((zone_id) => {
            return {
              zone_id: zone_id,
              sensor_placement: -1,
            };
          });
          new_doorway.links = [...exteriorLinks, ...interiorLinks];
        }
      }
    }

    this.setState({ doorways: doorways, new_doorway: new_doorway });
  }

  _saveDoorway(doorway) {
    if (this._validateDoorwayInput(doorway)) {
      const doorwayObject = {
        building_id: doorway.building_id,
        name: doorway.name,
        description: doorway.description,
      };

      DoorwayActions.saveDoorway(doorwayObject, doorway.links);
    } else {
      toast.warn("Invalid Input, Please check fields and try again")
    }
  }

  _updateDoorway(doorway) {
    if (this._validateDoorwayInput(doorway) && doorway.doorway_id) {
      const doorwayObject = {
        doorway_id: doorway.doorway_id,
        name: doorway.name,
        description: doorway.description,
      };

      DoorwayActions.updateDoorway(doorwayObject, doorway.links);
    } else {
      toast.warn("Invalid Input, Please check fields and try again")
    }
  }

  _deleteDoorway(doorway_id) {
    if (
      window.confirm(
        "Are you sure you wish to delete this doorway? All associated links will be destroyed"
      )
    ) {
      DoorwayActions.deleteDoorway(doorway_id);
    }
  }

  _toggleDoorwayCreation(target_state = null) {
    if (this.state.doorway_creation || target_state === false) {
      this.setState({
        doorway_creation: false,
        new_doorway: {
          name: "",
          description: "",
          building_id: this.state.selected_building.building_id,
          links: [],
        },
      });
    } else {
      this.setState({ doorway_creation: true });
    }
  }

  _resetFiltersHandler() {
    this._toggleDoorwayCreation(false);
    this.setState({ selected_zone: null });
  }

  getDoorwayTable() {
    let doorwayRows = this.noRowsAvailable(0);
    let newDoorwayRow = null;
    let resetFiltersButton = (
      <button
        className="btn btn-outline-warning pd-t-2 pd-b-2 tx-12 mg-l-15 ht-35 tx-14 mg-y-5"
        onClick={this._resetFiltersHandler}
      >
        Reset Filters
      </button>
    );
    let buildingsFormattedArray = this.state.buildings.map((building) => {
      return {
        ...building,
        name: building.name + ` (building id: ${building.building_id})`,
      };
    });
    let zonesFormattedArray = this.state.zones.map((zone) => {
      return { ...zone, name: zone.name + ` (zone id: ${zone.zone_id})` };
    });

    let doorways = _.orderBy(this.state.doorways, "name", "asc");
    let button = (
      <button
        onClick={this._toggleDoorwayCreation}
        style={{ cursor: "pointer" }}
        className="btn btn-outline-info pd-t-2 pd-b-2 tx-12 mg-l-15 ht-35 tx-14 mg-y-5"
      >
        Add Doorway
      </button>
    );
    if (this.state.doorway_creation) {
      button = (
        <button
          onClick={this._toggleDoorwayCreation}
          style={{ cursor: "pointer" }}
          className="btn btn-outline-warning pd-t-2 pd-b-2 tx-12 mg-l-15 ht-35 tx-14 mg-y-5"
        >
          Cancel
        </button>
      );
    }

    if (this.state.doorway_creation) {
      newDoorwayRow = (
        <ModifyDoorwayPanel
          key={"new_doorway"}
          zones={this.state.zones}
          building={this.state.selected_building}
          changeDoorwayValues={this._changeDoorwayValues}
          changeDoorwayZones={this._changeDoorwayZones}
          expanded={true}
          doorway={this.state.new_doorway}
          updateZone={this._updateDoorway}
          saveDoorway={this._saveDoorway}
          cancelDoorway={this._toggleDoorwayCreation}
        />
      );
    }

    if (doorways.length > 0) {
      doorwayRows = doorways.reverse().map((doorway, key) => {
        const doorwayZones = doorway.links.map((link) => link.zone_id);
        const zoneFilter =
          this.state.selected_zone &&
            !doorwayZones.includes(this.state.selected_zone.zone_id)
            ? false
            : true;

        if (doorway.doorway_id && zoneFilter) {
          return (
            <ModifyDoorwayPanel
              key={doorway.doorway_id}
              changeDoorwayValues={this._changeDoorwayValues}
              changeDoorwayZones={this._changeDoorwayZones}
              deleteDoorway={this._deleteDoorway}
              doorway={doorway}
              zones={this.state.zones}
              doorway_types={this.state.doorway_types}
              expanded={this.state.items_expanded.includes(doorway.doorway_id)}
              expansionHandler={this._expansionHandler}
              building={this.state.selected_building}
              updateDoorway={this._updateDoorway}
              saveDoorway={this._saveDoorway}
            />
          );
        } else {
          return null;
        }
      });
    }

    return (
      <div>
        <div
          className="br-pagetitle pd-x-0 pd-b-0 mg-b-10 flex-wrap"
          style={{ width: "100%" }}
        >
          <span className="col-12 col-md-7 d-flex pd-0 flex-wrap">
            <span className="col-6 pd-l-0">
              <SearchSelect
                limit={100}
                options={buildingsFormattedArray}
                placeholder={"Select Building"}
                defaultValue={this.state.selected_building}
                actionOnSelectedOption={this._setTargetBuilding}
              />
            </span>
            <span className="col-6 pd-r-0">
              <SearchSelect
                limit={100}
                options={zonesFormattedArray}
                placeholder={"Select Zone"}
                defaultValue={this.state.selected_zone}
                actionOnSelectedOption={this._setTargetZone}
              />
            </span>
          </span>
          <div className="col-12 col-md-5 mg-l-auto pd-0 tx-right flex-wrap">
            {this.state.selected_zone && resetFiltersButton}
            {this.state.selected_building && button}
          </div>
        </div>
        <div className="card bd-0 shadow-base mg-t-5">
          <table className="table mg-b-0 table-contact">
            <thead>
              <tr>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Name<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Description<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Interior Zone
                </th>
                <th className="wd-20p tx-10-force tx-mont tx-medium">
                  Exterior Zone
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium"></th>
              </tr>
            </thead>
            <tbody>
              {newDoorwayRow}
              {doorwayRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="br-mainpanel">
        <DocumentTitle title="Manage Doorways" />
        <div className="br-pagetitle mg-b-0">
          <div>
            <h4>Update Doorway Details</h4>
            <p className="mg-b-0">
              Update and create new doorways. Select the building to continue.
            </p>
          </div>
        </div>
        <div>
          <div className="col-12">{this.getDoorwayTable()}</div>
        </div>
      </div>
    );
  }
}

export default ManageDoorways;
