import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./DocumentTitle.scss";
import PropTypes from "prop-types";

const DocumentTitle = (props) => {
  const { title, notifications } = props;
  const location = useLocation();

  useEffect(() => {
    if (typeof title === "string") {
      const notification = notifications ? `(${notifications}) ` : "";
      const suffix = " | OPNBuildings";
      document.title = notification + title + suffix;
    } else {
      const pathTitle = location.pathname
        .split("/")
        .filter(Boolean)
        .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1))
        .join(" ") || "Home";
      document.title = `${pathTitle} | OPNBuildings`;
    }
  }, [notifications, title, location.pathname]);

  return null;
};

export default DocumentTitle;

DocumentTitle.propTypes = {
  title: PropTypes.string,
  notifications: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
