// src/pages/ManageZones/ZoneModal.jsx

import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";
import Constants from "../../../constants";
import isEqual from "lodash/isEqual";

const { Title } = Typography;

/**
 * ZoneModal Component
 *
 * Handles both adding and editing zones, aligning with the V2ZoneRequestDTO structure.
 */
const ZoneModal = ({
  isSuperUser,
  open,
  onCancel,
  onSubmit,
  zone = null,
  buildings = [],
  allZones = [],
}) => {
  const [form] = Form.useForm();
  const [parentZones, setParentZones] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isDirty, setIsDirty] = useState(false);

  // Determine if the modal is in edit mode based on the presence of zone ID
  const isEditMode = !!zone?.zone?.id;

  /**
   * Populate form fields when the modal becomes open.
   */
  useEffect(() => {
    if (open) {
      if (isEditMode) {
        // Set initial form values for editing
        const initialValues = {
          zone_id: zone.zone.id,
          building_id: zone.building.id,
          name: zone.zone.name,
          description: zone.zone.description,
          location: zone.zone.location,
          type: zone.zone.type,
          size: zone.zone.size,
          temp_target_lower: zone.zone.temp_target_lower,
          temp_target_upper: zone.zone.temp_target_upper,
          co2_target_lower: zone.zone.co2_target_lower,
          co2_target_upper: zone.zone.co2_target_upper,
          humd_target_lower: zone.zone.humd_target_lower,
          humd_target_upper: zone.zone.humd_target_upper,
          lux_target_lower: zone.zone.lux_target_lower,
          lux_target_upper: zone.zone.lux_target_upper,
          pm25_target_lower: zone.zone.pm25_target_lower,
          pm25_target_upper: zone.zone.pm25_target_upper,
          parent_id: zone.zone.parent_id,
        };

        form.setFieldsValue({ ...initialValues });

        // Filter parent zones based on the selected building and exclude current zone
        const buildingId = zone.building.id;
        const buildingZones = allZones.filter((z) => z.building.id === buildingId);
        setParentZones(buildingZones);

        setInitialFormValues(initialValues);
        setIsDirty(false);
      } else {
        // Reset form for adding a new zone
        form.resetFields();
        setParentZones([]);
      }
      setIsDirty(false);
    } else {
      // Reset form and states when modal is closed
      form.resetFields();
      setParentZones([]);
      setIsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, isEditMode, zone, allZones]);

  /**
     * Handle changes in form values.
     */
  const handleValuesChange = (changedValues, allValues) => {
    let buildingChanged = false;

    if (changedValues.building_id !== undefined) {
      buildingChanged = true;
    }

    if (buildingChanged) {
      const selectedBuildingId = allValues.building_id;

      if (selectedBuildingId) {
        // Filter parent zones based on selected building
        const buildingZones = allZones.filter(z => z.building.id === selectedBuildingId);
        setParentZones(buildingZones);

        form.setFieldsValue({ parent_id: undefined })
      } else {
        setParentZones([]);
      }
    }

    // Determine if form has been modified by comparing with initial values
    // Exclude helper fields from comparison
    const { ...currentValues } = allValues;
    const { ...initialValues } = initialFormValues;

    console.log("currentValues", currentValues);
    console.log("initialValues", initialValues);

    const changed = !isEqual(currentValues, initialValues);
    setIsDirty(changed);
  };

  /**
   * Handle form submission.
   */
  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const payload = {
        zone_id: values.zone_id,
        building_id: values.building_id,
        name: values.name,
        description: values.description,
        location: values.location || values.name, // Defaults to zone name
        type: isSuperUser ? values.type : initialFormValues.type || Constants.ZONE_TYPES.SPACE.type, // Keep existing or default if not super user
        size: isSuperUser ? values.size : initialFormValues.size || 0, // Keep existing or default if not super user
        temp_target_upper: values.temp_target_upper,
        temp_target_lower: values.temp_target_lower,
        co2_target_upper: values.co2_target_upper,
        co2_target_lower: values.co2_target_lower,
        humd_target_upper: values.humd_target_upper,
        humd_target_lower: values.humd_target_lower,
        lux_target_upper: values.lux_target_upper,
        lux_target_lower: values.lux_target_lower,
        pm25_target_upper: values.pm25_target_upper,
        pm25_target_lower: values.pm25_target_lower,
        parent_id: isSuperUser ? values.parent_id : initialFormValues.parent_id || null, // Keep existing or default if not super user,
      };

      console.log(payload)
      onSubmit(payload);
      form.resetFields();
      setIsDirty(false);
    } catch (error) {
      console.error("ZoneModal - Validation failed:", error);
    }
  };

  /**
   * Handle modal cancellation.
   */
  const handleCancel = () => {
    form.resetFields();
    setParentZones([]);
    setIsDirty(false);
    onCancel();
  };

  return (
    <Modal
      centered
      destroyOnClose
      width={800}
      open={open}
      title={isEditMode ? "Edit Zone" : "Add New Zone"}
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          disabled={!isDirty}
        >
          {isEditMode ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="zoneForm"
        onValuesChange={handleValuesChange}
      >
        {/* Hidden Field: Zone ID (for editing) */}
        {isEditMode && (
          <Form.Item name="zone_id" hidden>
            <Input />
          </Form.Item>
        )}

        <Row gutter={16}>
          {/* Building Selection */}
          <Col xs={24} sm={12}>
            <Form.Item
              name="building_id"
              label="Building"
              rules={[{ required: true, message: "Please select a building." }]}
            >
              <Select
                showSearch
                placeholder="Select a building"
                options={buildings.map((b) => ({
                  label: b.name,
                  value: b.building_id,
                }))}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>

          {/* Zone Name */}
          <Col xs={24} sm={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: "Please enter the zone name." },
                { max: 32, message: "Name cannot exceed 32 characters." },
              ]}
            >
              <Input placeholder="Enter zone name" disabled={!form.getFieldValue("building_id")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* Description */}
          <Col xs={24} sm={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please enter the description." },
                { max: 512, message: "Description cannot exceed 512 characters." },
              ]}
            >
              <Input.TextArea rows={1} placeholder="Enter description" disabled={!form.getFieldValue("building_id")} />
            </Form.Item>
          </Col>

          {/* Location */}
          <Col xs={24} sm={12}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                { max: 32, message: "Location cannot exceed 32 characters." },
              ]}
            >
              <Input placeholder="Defaults to zone name if not provided" disabled={!form.getFieldValue("building_id")} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* Type (Visible Only to Super Users) */}
          {isSuperUser && (
            <Col xs={24} sm={12}>
              <Form.Item
                name="type"
                label="Type"
                initialValue={Constants.ZONE_TYPES.SPACE.type}
                rules={[{ required: true, message: "Please select the type." }]}
              >
                <Select
                  showSearch
                  placeholder="Select type"
                  options={Object.values(Constants.ZONE_TYPES).map(({ type, label }) => ({
                    label: label,
                    value: type,
                  }))}
                  disabled={!form.getFieldValue('building_id')}
                />
              </Form.Item>
            </Col>
          )}

          {/* Parent Zone Selection (Visible Only to Super Users) */}
          {isSuperUser && (
            <Col xs={24} sm={12}>
              <Form.Item name="parent_id" label="Parent Zone">
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a parent zone"
                  options={parentZones.map((m) => ({
                    label: m.zone.name,
                    value: m.zone.id,
                  }))}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  disabled={!form.getFieldValue("building_id")}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        {/* Size (Visible Only to Super Users) */}
        {isSuperUser && (
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="size"
                label="Size (sqm)"
                rules={[
                  { required: true, message: "Please enter the size." },
                  {
                    type: "number",
                    min: 0,
                    message: "Size must be a positive number.",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder="Enter size in square meters"
                  disabled={!form.getFieldValue('building_id')}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Divider />

        {/* KPI Targets */}
        <Title level={5}>KPI Targets</Title>
        <Row gutter={16}>
          {/* Temperature Targets */}
          <Col xs={24} sm={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="temp_target_lower"
                  label="Temp Lower (°C)"
                  initialValue={18}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the lower temperature target.",
                    },
                    {
                      type: "number",
                      min: 10,
                      max: 25,
                      message: "Temperature must be between 10°C and 25°C.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Lower Temp Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="temp_target_upper"
                  label="Temp Upper (°C)"
                  initialValue={24}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the upper temperature target.",
                    },
                    {
                      type: "number",
                      min: 10,
                      max: 25,
                      message: "Temperature must be between 10°C and 25°C.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Upper Temp Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* CO2 Targets */}
          <Col xs={24} sm={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="co2_target_lower"
                  label="CO2 Lower (ppm)"
                  initialValue={400}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the lower CO2 target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 10000,
                      message: "CO2 must be between 0 ppm and 10,000 ppm.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Lower CO2 Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="co2_target_upper"
                  label="CO2 Upper (ppm)"
                  initialValue={1000}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the upper CO2 target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 10000,
                      message: "CO2 must be between 0 ppm and 10,000 ppm.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Upper CO2 Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* Humidity Targets */}
          <Col xs={24} sm={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="humd_target_lower"
                  label="Humidity Lower (%)"
                  initialValue={30}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the lower humidity target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "Humidity must be between 0% and 100%.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Lower Humidity Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="humd_target_upper"
                  label="Humidity Upper (%)"
                  initialValue={50}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the upper humidity target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "Humidity must be between 0% and 100%.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Upper Humidity Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* PM2.5 Targets */}
          <Col xs={24} sm={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="pm25_target_lower"
                  label="PM2.5 Lower (mcg/m³)"
                  initialValue={0}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the lower PM2.5 target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "PM2.5 must be between 0 and 100 mcg/m³.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Lower PM2.5 Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pm25_target_upper"
                  label="PM2.5 Upper (mcg/m³)"
                  initialValue={35}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the upper PM2.5 target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 100,
                      message: "PM2.5 must be between 0 and 100 mcg/m³.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Upper PM2.5 Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={16}>
          {/* Light Targets */}
          <Col xs={24} sm={12}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="lux_target_lower"
                  label="Light Lower (Lux)"
                  initialValue={300}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the lower light target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 1000,
                      message: "Light must be between 0 and 1,000 Lux.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Lower Light Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lux_target_upper"
                  label="Light Upper (Lux)"
                  initialValue={500}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the upper light target.",
                    },
                    {
                      type: "number",
                      min: 0,
                      max: 1000,
                      message: "Light must be between 0 and 1,000 Lux.",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Upper Light Target"
                    disabled={!form.getFieldValue('building_id')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ZoneModal;
