import React, { useEffect, useState } from 'react';
import UserStore from '../../stores/userStore';
import OrganisationActions from '../../actions/organisationActions';
import OrganisationStore from '../../stores/organisationStore';
import { DocumentTitle } from '../../components/DocumentTitle';
import { Typography, Row, Col, Form, Input, Button, Card, Select } from "antd";
import { LogoSpinner } from '../../components/LogoSpinner';
import UserActions from '../../actions/userActions';

const { Title, Text } = Typography;

const ChangeOrganisation = ({ history }) => {
  const [user] = useState(UserStore.getUser());
  const [orgs, setOrgs] = useState(OrganisationStore.getOrganisations());
  const [loading, setLoading] = useState(true);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  useEffect(() => {
    const onOrganisationChange = () => {
      setOrgs(OrganisationStore.getOrganisations());
      setLoading(false);
    };

    OrganisationStore.addChangeListener(onOrganisationChange);

    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(false);
    }

    return () => {
      OrganisationStore.removeChangeListener(onOrganisationChange);
    };
  }, [history]);

  useEffect(() => {
    const onOrganisationOperation = () => {
      OrganisationActions.getOrganisations(false);
    };

    OrganisationStore.addOperationListener(onOrganisationOperation);

    return () => {
      OrganisationStore.removeOperationListener(onOrganisationOperation);
    };
  }, []);

  const formattedOrgs = orgs.map(org => ({
    value: org.organisation_id,
    label: org.name
  }));

  const handleChange = (value, option) => {
    setSelectedOrganisation(option);
  };

  const updateUser = async () => {
    if (selectedOrganisation) {
      UserActions.updateUserOrganisation(user.user_id, selectedOrganisation.value)
        .then(() => history.push('/login'))
    }
  };

  return (
    <div className="br-mainpanel br-profile-page floorplan-background">
      <DocumentTitle title='Change Organisation' />
      <LogoSpinner loading={loading} />
      <div className="br-container">
        <Row gutter={[30, 30]} className='mg-t-30'>
          <Col span={24}>
            <Title level={3}>Change Organisation</Title>
            <Text type='secondary'>
              Change the organisation your account belongs to.
            </Text>
          </Col>
          <Col lg={12} xs={24}>
            <Card title="Change Organisation">
              <Form layout="vertical">
                <Form.Item label="Current Organisation">
                  <Input
                    disabled
                    value={orgs.find(org => org.organisation_id === user.fk_organisation_id)?.name}
                    placeholder="Current Organisation"
                  />
                </Form.Item>
                <Form.Item label="Select New Organisation">
                  <Select
                    showSearch
                    placeholder="Select Organisation"
                    optionFilterProp="label"
                    onChange={handleChange}
                    options={formattedOrgs}
                    filterSort={(optionA, optionB) => optionA.label.localeCompare(optionB.label)}
                  />
                </Form.Item>
                <Form.Item>
                  <Button 
                    block 
                    type='primary' 
                    onClick={updateUser} 
                    disabled={!selectedOrganisation || user.fk_organisation_id === selectedOrganisation.value}
                  >
                    Update
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChangeOrganisation;
