import React, { Component } from "react";
import "./ManageAssets.scss";

import ZonesActions from "../../actions/zonesActions";
import ZonesStore from "../../stores/zonesStore";
import AssetsActions from "../../actions/assetsActions";
import AssetsStore from "../../stores/assetsStore";
import MeterActions from "../../actions/meterActions";
import MeterStore from "../../stores/meterStore";
import OrganisationActions from "../../actions/organisationActions";
import OrganisationStore from "../../stores/organisationStore";
import UserStore from "../../stores/userStore";

import GeneralUtils from "../../utils/GeneralUtils";
import { SearchSelect } from "../../components/SearchSelect";
import { DocumentTitle } from "../../components/DocumentTitle";
import { ModifyAssetPanel } from "./ModifyAssetPanel";
import { LogoSpinner } from "../../components/LogoSpinner";
import _ from "lodash";

import { toast } from "react-toastify";


import { Button, Typography } from 'antd';
import { Icon } from '../../components/Icon';

const { Title, Text } = Typography;

class ManageAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zones: ZonesStore.getZones(),
      buildings: OrganisationStore.getBuildings(),
      available_meters: [],
      asset_creation: false,
      selected_building: null,
      selected_zone: null,
      selected_asset_type: null,
      asset_types: [],
      items_expanded: [],
      assets: [],
      new_asset: {
        name: "",
        description: "",
        location: "",
        building_id: 0,
        asset_type_id: 0,
        zone_id: null,
        generator_meters: [],
      },
    };
    // listener events
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._onZonesChange = this._onZonesChange.bind(this);
    this._onAssetChange = this._onAssetChange.bind(this);
    this._onAssetOperation = this._onAssetOperation.bind(this);
    this._onMetersFetched = this._onMetersFetched.bind(this);
    this._getAvailableMeters = this._getAvailableMeters.bind(this);

    // filter setters
    this._setTargetBuilding = this._setTargetBuilding.bind(this);
    this._setTargetZone = this._setTargetZone.bind(this);
    this._setAssetType = this._setAssetType.bind(this);

    //helpers and controllers
    this._changeAssetValues = this._changeAssetValues.bind(this);
    this._changeGeneratorMeters = this._changeGeneratorMeters.bind(this);
    this._clearGeneratorMeters = this._clearGeneratorMeters.bind(this);
    this._validateAssetInput = this._validateAssetInput.bind(this);
    this._toggleAssetCreation = this._toggleAssetCreation.bind(this);
    this._resetFiltersHandler = this._resetFiltersHandler.bind(this);
    this._expansionHandler = this._expansionHandler.bind(this);
    this._massExpansionHandler = this._massExpansionHandler.bind(this);

    // Operation actions
    this._saveAsset = this._saveAsset.bind(this);
    this._updateAsset = this._updateAsset.bind(this);
    this._deleteAsset = this._deleteAsset.bind(this);
  }
  _expansionHandler(asset_id = null) {
    if (this.state.items_expanded.includes(asset_id)) {
      this.setState({
        items_expanded: this.state.items_expanded.filter(
          (item) => item !== asset_id
        ),
      });
    } else {
      this.setState({
        items_expanded: [...this.state.items_expanded, asset_id],
      });
    }
  }

  _massExpansionHandler() {
    if (this.state.items_expanded.length > 0) {
      this.setState({ items_expanded: [] });
    } else {
      this.setState({
        items_expanded: [...this.state.assets.map((el) => el.asset_id)],
      });
    }
  }

  _onOrganisationChange() {
    this.setState({
      buildings: OrganisationStore.getBuildings(),
      asset_types: AssetsStore.getAssetTypes(),
    });
  }

  _onZonesChange() {
    AssetsActions.getBuildingAssets(
      this.state.selected_building && this.state.selected_building.building_id
    );

    this.setState({
      zones: ZonesStore.getZones(),
    });
  }

  _onAssetChange() {
    this.setState({ assets: AssetsStore.getAssets() }, () => {
      if (this.state.selected_building)
        MeterActions.getMetersByBuildingId(
          this.state.selected_building.building_id
        );
    });
  }

  _onAssetOperation() {
    this._toggleAssetCreation(false);
    AssetsActions.getBuildingAssets(
      this.state.selected_building && this.state.selected_building.building_id
    );
  }

  _onMetersFetched() {
    let fetched_meters = MeterStore.getMeters();

    // pass them to available meters
    this.setState({
      available_meters: fetched_meters,
    });
  }

  _getAvailableMeters(asset) {
    let available_meters = [];
    let already_used_meter_ids = [];

    // get all generator meter ids from assets except the one for the asset

    this.state.assets.forEach((a) => {
      if (a.asset_id !== asset.asset_id) {
        a.generator_meters.forEach((m) => {
          already_used_meter_ids.push(m.meter_id);
        });
      }
    });

    // filter meters removing the ids that were specified above
    available_meters = this.state.available_meters.filter((m) => {
      return already_used_meter_ids.includes(m.meter_id) === false;
    });

    return available_meters;
  }

  UNSAFE_componentWillMount() {
    OrganisationStore.addChangeListener(this._onOrganisationChange);
    ZonesStore.addChangeListener(this._onZonesChange);
    AssetsStore.addChangeListener(this._onAssetChange);
    AssetsStore.addAssetOperationListener(this._onAssetOperation);

    MeterStore.addChangeListener(this._onMetersFetched);
  }

  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(true);
      AssetsActions.getAssetTypes();
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
    AssetsActions.getAssetTypes();
  }

  componentWillUnmount() {
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
    ZonesStore.removeChangeListener(this._onZonesChange);
    AssetsStore.removeChangeListener(this._onAssetChange);
    AssetsStore.removeAssetOperationListener(this._onAssetOperation);

    ZonesStore.clear();
    AssetsStore.clear();
  }

  _setTargetBuilding(selected_building) {
    ZonesActions.getZones(selected_building.building_id);
    MeterActions.getMetersByBuildingId(selected_building.building_id);

    this.setState({
      selected_building: selected_building,
      asset_types: AssetsStore.getAssetTypes(),
      asset_creation: false,
      new_asset: {
        ...this.state.new_asset,
        building_id: selected_building.building_id,
      },
    });
  }

  _setTargetZone(selected_zone) {
    this.setState({
      selected_zone: selected_zone,
      new_asset: { ...this.state.new_asset, zone_id: selected_zone.zone_id },
    });
  }

  _setAssetType(asset_type) {
    this.setState({
      selected_asset_type: asset_type,
      new_asset: {
        ...this.state.new_asset,
        asset_type_id: asset_type.asset_type_id,
        generator_meters: [],
      },
    });
  }

  noRowsAvailable(numRows) {
    let rows = [];
    for (let i = 0; i < numRows; i++) {
      rows.push(<td key={i} className="valign-middle"></td>);
    }
    return (
      <tr>
        <td className="valign-middle" colSpan={2}>
          <div className="d-flex align-items-center">
            <div>
              <div className="tx-inverse">
                {this.state.selected_building == null
                  ? "No assets available, please select a target building."
                  : "No assets available, check zone and type filters. Please contact support to add new assets."}
              </div>
            </div>
          </div>
        </td>
        {rows}
      </tr>
    );
  }
  _validateAssetInput(asset) {
    let validBuilding = parseInt(asset.building_id) > 0;
    let validAssetTypeId = parseInt(asset.asset_type_id) > 0;
    let validName = GeneralUtils.stringNotEmpty(asset.name);
    let validLocation = GeneralUtils.stringNotEmpty(asset.location);
    let validDescription = GeneralUtils.stringNotEmpty(asset.description);
    let validGeneratorMeters = true;
    if (asset.asset_type_id === 22 || asset.asset_type_id === 30) {
      validGeneratorMeters =
        asset.generator_meters.length === 0 ||
        (asset.generator_meters.length === 2 &&
          asset.generator_meters.filter((m) => m.meter_role === "OUT")
            .length === 1);
    } else if (asset.asset_type_id === 28) {
      validGeneratorMeters =
        asset.generator_meters.length === 0 ||
        (asset.generator_meters.length === 3 &&
          asset.generator_meters.filter((m) => m.meter_role === "OUT")
            .length === 2);
    }

    return (
      validBuilding &&
      validName &&
      validLocation &&
      validDescription &&
      validAssetTypeId &&
      validGeneratorMeters
    );
  }

  _changeGeneratorMeters(id, type, asset_id, asset_type_id, prev = null) {
    // New Asset
    if (asset_id === undefined) {
      let new_asset = this.state.new_asset;
      let generator_meters = new_asset.generator_meters;
      let in_meters = generator_meters.filter((m) => m.meter_role === "IN");
      let out_meters = generator_meters.filter((m) => m.meter_role === "OUT");

      if (isNaN(+id) && prev) {
        new_asset.generator_meters = generator_meters.filter((g) => {
          return prev !== g.meter_id;
        });
      } else if (type === "IN") {
        // no in meters
        if (in_meters.length === 0) {
          generator_meters.push({
            meter_id: +id,
            meter_role: "IN",
          });
        } else {
          generator_meters.forEach((m) => {
            if (m.meter_role === "IN") {
              m.meter_id = +id;
            }
          });
        }
      } else if (type === "OUT") {
        if (asset_type_id === 28) {
          if (out_meters.length === 0) {
            generator_meters.push({
              meter_id: +id,
              meter_role: "OUT",
            });
          } else if (out_meters.length === 1) {
            if (prev) {
              generator_meters.forEach((m) => {
                if (m.meter_role === "OUT") {
                  m.meter_id = +id;
                }
              });
            } else {
              generator_meters.push({
                meter_id: +id,
                meter_role: "OUT",
              });
            }
          } else if (out_meters.length === 2) {
            generator_meters.forEach((m) => {
              if (m.meter_role === "OUT" && m.meter_id === prev) {
                m.meter_id = +id;
              }
            });
          }
        } else {
          if (out_meters.length === 0) {
            generator_meters.push({
              meter_id: +id,
              meter_role: "OUT",
            });
          } else {
            generator_meters.forEach((m) => {
              if (m.meter_role === "OUT") {
                m.meter_id = +id;
              }
            });
          }
        }
      }

      this.setState({
        new_asset: new_asset,
      });
    } else if (asset_id) {
      let assets = [...this.state.assets];
      for (let i = 0; i < assets.length; i++) {
        if (assets[i].asset_id === asset_id) {
          let generator_meters = assets[i].generator_meters;
          let in_meters = generator_meters.filter((m) => m.meter_role === "IN");
          let out_meters = generator_meters.filter(
            (m) => m.meter_role === "OUT"
          );

          // Setting back to null
          if (isNaN(+id) && prev) {
            assets[i].generator_meters = generator_meters.filter((g) => {
              return prev !== g.meter_id;
            });
          } else if (type === "IN") {
            // no in meters
            if (in_meters.length === 0) {
              generator_meters.push({
                meter_id: +id,
                meter_role: "IN",
              });
            } else if (in_meters.length) {
              generator_meters.forEach((m) => {
                if (m.meter_role === "IN") {
                  m.meter_id = +id;
                }
              });
            }
          } else if (type === "OUT") {
            if (asset_type_id === 28) {
              // Empty
              if (out_meters.length === 0) {
                generator_meters.push({
                  meter_id: +id,
                  meter_role: "OUT",
                });
              } else if (out_meters.length === 1) {
                if (prev) {
                  generator_meters.forEach((m) => {
                    if (m.meter_role === "OUT") {
                      m.meter_id = +id;
                    }
                  });
                } else {
                  generator_meters.push({
                    meter_id: +id,
                    meter_role: "OUT",
                  });
                }
              } else if (out_meters.length === 2) {
                generator_meters.forEach((m) => {
                  if (m.meter_role === "OUT" && m.meter_id === prev) {
                    m.meter_id = +id;
                  }
                });
              }
            } else {
              if (out_meters.length === 0) {
                generator_meters.push({
                  meter_id: +id,
                  meter_role: "OUT",
                });
              } else {
                generator_meters.forEach((m) => {
                  if (m.meter_role === "OUT") {
                    m.meter_id = +id;
                  }
                });
              }
            }
          }

          break;
        }
      }

      this.setState({
        assets: assets,
      });
    }
  }

  _clearGeneratorMeters(asset_id) {
    if (asset_id === undefined) {
      this.setState({
        new_asset: {
          ...this.state.new_asset,
          generator_meters: [],
        },
      });
    } else {
      let assets = [...this.state.assets];

      for (let i = 0; i < assets.length; i++) {
        if (assets[i].asset_id === asset_id) {
          assets[i].generator_meters = [];
          break;
        }
      }

      this.setState({
        assets: assets,
      });
    }
  }

  _changeAssetValues(asset, target_property, event) {
    let assets = [...this.state.assets];
    let new_asset = this.state.new_asset;
    const string_target_properties = [
      "name",
      "location",
      "description",
      "virtual_values",
      "reference_id",
    ];
    let value = string_target_properties.includes(target_property)
      ? event.target.value
      : +event.target.value;

    let editExisting = false;
    for (let i = 0; i < assets.length; i++) {
      if (assets[i].asset_id === asset.asset_id) {
        assets[i][target_property] = value;
        editExisting = true;
        break;
      }
    }

    if (!editExisting) {
      new_asset[target_property] = value;
    }

    this.setState({ new_asset: new_asset, assets: assets });
  }

  _saveAsset(asset) {
    if (this._validateAssetInput(asset)) {
      AssetsActions.saveAsset(asset);
    } else {
      toast.warn("Invalid Input, Please check fields and try again")
    }
  }

  _updateAsset(asset) {
    if (this._validateAssetInput(asset) && asset.asset_id) {
      AssetsActions.updateAsset(asset);
    } else {
      toast.warn("Invalid Input, Please check fields and try again")
    }
  }

  _deleteAsset(asset_id) {
    if (window.confirm("Are you sure you wish to delete this asset?")) {
      AssetsActions.deleteAsset(asset_id);
    }
  }

  _toggleAssetCreation(target_state = null) {
    if (this.state.asset_creation || target_state === false) {
      this.setState({
        asset_creation: false,
        new_asset: {
          name: "",
          description: "",
          location: "",
          building_id: this.state.selected_building.building_id,
          asset_type_id: this.state.selected_asset_type
            ? this.state.selected_asset_type.asset_type_id
            : 0,
          zone_id: null,
          generator_meters: [],
        },
      });
    } else {
      this.setState({ asset_creation: true });
    }
  }

  _resetFiltersHandler() {
    this._toggleAssetCreation(false);
    this.setState({ selected_zone: null, selected_asset_type: null });
  }

  getAssetTable() {
    let assetRows = this.noRowsAvailable(6);
    let newAssetRow = null;
    let resetFiltersButton = (
      <Button
        className="button orange"
        icon={<Icon name="Reset" color={'#fff'} size={18} />}
        size="small"
        onClick={this._resetFiltersHandler}
        style={{ marginLeft: '20px' }}
      >Reset</Button>
    );
    let buildingsFormattedArray = this.state.buildings.map((building) => {
      return {
        ...building,
        name: building.name + ` (building id: ${building.building_id})`,
      };
    });
    let zonesFormattedArray = this.state.zones.map((zone) => {
      return { ...zone, name: zone.name + ` (zone id: ${zone.zone_id})` };
    });
    let assetTypesFormattedArray = this.state.asset_types.map((item) => {
      return {
        ...item,
        name: GeneralUtils.getAssetName(item.type) + ` (asset type id: ${item.asset_type_id})`,
      };
    });

    buildingsFormattedArray = _.orderBy(buildingsFormattedArray, "name", "asc");
    zonesFormattedArray = _.orderBy(zonesFormattedArray, "name", "asc");
    assetTypesFormattedArray = _.orderBy(assetTypesFormattedArray, "name", "asc");

    let assets = _.orderBy(this.state.assets, "name", "asc");
    let button = (
      <Button
        className="button green"
        icon={<Icon name="AddCircleFilled" color={'#fff'} size={18} />}
        size="small"
        onClick={this._toggleAssetCreation}
        style={{ marginLeft: '20px' }}
      >Add Asset</Button>
    );
    if (this.state.asset_creation) {
      button = (
        <Button
          className="button red"
          icon={<Icon name="Close" color={'#fff'} size={18} />}
          size="small"
          onClick={this._toggleAssetCreation}
          style={{ marginLeft: '20px' }}
        >Close</Button>
      );
    }

    if (this.state.asset_creation) {
      newAssetRow = (
        <ModifyAssetPanel
          key={"new_asset"}
          zones={this.state.zones}
          meters={this._getAvailableMeters(this.state.new_asset)}
          changeMeters={this._changeGeneratorMeters}
          clearMeters={this._clearGeneratorMeters}
          asset_types={this.state.asset_types}
          building={this.state.selected_building}
          changeAssetValues={this._changeAssetValues}
          expanded={true}
          asset={this.state.new_asset}
          updateZone={this._updateAsset}
          saveAsset={this._saveAsset}
          cancelAsset={this._toggleAssetCreation}
        />
      );
    }

    if (assets.length > 0) {
      assetRows = assets.reverse().map((asset, key) => {
        const zoneFilter = this.state.selected_zone
          ? this.state.selected_zone.zone_id === asset.zone_id
          : true;
        const typeFilter = this.state.selected_asset_type
          ? this.state.selected_asset_type.asset_type_id === asset.asset_type_id
          : true;

        if (asset.asset_id && zoneFilter && typeFilter) {
          return (
            <ModifyAssetPanel
              key={asset.asset_id}
              changeAssetValues={this._changeAssetValues}
              deleteAsset={this._deleteAsset}
              meters={this._getAvailableMeters(asset)}
              changeMeters={this._changeGeneratorMeters}
              clearMeters={this._clearGeneratorMeters}
              asset={asset}
              zones={this.state.zones}
              asset_types={this.state.asset_types}
              expanded={this.state.items_expanded.includes(asset.asset_id)}
              expansionHandler={this._expansionHandler}
              building={this.state.selected_building}
              updateAsset={this._updateAsset}
              saveAsset={this._saveAsset}
            />
          );
        } else {
          return null;
        }
      });
    }
    return (
      <div>
        <div
          className="br-pagetitle pd-x-0 pd-b-0 mg-b-10 flex-wrap"
          style={{ width: "100%" }}
        >
          <span className="col-12 col-md d-flex pd-0 flex-wrap">
            <span className="col-4 pd-l-0">
              <SearchSelect
                limit={100}
                options={buildingsFormattedArray}
                placeholder={"Select Building"}
                defaultValue={this.state.selected_building}
                actionOnSelectedOption={this._setTargetBuilding}
              />
            </span>
            <span className="col-4 pd-x-0">
              <SearchSelect
                limit={100}
                options={assetTypesFormattedArray}
                placeholder={"Select Asset Type"}
                defaultValue={this.state.selected_asset_type}
                actionOnSelectedOption={this._setAssetType}
              />
            </span>
            <span className="col-4 pd-r-0">
              <SearchSelect
                limit={100}
                options={zonesFormattedArray}
                placeholder={"Select Zone"}
                defaultValue={this.state.selected_zone}
                actionOnSelectedOption={this._setTargetZone}
              />
            </span>
          </span>
          {(this.state.selected_zone || this.state.selected_asset_type) && resetFiltersButton}
          {this.state.selected_building && this.state.selected_asset_type && button}
        </div>
        <div className="card bd-0 shadow-base mg-t-5 overflow-auto">
          <table className="table mg-b-0 table-contact">
            <thead>
              <tr>
                <th className="wd-15p tx-mont tx-medium">
                  Name<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-25p tx-10-force tx-mont tx-medium">
                  Description<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-15p tx-10-force tx-mont tx-medium">
                  Location<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-15p tx-10-force tx-mont tx-medium">Zone</th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">
                  Asset Type<span style={{ color: "red" }}>*</span>
                </th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">Ref ID</th>
                <th className="wd-10p tx-10-force tx-mont tx-medium">
                  Virtual Values
                </th>
                <th style={{ paddingBottom: "5px" }}>
                  <button
                    className="btn btn-info ht-30 d-flex align-items-center"
                    onClick={this._massExpansionHandler}
                  >
                    {this.state.items_expanded.length > 0 ? "-" : "+"}
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {newAssetRow}
              {assetRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="br-mainpanel br-profile-page floorplan-background">
        <DocumentTitle title="Manage Assets" />
        <LogoSpinner loading={this.state.loading} />
        <div className="br-container">
        <div className="row">
            <div className="col-12 mg-t-30">
              <Title level={3}>Manage Assets</Title>
              <Text type="secondary">
              Update and create new assets. Select the building and asset type
              to continue.
              </Text>
            </div>
            <div className="col-12">{this.getAssetTable()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageAssets;
