import Dispatcher from "../dispatcher";
import ActionTypes from "../constants";
import API from "../api";
import GeneralUtils from "../utils/GeneralUtils";
import { toast } from "react-toastify";

/**
 * Actions related to managing users. This class interfaces with the API
 * to retrieve, create, update, and delete user data, dispatching actions to update the application state.
 *
 * @class
 * @example
 * const userActions = new UserActions();
 * userActions.getOrganisationUsers('org123');
 */
class UserActions {
  /**
   * Fetches the users for a specific organisation from the API and dispatches an action to store this data.
   *
   * @param {string} organisationId - The unique identifier of the organisation.
   * @returns {Promise} - A promise that resolves with the fetched data.
   */
  getOrganisationUsers(organisationId) {
    return GeneralUtils.get({
      url: `user/organisation/${organisationId}`,
      actionType: ActionTypes.ORGANISATION_USERS_FETCHED,
    });
  }

  /**
   * Updates the organisation for a specific user by sending a request to the API and dispatches an action to store this data.
   *
   * @param {string} userId - The unique identifier of the user.
   * @param {string} organisationId - The unique identifier of the new organisation.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateUserOrganisation(userId, organisationId) {
    return GeneralUtils.get({
      url: `user/${userId}/changeorganisation/${organisationId}`,
      actionType: ActionTypes.USER_ORGANISATION_UPDATED,
      successToast: "User updated successfully",
      failToast: "Unable to update user, please try again",
    });
  }

  /**
   * Saves a new user to the API and dispatches an action to store the new user data.
   *
   * @param {string} [app="ENGINEERING_UI"] - The application context.
   * @param {Object} user - The user object to be saved.
   * @returns {Promise} - A promise that resolves with the response of the save operation.
   */
  saveUser(app = "ENGINEERING_UI", user) {
    return GeneralUtils.post({
      url: `user?app=${app}`,
      object: user,
      actionType: ActionTypes.USER_SAVED,
      successToast: "User saved successfully",
      failToast: (error) => {
        if (error.message.includes('User already exists')) {
          return "User already exists. Please use a different email address.";
        }
        return "Unable to save user, please try again";
      }
    });
  }

  /**
   * Updates an existing user in the API and dispatches an action to store the updated data.
   *
   * @param {Object} user - The user object to be updated.
   * @param {boolean} [updateBuildingAccess=false] - Whether to update building access for the user.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  updateUser(user, updateBuildingAccess = false) {
    return GeneralUtils.put({
      url: `user?updateBuildingAccess=${updateBuildingAccess}`,
      object: user,
      actionType: ActionTypes.USER_UPDATED,
      successToast: "User updated successfully",
      failToast: "Unable to update user, please try again",
    });
  }

  /**
   * Deletes a specific user by its ID from the API and dispatches an action to remove this data from the state.
   *
   * @param {Object} user - The user object to be deleted.
   * @returns {Promise} - A promise that resolves with the response of the delete operation.
   */
  deleteUser(user) {
    return GeneralUtils.delete({
      url: `user/${user.user_id}`,
      actionType: ActionTypes.USER_DELETED,
      successToast: "User deleted successfully",
      failToast: "Unable to delete user, please try again",
    });
  }

  /**
   * Logs in a user by sending the provided user credentials to the API and dispatches actions based on the response.
   *
   * @param {Object} user - The user object containing email and password.
   */
  login(user) {
    API.postLogin("login", user.mail, user.password).then((response) => {
      if (typeof response.code !== "undefined") {
        if (response.code === 200) {
          Dispatcher.dispatch({
            actionType: ActionTypes.LOGIN,
            payload: response,
          });
        } else {
          Dispatcher.dispatch({
            actionType: ActionTypes.LOGIN_FAIL,
            payload: response,
          });
        }
      }
    });
  }


  /**
   * Resends an invitation to a specific user by sending the user ID to the API and dispatches actions based on the response.
   *
   * @param {string} userId - The unique identifier of the user.
   * @param {string} [app="ENGINEERING_UI"] - The application context.
   * @returns {Promise} - A promise that resolves with the response of the resend operation.
   */
  resendInvitation(userId, app = "ENGINEERING_UI") {
    return GeneralUtils.post({
      url: `user/${userId}/resend-invite?app=${app}`,
      successToast: "Invitation resent successfully",
      failToast: "Unable to resend invitation, please try again",
    });
  }

  /**
   * Logs in a user with a reset token by sending the provided credentials to the API and dispatches actions based on the response.
   *
   * @param {string} userId - The unique identifier of the user.
   * @param {string} token - The reset token.
   * @param {string} password - The new password.
   */
  loginWithResetToken(userId, token, password) {
    API.postResetLogin("reset-password", userId, token, password).then(
      (response) => {
        if (typeof response.code !== "undefined") {
          if (response.code === 200) {
            Dispatcher.dispatch({
              actionType: ActionTypes.PASSWORD_RESET,
              payload: response,
            });
          } else {
            toast.error("Unable to send password reset request, please try again");
            Dispatcher.dispatch({
              actionType: ActionTypes.PASSWORD_RESET_FAIL,
              payload: response,
            });
          }
        }
      }
    );
  }

  /**
   * Logs in a user with an invite token by sending the provided credentials to the API and dispatches actions based on the response.
   *
   * @param {string} userId - The unique identifier of the user.
   * @param {string} token - The invite token.
   * @param {string} password - The new password.
   */
  loginWithInviteToken(userId, token, password) {
    API.postInviteLogin("verify-invite", userId, token, password).then(
      (response) => {
        if (typeof response.code !== "undefined") {
          if (response.code === 200) {
            Dispatcher.dispatch({
              actionType: ActionTypes.INVITE_ACTIVATION,
              payload: response,
            });
          } else {
            toast.error("Unable to activate this account, please try again");
            Dispatcher.dispatch({
              actionType: ActionTypes.INVITE_ACTIVATION_FAIL,
              payload: response,
            });
          }
        }
      }
    );
  }

  /**
   * Sends a password reset request by sending the provided email to the API and dispatches actions based on the response.
   *
   * @param {string} mail - The email address for the password reset request.
   * @returns {Promise} - A promise that resolves with the response of the send operation.
   */
  sendPasswordReset(mail) {
    API.postPasswordReset(mail).then((response) => {
      if (typeof response.code !== "undefined") {
        if (response.code === 200) {
          toast.success("Password reset request sent, Please check your email inbox");
          } else {
          toast.error("Unable to send password reset request, please try again");
        }
      }
    });
  }

  /**
   * Changes the password for a specific user by sending the provided new password to the API and dispatches actions based on the response.
   *
   * @param {Object} user - The user object.
   * @param {string} newPassword - The new password.
   * @returns {Promise} - A promise that resolves with the response of the update operation.
   */
  changePassword(user, newPassword) {
    return GeneralUtils.post({
      url: `user/${user.user_id}/changepassword`,
      object: { password: newPassword },
      successToast: "Password successfully updated.",
      failToast: "Unable to update password, please try again",
    });
  }

  /**
   * Updates the password in the application's state by dispatching an action with the provided password.
   *
   * @param {string} password - The new password.
   */
  updatePassword(password) {
    setTimeout(function () {
      Dispatcher.dispatch({
        actionType: ActionTypes.UPDATE_PASSWORD_VALUE,
        payload: password,
      });
    }, 50);
  }

  /**
   * Updates the email in the application's state by dispatching an action with the provided email.
   *
   * @param {string} mail - The new email address.
   */
  updateMail(mail) {
    setTimeout(function () {
      Dispatcher.dispatch({
        actionType: ActionTypes.UPDATE_MAIL_VALUE,
        payload: mail,
      });
    }, 50);
  }
}

const userActions = new UserActions();
export default userActions;
