import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Input, Button } from 'antd';
import { Icon } from '../Icon';
import _ from 'lodash';
import moment from 'moment';
import styles from './AntTable.module.scss';
import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';

/**
 * AntTable Component
 *
 * A customizable table component built on top of Ant Design's Table.
 * Features include:
 * - Input-based filtering.
 * - Tag-based filtering.
 * - Sorting with persistence via sessionStorage.
 * - CSV export functionality.
 * - Dynamic rendering of filter elements.
 *
 * Props:
 * - tableId: string (required) - Unique identifier for the table, used for storing filter states.
 * - columns: array (required) - Column definitions for the table.
 * - dataSource: array - Data to display in the table.
 * - tags: array - Tags for additional filtering.
 * - addNewButton: node - React node for the "Add New" button.
 * - hasInputFilter: bool - Whether to display an input filter.
 * - multiTagFilter: bool - Whether multiple tag selections are allowed.
 * - pagination: bool | object - Pagination configuration.
 * - onRow: func - Event handlers for table rows.
 * - scroll: object - Scroll configuration for the table.
 * - exporting: bool | object - Exporting configuration.
 * - stickyFilters: bool - Whether to persist filter states using sessionStorage.
 * - ...rest - Other props passed to the Ant Design Table component.
 */
const AntTable = (props) => {
    const {
        columns,
        dataSource = [],
        tags,
        addNewButton,
        hasInputFilter = false,
        multiTagFilter,
        pagination = false,
        onRow,
        scroll = {},
        exporting,
        tableId,
        stickyFilters = true,
        ...rest
    } = props;

    // State initializations with sessionStorage persistence if stickyFilters is true
    const [inputFilter, setInputFilter] = useState(() =>
        stickyFilters ? sessionStorage.getItem(`${tableId}_inputFilter`) || '' : ''
    );
    const [selectedTags, setSelectedTags] = useState(() =>
        stickyFilters 
            ? JSON.parse(sessionStorage.getItem(`${tableId}_selectedTags`) || '["All"]') 
            : ['All']
    );
    const [sortField, setSortField] = useState(() => {
        if (stickyFilters) {
            const storedSortField = sessionStorage.getItem(`${tableId}_sortField`);
            return storedSortField ? JSON.parse(storedSortField) : null;
        }
        return null;
    });
    const [sortOrder, setSortOrder] = useState(() =>
        stickyFilters ? sessionStorage.getItem(`${tableId}_sortOrder`) || null : null
    );

    const [tableHeight, setTableHeight] = useState(null);
    const [isNonScrollable, setIsNonScrollable] = useState(false);
    const wrapperRef = useRef(null);
    const filtersRef = useRef(null);

    /**
     * Helper function to extract text from render functions.
     * If the render function returns a React element, convert it to static markup.
     *
     * @param {Function} renderFn - The render function of the column.
     * @param {any} text - The text value of the cell.
     * @param {Object} record - The entire data record for the row.
     * @returns {string} - The extracted text for filtering and sorting.
     */
    const getRenderText = useCallback((renderFn, text, record) => {
        if (renderFn) {
            const rendered = renderFn(text, record);
            if (React.isValidElement(rendered)) {
                // Convert React elements to static markup and extract text
                const staticMarkup = ReactDOMServer.renderToStaticMarkup(rendered);
                // Remove any HTML tags to get plain text
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = staticMarkup;
                return tempDiv.textContent || tempDiv.innerText || '';
            }
            // If render function returns a string or number
            return String(rendered);
        }
        // Fallback to raw data
        return text != null ? String(text) : '';
    }, []);

    /**
     * Filtering logic utilizing rendered values.
     * Filters data based on input filter and selected tags.
     * Uses rendered text from render functions for accurate filtering.
     */
    const filterData = useCallback((filterValue, tagLabels) => {
        const lowercasedFilter = filterValue.toLowerCase();
        let filtered = dataSource;

        // Filter by input text
        if (lowercasedFilter) {
            filtered = dataSource.filter(item => {
                return columns.some(column => {
                    if (column.filterable) {
                        const value = _.get(item, column.dataIndex);
                        const renderFn = column.render;
                        const textForFilter = getRenderText(renderFn, value, item);
                        return textForFilter.toLowerCase().includes(lowercasedFilter);
                    }
                    return false;
                });
            });
        }

        // Filter by tag(s)
        if (tags && tags.length > 0 && !tagLabels.includes('All')) {
            filtered = filtered.filter(record => {
                return tagLabels.some(tagLabel => {
                    const selectedTagObj = tags.find(tag => tag.label === tagLabel);
                    return selectedTagObj && selectedTagObj.condition(record);
                });
            });
        }
        return filtered;
    }, [dataSource, columns, tags, getRenderText]);

    // Memoize the filtered data to optimize performance
    const filteredData = useMemo(() => {
        return filterData(inputFilter, selectedTags);
    }, [filterData, inputFilter, selectedTags]);

    /**
     * Handler for input filter changes.
     * Updates the inputFilter state and persists it if stickyFilters is enabled.
     */
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputFilter(value);
        if (stickyFilters) {
            sessionStorage.setItem(`${tableId}_inputFilter`, value);
        }
    };

    /**
     * Handler for tag clicks.
     * Updates the selectedTags state based on user interactions and persists it if stickyFilters is enabled.
     */
    const handleTagClick = (label) => {
        setSelectedTags(prevTags => {
            let newTags;
            if (label === 'All') {
                newTags = ['All'];
            } else if (multiTagFilter) {
                // Multiple tag selection
                if (prevTags.includes(label)) {
                    newTags = prevTags.filter(tag => tag !== label);
                    if (newTags.length === 0) newTags = ['All'];
                } else {
                    newTags = prevTags.filter(tag => tag !== 'All');
                    newTags.push(label);
                }
            } else {
                // Single tag selection
                newTags = [label];
            }
            if (stickyFilters) {
                sessionStorage.setItem(`${tableId}_selectedTags`, JSON.stringify(newTags));
            }
            return newTags;
        });
    };

    /**
     * Handler for table changes such as sorting and pagination.
     * Updates the sortField and sortOrder states and persists them if stickyFilters is enabled.
     */
    const handleTableChange = (pagination, filters, sorter) => {
        setSortField(sorter.field);
        setSortOrder(sorter.order);
        if (stickyFilters) {
            sessionStorage.setItem(`${tableId}_sortField`, JSON.stringify(sorter.field));
            sessionStorage.setItem(`${tableId}_sortOrder`, sorter.order);
        }
    };

    /**
     * Input filter element.
     * Renders a search input field for filtering table data.
     */
    const getInputFilter = () => (
        <Input
            className={styles.inputFilter}
            placeholder={`Search in ${filteredData.length} entries...`}
            value={inputFilter}
            onChange={handleInputChange}
        />
    );

    /**
     * Tag filter element.
     * Renders a list of tags for additional filtering.
     */
    const getTagFilter = () => (
        <div className={styles.tagFilters}>
            {tags.map((tag) => {
                let tagClasses = [styles.tag];
                if (selectedTags.includes(tag.label)) {
                    tagClasses.push(styles.active);
                }
                return (
                    <div
                        key={tag.label}
                        className={tagClasses.join(' ')}
                        onClick={() => handleTagClick(tag.label)}
                    >
                        {tag.label}
                    </div>
                );
            })}
        </div>
    );

    /**
     * Enhances columns with default sorters and "N/A" fallback.
     * If a column has a render function, it uses the rendered value for display and sorting.
     */
    const modifiedColumns = useMemo(() => {
        return columns.map(column => {
            const newColumn = { ...column };
            const originalRender = newColumn.render;

            // Disable sorting if sorter is explicitly set to false
            if (newColumn.sorter === false) {
                return newColumn;
            }

            newColumn.render = (text, record, index) => {
                const value = originalRender
                    ? originalRender(text, record, index)
                    : _.get(record, newColumn.dataIndex);
                return value == null
                    ? <span style={{ opacity: 0.2 }}>N/A</span>
                    : value;
            };

            // Default sorting if none provided
            if (newColumn.sorter === undefined) {
                newColumn.sorter = (a, b, sortOrder) => {
                    const aValue = getRenderText(newColumn.render, _.get(a, newColumn.dataIndex), a);
                    const bValue = getRenderText(newColumn.render, _.get(b, newColumn.dataIndex), b);

                    // Push null/undefined to bottom
                    if (aValue === 'N/A') return sortOrder === 'ascend' ? 1 : -1;
                    if (bValue === 'N/A') return sortOrder === 'ascend' ? -1 : 1;

                    // Compare as strings
                    return aValue.toLowerCase().trim().localeCompare(bValue.toLowerCase().trim());
                };
            }

            // Apply stored sort field/order if it matches this column
            if (
                (Array.isArray(sortField) && _.isEqual(newColumn.dataIndex, sortField)) ||
                newColumn.dataIndex === sortField
            ) {
                newColumn.sortOrder = sortOrder;
            }

            return newColumn;
        });
    }, [columns, sortField, sortOrder, getRenderText]);

    /**
     * CSV Export logic.
     * Converts the filtered data into CSV format and triggers a download.
     */
    const _triggerDownload = useCallback(() => {
        if (!exporting) return;
        const exportableColumns = columns.filter(col => !col.dontExport);
        let csv = [];
    
        // CSV headers
        let headers = exportableColumns.map(col => col.title);
        csv.push(headers.join(","));
    
        const exportType = exporting.type || 'formatted';
    
        filteredData.forEach(row => {
            let rowData = exportableColumns.map(col => {
                let value;
    
                // Use exportValue if available
                if (col.exportValue) {
                    value = col.exportValue(row);
                }
                // For 'formatted', use render if available
                else if (exportType === 'formatted' && col.render) {
                    value = col.render(_.get(row, col.dataIndex), row);
                    if (React.isValidElement(value)) {
                        value = ReactDOMServer.renderToStaticMarkup(value);
                    } else if (typeof value === 'object' && value !== null) {
                        value = JSON.stringify(value);
                    }
                }
                else {
                    value = _.get(row, col.dataIndex);
                }
    
                // CSV-escape
                return value != null
                    ? `"${String(value).replace(/"/g, '""').replace(/\n/g, ' ')}"`
                    : '""';
            });
            csv.push(rowData.join(","));
        });
    
        let csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
        let downloadLink = document.createElement("a");
        const filename = exporting.filename || `${tableId} ${moment().format("DD_MMM_YYYY")}.csv`;
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }, [exporting, columns, filteredData, tableId]);

    /**
     * Handle table height and scrollability.
     * Adjusts the table height based on the container size and filter elements.
     */
    useEffect(() => {
        const updateTableDimensions = () => {
            if (wrapperRef.current && filtersRef.current) {
                const wrapperWidth = wrapperRef.current.clientWidth;
                const wrapperHeight = wrapperRef.current.clientHeight;
                const filtersHeight = filtersRef.current.clientHeight;

                if (!scroll.y) {
                    setTableHeight(wrapperHeight - filtersHeight - 65);
                }
                setIsNonScrollable(scroll.x && wrapperWidth > scroll.x);
            }
        };

        updateTableDimensions();
        window.addEventListener('resize', updateTableDimensions);
        return () => window.removeEventListener('resize', updateTableDimensions);
    }, [scroll]);

    // If no explicit scroll.y, set a dynamic height for the table
    const tableScroll = scroll.y ? scroll : { ...scroll, y: tableHeight || 0 };

    /**
     * Render the filter/search area.
     * Includes input filter, tag filters, and export button as applicable.
     */
    const getFilters = () => {
        // If no search, no tags, no export, no button → no filters row
        if (!hasInputFilter && !tags && !exporting && !addNewButton) {
            return <div className={styles.filters} ref={filtersRef} style={{ margin: 0 }} />;
        }

        return (
            <div className={styles.filters} ref={filtersRef}>
                <div className="d-flex align-items-center">
                    {hasInputFilter && getInputFilter()}

                    {addNewButton && (
                        <div style={{ marginLeft: hasInputFilter ? 10 : 0 }}>
                            {addNewButton}
                        </div>
                    )}
                </div>

                {(tags || exporting) && (
                    <div className='d-flex'>
                        {tags && getTagFilter()}
                        {exporting && (
                            <Button
                                className="button blue"
                                icon={<Icon name="Download" color={'#fff'} size={18} />}
                                size="small"
                                onClick={_triggerDownload}
                                style={{ minWidth: '34px', marginLeft: '10px' }}
                            >
                                Export
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            {getFilters()}
            <Table
                className={classNames(
                    styles.table,
                    {
                        [styles.clickable]: !!onRow,
                        [styles.nonScrollable]: isNonScrollable
                    }
                )}
                columns={modifiedColumns}
                dataSource={filteredData}
                pagination={pagination}
                showSorterTooltip={false}
                onRow={onRow}
                scroll={tableScroll}
                onChange={handleTableChange}
                {...rest}
            />
        </div>
    );
};

// Define the expected prop types
AntTable.propTypes = {
    /** Unique ID for this table (used in stickyFilters) */
    tableId: PropTypes.string.isRequired,

    /** Table columns array */
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,

    /** The data array for the table */
    dataSource: PropTypes.array,

    /** Array of tags for additional filtering */
    tags: PropTypes.array,

    /** Whether to display a search input field */
    hasInputFilter: PropTypes.bool,

    /** Allow multiple tag selection if true */
    multiTagFilter: PropTypes.bool,

    /** Pagination config or false for none */
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

    /** Event handlers for table rows, e.g. onClick(row) */
    onRow: PropTypes.func,

    /** Table scroll options (x, y, etc.) */
    scroll: PropTypes.object,

    /** Optional exporting config: { filename, type: 'formatted'|'raw' } */
    exporting: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            filename: PropTypes.string,
            type: PropTypes.oneOf(['formatted', 'raw']),
        })
    ]),

    /** Use sessionStorage to persist filters/sorters */
    stickyFilters: PropTypes.bool,

    /**
     * Pass in your entire add-new button as a React node (e.g. <Button onClick={...}>Add Meter</Button>)
     * It will be placed next to the search bar if one exists, or alone if not.
     */
    addNewButton: PropTypes.node,
};

export default AntTable;
