import ActionTypes from "../constants";
import decode from "jwt-decode";
import _ from "lodash";

import GeneralUtils from "../utils/GeneralUtils";

/**
 * Actions related to generating tokens and fetching dashboard data. This class interfaces with the API
 * to retrieve or generate data and dispatches actions to update the application state via a Dispatcher.
 *
 * @class
 * @example
 * const dashboardActions = new DashboardsActions();
 * dashboardActions.generateToken({ userId: 'user123' });
 * dashboardActions.getDataWithToken('some-jwt-token');
 */
class DashboardsActions {
  /**
   * Generates a token by sending the provided token object to the API and dispatches an action
   * to store this token in the application's state management system upon successful retrieval.
   *
   * @param {Object} tokenObject - The object containing information required to generate the token.
   * @returns {Promise} - A promise that resolves with the generated token.
   */
  generateToken(tokenObject) {
    return GeneralUtils.post({
      url: "tokens/generate",
      object: tokenObject,
      actionType: ActionTypes.DASHBOARD_TOKEN_GENERATED,
      modifyResponse: (response) => _.get(response, "token"),
      failToast: "Error creating dashboard",
    });
  }

  /**
   * Fetches dashboard data for a specific token from the API and dispatches an action to store this data
   * in the application's state management system upon successful retrieval.
   *
   * @param {string} token - The token used to fetch dashboard data.
   * @returns {Promise} - A promise that resolves with the fetched dashboard data.
   */
  getDataWithToken(token) {
    return GeneralUtils.get({
      authorizationNeeded: false,
      url: `tokens/process?token=${token}`,
      actionType: ActionTypes.DASHBOARD_DATA_FETCHED,
      modifyResponse: (response) => {
        const decoded_token = decode(token);

        const building_id = _.get(decoded_token, "data.building_id");
        const dashboard_type = _.get(decoded_token, "data.type");
        const header_left = _.get(decoded_token, "data.custom.header_left");
        const header_right = _.get(decoded_token, "data.custom.header_right");
        const stacked_chart = _.get(decoded_token, "data.custom.stacked_chart");
        const meter_ids = _.get(decoded_token, "data.meter_ids");

        return {
          data: response,
          building_id,
          dashboard_type,
          header_left,
          header_right,
          stacked_chart,
          meter_ids,
        };
      },
      failToast: "Error fetching dashboard data",
    });
  }
}

const dashboardsActions = new DashboardsActions();
export default dashboardsActions;
