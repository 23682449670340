import React, { useState, useEffect } from 'react';
import { Dropdown, Avatar } from 'antd';
import styles from './UserMenu.module.scss';
import { NavLink } from "react-router-dom";
import GeneralUtils from '../../utils/GeneralUtils';
import OrganisationActions from '../../actions/organisationActions';
import UserStore from '../../stores/userStore';

const UserMenu = ({ user }) => {
    const [org, setOrg] = useState(null);
    const isSuperAdmin = UserStore.isSuper();

    useEffect(() => {

        const fetchOrg = async () => {
            if (user && !org) {
                try {
                    const org = await OrganisationActions.getOrganisation(user.fk_organisation_id);
                    setOrg(org);
                } catch (error) {
                    console.error('Failed to fetch organisation data: ', error.code, error.type)
                }
            }
        }

        fetchOrg();
    }, [user, org]);


    if (!user) {
        return null;
    }

    const items = [
        {
            key: '0',
            style: { pointerEvents: 'none' },
            label: (
                <div className={styles.mainInfo}>
                    <div className={styles.label}>Account</div>
                    <div className={styles.infoWrapper} >
                        <Avatar className={styles.avatar}>{GeneralUtils.getInitials(user.name)}</Avatar>
                        <div>
                            <div>{user.name}</div>
                            <div className={styles.email}>{user.mail}</div>
                            {org && <div className={styles.org}>{org.description}</div>}
                        </div>
                    </div>
                </div>
            )
        },
        { type: 'divider' },
        {
            key: '1',
            label: (<NavLink to="/manage-password" className={styles.menuItem}>
                Password Change
            </NavLink>)
        },
        isSuperAdmin ? {
            key: '2',
            label: (<NavLink to="/change-organisation" className={styles.menuItem}>
                Organisation Change
            </NavLink>)
        } : null,
        {
            key: '3',
            label: (<NavLink to="/login" className={styles.menuItem}>
                Logout
            </NavLink>)
        },
    ]

    return (
        <Dropdown menu={{ items }} trigger={['click']} className={styles.userMenu}>
            <div className={styles.wrapper} onClick={e => e.preventDefault()}>
                <Avatar size={36} style={{ background: 'linear-gradient(to right, #17a2b8 0%, #1caf9a 100%)', fontSize: '17px' }}>{GeneralUtils.getInitials(user.name)}</Avatar>
            </div>
        </Dropdown>
    );
};

export default UserMenu;