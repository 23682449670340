import React, { useEffect, useState } from 'react';
import styles from './ConsumptionAnalysis.module.scss';
import { useParams } from 'react-router-dom';

import ConsumptionAnalysisStore from '../../../stores/consumptionAnalysisStore';
import UserStore from '../../../stores/userStore';

import { DocumentTitle } from '../../../components/DocumentTitle';
import ConsumptionAnalysisOverview from './ConsumptionAnalysisOverview';
import ConsumptionAnalysisDetail from './ConsumptionAnalysisDetail';

const ConsumptionAnalysis = (props) => {
    const { meterId } = useParams();
    const [isLoggedIn] = useState(UserStore.loggedIn());

    useEffect(() => {
        return () => {
            ConsumptionAnalysisStore.clear();
        }
    }, []);


    return <div
        className={`${styles.consumptionAnalysis} ${!isLoggedIn && styles.outOfLogin} br-mainpanel br-profile-page floorplan-background`}
    >
        <DocumentTitle title="Out-of-Hours Energy Analysis" />
        <div className='br-container'>
            <div style={{ display: meterId ? 'none' : 'block' }}>
                <ConsumptionAnalysisOverview {...props} />
            </div>
            {meterId && <ConsumptionAnalysisDetail {...props} />}
        </div>
    </div>
};

export default ConsumptionAnalysis;
