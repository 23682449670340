import React, { Component } from 'react';
import queryString from 'query-string'
import moment from 'moment';
import './SignificantEnergyUsersReport.scss';
import { DocumentTitle } from '../../components/DocumentTitle'
import ReportActions from '../../actions/reportActions';
import ReportStore from '../../stores/reportStore';
import { toast } from 'react-toastify';
import _ from 'lodash';


class SignificantEnergyUsersReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Downloading...',
      sub_message: 'Your report is downloading and will be with you shortly.',
    };
    this._onChange = this._onChange.bind(this);
    this._generateCSV = this._generateCSV.bind(this);
  }
  _onChange() {
    const data = ReportStore.getSignificantEnergyUsersReportData();
    if (data && (Object.entries(data).length > 0)) {

      //Sort Keys
      let ordered_data = {};
      _(data).keys().sort().each(function (key) {
        ordered_data[key] = data[key];
      });

      toast.success(`Downloading File Now...`)
      this._generateCSV(ordered_data, 'Weekly_Significant_Energy_Users_Report');
      this.setState({ message: "Successfully Downloaded.", sub_message: 'Your report has downloaded, thank you for your custom.' })
    } else {
      toast.warn(`No data available for the timeframe of this report.`)
    }
  }
  _generateCSV(data, file_name) {
    let totals = [];

    let csv = 'DATE, DAY OF WEEK';
    //Create Header
    for (const [date, meters] of Object.entries(data)) {
      for (const [name, value] of Object.entries(meters)) {
        csv += (',' + name);
        totals.push({ firstValue: value, date: date, meter: name, value: 0 });
      }
      break;
    }

    csv += "\n";

    //Create Rows
    for (const [date, meters] of Object.entries(data)) {
      let modate = moment(date);
      csv += (modate.format("DD MMM YYYY"));
      csv += (',' + modate.format("dddd"));
      for (const [name, value] of Object.entries(meters)) {
        csv += (',' + Math.round(value));

        for (let i = 0; i < totals.length; i++) {
          if (totals[i].meter === name) {
            totals[i].value = totals[i].value + Math.round(value);
            break;
          }
        }

      }
      csv += "\n";
    }

    csv += ",TOTAL";

    for (let i = 0; i < totals.length; i++) {
      csv += ("," + totals[i].value);
    }
    csv += "\n";

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, file_name);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", file_name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  UNSAFE_componentWillMount() {
    ReportStore.addChangeListener(this._onChange);
    const values = queryString.parse(this.props.location.search)
    if (values && values.ts_start && values.ts_end) {
      try {
        if (this.props.match.params.organisation_id && !isNaN(values.ts_start) && !isNaN(values.ts_end)) {
          ReportActions.getSignificantEnergyUsersDownload(this.props.match.params.organisation_id, Number.parseInt(values.ts_start), Number.parseInt(values.ts_end));
        }
      } catch (err) {
        console.error("WARN: Could not parse target dates in url, please contact support")
      }
    }
  }
  componentWillUnmount() {
    ReportStore.removeChangeListener(this._onChange);
  }
  render() {
    let isMobileDevice = window.matchMedia("only screen and (max-width: 760px)").matches;
    if (isMobileDevice) {
      return (
        <div className="ht-100v align-items-center justify-content-center">
          <DocumentTitle title='Report Download' />
          <div className="tx-center pd-x-40" style={{ width: '100%' }}>
            <h1 className="tx-50 tx-normal tx-inverse tx-roboto mg-b-20 mg-t-50">{this.state.message}</h1>
            <h5 className="tx-xs-24 tx-normal tx-info mg-b-30 lh-5">{this.state.sub_message}</h5>
            <p className="tx-16 mg-b-30">If the file doesn't appear as a download in your browser please refresh the page and try again. We hope you find this service useful and we thank you for your custom. If you have any queries in relation to the report, its data and/or format please feel free to reach out to one of the OPNBuildings team.
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="ht-100v d-flex align-items-center justify-content-center">
          <DocumentTitle title='Report Download' />
          <div className="wd-lg-70p wd-xl-50p tx-center pd-x-40">
            <h1 className="tx-100 tx-xs-140 tx-normal tx-inverse tx-roboto mg-b-0">{this.state.message}</h1>
            <h5 className="tx-xs-24 tx-normal tx-info mg-b-30 lh-5">{this.state.sub_message}</h5>
            <p className="tx-16 mg-b-30">If the file doesn't appear as a download in your browser please refresh the page and try again. We hope you find this service useful and we thank you for your custom. If you have any queries in relation to the report, its data and/or format please feel free to reach out to one of the OPNBuildings team.
            </p>

          </div>
        </div>
      );
    }

  }
}

export default SignificantEnergyUsersReport;
