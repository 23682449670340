// src/pages/ManageMeters/ManageMeters.jsx
import React, { useState, useEffect } from "react";
import { Button, Tag } from "antd";
import "./ManageMeters.scss";
import { AntTable } from "../../components/AntTable";
import { DocumentTitle } from "../../components/DocumentTitle";
import UserStore from "../../stores/userStore";
import MeterActions from "../../actions/meterActions";
import BuildingActions from "../../actions/buildingActions";
import MeterModal from "./MeterModal";
import { Icon } from "../../components/Icon";
import ButtonCell from "../../components/Table/TableCells/ButtonCell/ButtonCell";
import Constants from "../../constants";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

/**
 * ManageMeters Component
 *
 * Provides an interface for administrators to manage meters within the system.
 */
const ManageMeters = () => {
  const [meters, setMeters] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [meterToEdit, setMeterToEdit] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [meterToDelete, setMeterToDelete] = useState(null);

  /**
   * Fetch all meters when the component mounts.
   */
  useEffect(() => {
    const fetchMeters = async () => {
      try {
        setLoading(true);
        const data = await MeterActions.v2GetAll();
        setMeters(data || []);
      } catch (error) {
        console.error("Error fetching meters:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchMeters();
  }, []);

  /**
   * Fetch all buildings when the component mounts.
   */
  useEffect(() => {
    const fetchBuildings = async () => {
      try {
        const data = await BuildingActions.getBuildings();
        setBuildings(data || []);
      } catch (error) {
        console.error("Error fetching buildings:", error);
      }
    };
    fetchBuildings();
  }, []);

  /**
   * Set super user status on mount.
   */
  useEffect(() => {
    setIsSuperUser(UserStore.isSuper());
  }, []);

  /**
   * Open the MeterModal.
   */
  const openModal = (meterItem = null) => {
    setMeterToEdit(meterItem);
    setModalVisible(true);
  };

  /**
   * Close the MeterModal.
   */
  const closeModal = () => {
    setModalVisible(false);
    setMeterToEdit(null);
  };

  /**
   * Handle submission from the MeterModal.
   */
  const handleMeterSubmit = async (payload) => {
    try {
      if (payload.meter_id) {
        // Update existing meter
        const updated = await MeterActions.v2UpdateMeter(payload.meter_id, payload);
        setMeters((prev) =>
          prev.map((m) => (m.meter.id === updated.meter.id ? updated : m))
        );
      } else {
        // Create new meter
        const created = await MeterActions.v2SaveMeter(payload);
        setMeters((prev) => [...prev, created]);
      }
      closeModal();
    } catch (err) {
      console.error("Error saving/updating meter:", err);
    }
  };

  /**
   * Handle deletion of a meter by opening the confirmation modal.
   */
  const handleDeleteMeter = (meter) => {
    setMeterToDelete(meter);
    setIsDeleteModalVisible(true);
  };

  /**
   * Confirm deletion of the selected meter.
   */
  const handleDeleteConfirm = async () => {
    if (!meterToDelete) return;
    try {
      await MeterActions.v2DeleteMeter(meterToDelete.meter.id);
      setMeters((prev) => prev.filter((m) => m.meter.id !== meterToDelete.meter.id));
    } catch (error) {
      console.error("Error deleting meter:", error);
    } finally {
      setIsDeleteModalVisible(false);
      setMeterToDelete(null);
    }
  };

  /**
   * Cancel deletion by closing the confirmation modal.
   */
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setMeterToDelete(null);
  };

  /**
   * Computes the combined meter type string and wraps "Virtual" in an Ant Design Tag if applicable.
   */
  const computeMeterType = (record) => {
    const { type, main, virtual_calc } = record.meter;

    let combinedType = Constants.METER_TYPES[type]?.label || type;

    if (main) {
      combinedType = `Main ${combinedType}`;
    }

    if (virtual_calc) {
      return (
        <span>
          {combinedType} <Tag color="purple" style={{ marginLeft: 8 }}>Virtual</Tag>
        </span>
      );
    }

    return combinedType;
  };

  /**
   * Computes the combined meter type string for exporting.
   */
  const exportMeterType = (record) => {
    const { type, main, virtual_calc } = record.meter;
    let combinedType = Constants.METER_TYPES[type]?.label || type;
    if (main) {
      combinedType = `Main ${combinedType}`;
    }
    if (virtual_calc) {
      combinedType += ' Virtual';
    }
    return combinedType;
  };

  /**
   * Define the columns for the AntTable displaying meters.
   */
  const columns = [
    {
      title: "Building",
      dataIndex: ["building", "name"],
      key: "buildingName",
      defaultSortOrder: 'ascend',
      default: true,
      filterable: true,
    },
    {
      title: "Name",
      dataIndex: ["meter", "name"],
      key: "name",
      filterable: true,
    },
    {
      title: "Type",
      dataIndex: ["meter", "type"],
      key: "type",
      filterable: true,
      exportValue: (record) => exportMeterType(record),
      render: (type, record) => computeMeterType(record),
    },
    {
      title: "Parent",
      dataIndex: ["meter", "parent_id"],
      key: "parentId",
      render: (parent_id) => {
        const parentMeter = meters.find((m) => m.meter.id === parent_id);
        return parentMeter ? parentMeter.meter.name : "";
      },
    },
    {
      title: "Conversion Rate",
      dataIndex: ["meter", "conversion_rate"],
      key: "conversionRate",
    },
    {
      title: "Actions",
      key: "actions",
      width: 140,
      dontExport: true,
      sorter: false,
      render: (_, record) => (
        <ButtonCell
          wrapper="div"
          key={record.meter.id}
          buttons={[
            {
              label: "Edit Meter",
              icon: "Edit",
              iconColor: "#cc7832",
              clickHandler: () => openModal(record),
            },
            {
              label: "Delete Meter",
              icon: "Cross",
              iconColor: "#d04648",
              clickHandler: () => handleDeleteMeter(record),
            },
          ]}
        />
      ),
    },
  ];

  const tags = [
    {
      label: "All",
      condition: function (record) {
        return !!record
      },
      default: true,
    },
    {
      label: "Electric",
      condition: function (record) {
        return record.meter.type && record.meter.type.includes("ELECTRIC")
      },
    },
    {
      label: "Gas",
      condition: function (record) {
        return record.meter.type && record.meter.type.includes("GAS")
      },
    },
    {
      label: "Water",
      condition: function (record) {
        return record.meter.type && record.meter.type.includes("WATER")
      },
    },
    {
      label: "Heat",
      condition: function (record) {
        return record.meter.type && record.meter.type.includes("HEAT")
      },
    },
    {
      label: "Nitrogen",
      condition: function (record) {
        return record.meter.type && record.meter.type.includes("NITROGEN")
      },
    },
    {
      label: "Steam",
      condition: function (record) {
        return record.meter.type && record.meter.type.includes("STEAM")
      },
    },
  ]

  return (
    <div className="manage-meters-container br-mainpanel br-profile-page floorplan-background">
      <DocumentTitle title="Manage Meters" />

      <div className="br-container">
        <div className="mg-t-30 tableWrapper">
          {/* AntTable component to display meters */}
          <AntTable
            tableId="ManageMetersTable"
            virtual
            exporting
            tags={tags}
            columns={columns}
            dataSource={meters}
            loading={loading}
            rowKey={(record) => record?.meter?.id}
            pagination={false}
            scroll={{ x: 1000 }}
            hasInputFilter={true}
            multiTagFilter={true}
            onRow={(record) => ({
              onClick: () => openModal(record)
            })}
            addNewButton={
              <Button className="button green" onClick={() => openModal()}>
                <Icon name="AddCircleFilled" />
                Add Meter
              </Button>
            }
          />
        </div>
      </div>

      {/* MeterModal component for adding/editing meters */}
      <MeterModal
        isSuperUser={isSuperUser}
        open={modalVisible}
        onCancel={closeModal}
        onSubmit={handleMeterSubmit}
        meter={meterToEdit}
        buildings={buildings}
        allMeters={meters}
      />

      {/* DeleteConfirmationModal component for confirming deletions */}
      <DeleteConfirmationModal
        open={isDeleteModalVisible}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        itemName={meterToDelete ? meterToDelete.meter.name : ""}
      />
    </div>
  );
};

export default ManageMeters;
