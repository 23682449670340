import React, { useState, useCallback, useEffect } from 'react';
import API from "../../api"
import './GeolocationAutocomplete.scss'
import _ from 'lodash'

const GeolocationAutocomplete = (props) => {

    // Props

    const { initialValue, onSuggestSelect, className } = props;

    // State

    const [suggestions, setSuggestions] = useState([]);
    const [query, setQuery] = useState(initialValue);
    const [isListOpen, setListOpen] = useState(false)

    const fetchSuggestions = useCallback((query) => {
        API.get("geocode/?q=" + query).then((response) => {
            if (typeof (response.code) === 'undefined') {
                if (response != null) {
                    setListOpen(true)
                    setSuggestions(response.items);
                }
            }
        });
    }, []);  // Empty dependency array since API and setState are stable

    //Create debounced version of fetchSuggestions
    //eslint-disable-next-line
    const delayedFetch = useCallback(_.debounce(fetchSuggestions, 2000), [fetchSuggestions])

    useEffect(() => {
        setQuery(initialValue);
    }, [initialValue]);

    useEffect(() => {
        // Cleanup function to cancel pending debounced calls
        return () => {
            delayedFetch.cancel();
        };
    }, [delayedFetch]);

    // Handlers

    const queryChangeHandler = (query) => {
        setQuery(query)
        if (query.length > 4) {
            delayedFetch(query)
        }
    }

    const suggestionClickHandler = (suggestion) => {
        setQuery(suggestion.address.label);
        setListOpen(false)
        onSuggestSelect(suggestion)
    }

    // Rendering functions

    const getSuggestions = () => {
        let suggestionList = null;

        if (query && query.length < 5) {
            suggestionList =
                <ul className='suggestions'>
                    <li key={query}>Enter more characters to get suggestions...</li>
                </ul>
        }

        if (isListOpen && suggestions && suggestions.length > 0) {
            suggestionList =
                (<ul className='suggestions'>
                    {suggestions.map(suggestion => {
                        return <li onClick={() => suggestionClickHandler(suggestion)} key={suggestion.id}>{suggestion.address.label}</li>
                    })}
                </ul>)
        }

        return suggestionList;
    }


    return (
        <>
            <input
                placeholder="Address"
                type="text"
                className={className}
                value={query}
                onChange={(e) => queryChangeHandler(e.target.value)}
                onClick={() => setListOpen(!isListOpen)}
            />
            {getSuggestions()}
        </>
    );
};

export default GeolocationAutocomplete;