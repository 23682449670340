import React, { Component } from "react";
import "./AddBuilding.scss";
import _ from "lodash";
import OrganisationActions from "../../../actions/organisationActions";
import OrganisationStore from "../../../stores/organisationStore";
import UserStore from "../../../stores/userStore";
import BuildingStore from "../../../stores/buildingStore";
import BuildingActions from "../../../actions/buildingActions";
import GeneralUtils from "../../../utils/GeneralUtils";
import moment from "moment";

import { toast } from "react-toastify";


import { DocumentTitle } from "../../../components/DocumentTitle";
import { GeolocationAutocomplete } from "../../../components/GeolocationAutocomplete";
import Constants from "../../../constants";
import { Modal } from "../../../components/Modal";
import { Icon } from "../../../components/Icon";
import { Row, Col, Card, Typography } from "antd";
import { LogoSpinner } from "../../../components/LogoSpinner";

const { Title, Text } = Typography;

class AddBuilding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisation: OrganisationStore.getOrganisation(),
      estates: OrganisationStore.getEstates(),
      new_building: BuildingStore.getNewBuilding(),
      time_segments: [
        { day: 1, start: "08:00:00", end: "18:00:00" },
        { day: 2, start: "08:00:00", end: "18:00:00" },
        { day: 3, start: "08:00:00", end: "18:00:00" },
        { day: 4, start: "08:00:00", end: "18:00:00" },
        { day: 5, start: "08:00:00", end: "18:00:00" },
      ],
      loading: true,

      // For New Energy Targets
      energy_target_modal_open: false,
      new_target_year: "",
      new_baseline_year: "",
      new_gas_reduction: "",
      new_electricity_reduction: "",
    };
    this._saveBuilding = this._saveBuilding.bind(this);
    this._validateInputs = this._validateInputs.bind(this);
    this._onBuildingSaved = this._onBuildingSaved.bind(this);
    this._onOrganisationChange = this._onOrganisationChange.bind(this);
    this._changeBuildingValues = this._changeBuildingValues.bind(this);
    this._changeAvailableFeatures = this._changeAvailableFeatures.bind(this);
    this._changeBuildingAddress = this._changeBuildingAddress.bind(this);
    this._changeTimeSegment = this._changeTimeSegment.bind(this);
    this._removeTimeSegment = this._removeTimeSegment.bind(this);
    this._addTimeSegment = this._addTimeSegment.bind(this);
  }
  _onOrganisationChange() {
    this.setState({
      organisation: OrganisationStore.getOrganisation(),
      estates: OrganisationStore.getEstates(),
      loading: false,
    });
  }
  _onBuildingSaved() {
    BuildingStore.clear();
    let route = "/manage-buildings";
    this.props.history.push(route);
  }
  componentDidMount() {
    let current_user = UserStore.getUser();
    if (UserStore.isSuper()) {
      OrganisationActions.getOrganisations(false);
    } else if (current_user && current_user.fk_organisation_id) {
      OrganisationActions.getOrganisation(current_user.fk_organisation_id);
    }
  }
  UNSAFE_componentWillMount() {
    BuildingStore.addSaveListener(this._onBuildingSaved);
    OrganisationStore.addChangeListener(this._onOrganisationChange);
  }
  componentWillUnmount() {
    BuildingStore.clear();
    BuildingStore.removeSaveListener(this._onBuildingSaved);
    OrganisationStore.removeChangeListener(this._onOrganisationChange);
  }
  _validateInputs(building) {
    let validEstate = parseInt(building.fk_estate_id) > 0;
    let validName = GeneralUtils.stringNotEmpty(building.name);
    let validDescription = GeneralUtils.stringNotEmpty(building.description);
    let validCategory = GeneralUtils.stringNotEmpty(building.category);
    let validSize = parseFloat(building.size) > 0;
    let validAddress = GeneralUtils.stringNotEmpty(
      building.address.address_line
    );
    let validElectricRate = parseFloat(building.effective_elec_rate) > 0;
    let validGasRate = parseFloat(building.effective_gas_rate) > 0;
    let validElectricCarbon = parseFloat(building.effective_elec_carbon) > 0;
    let validGasCarbon = parseFloat(building.effective_gas_carbon) > 0;

    return (
      validEstate &&
      validName &&
      validDescription &&
      validCategory &&
      validSize &&
      validAddress &&
      validElectricRate &&
      validGasRate &&
      validElectricCarbon &&
      validGasCarbon
    );
  }

  _saveBuilding() {
    let valid_time_segments = [];
    for (let i = 0; i < this.state.time_segments.length; i++) {
      let segment = this.state.time_segments[i];
      if (
        segment.day > 0 &&
        segment.start.length > 0 &&
        segment.end.length > 0
      ) {
        valid_time_segments.push(segment);
      }
    }

    if (!this._validateInputs(this.state.new_building)) {
      toast.warn('Invalid, please ensure all mandatory fields are selected.')
    } else if (valid_time_segments.length === 0) {
      toast.warn("Building must have at least one valid opening and closing time set.")
    } else {
      let building_request = this.state.new_building;
      building_request.opening_times = this.state.time_segments;
      BuildingActions.saveBuilding(building_request);
    }
  }
  _changeTimeSegment(type, index, event) {
    let time_segments = this.state.time_segments;
    for (let i = 0; i < time_segments.length; i++) {
      if (i === index) {
        if (
          type === "start" &&
          time_segments[i].end.length > 0 &&
          moment(event.target.value, "H:mm:ss").isAfter(
            moment(time_segments[i].end, "H:mm:ss")
          )
        ) {
          toast.warn("Opening can't be after closing, please select a valid time.")
        } else if (
          type === "end" &&
          time_segments[i].start.length > 0 &&
          moment(event.target.value, "H:mm:ss").isBefore(
            moment(time_segments[i].start, "H:mm:ss")
          )
        ) {
          toast.warn("Closing can't be before opening, please select a valid time.")
        } else {
          if (type === "day") {
            time_segments[i][type] = Number.parseInt(event.target.value);
          } else {
            time_segments[i][type] = event.target.value;
          }
        }
      }
    }
    this.setState({ time_segments: time_segments });
  }
  _addTimeSegment() {
    let time_segments = this.state.time_segments;
    time_segments.push({ day: 0, start: "", end: "" });
    this.setState({ time_segments: time_segments });
  }
  _removeTimeSegment(index) {
    let time_segments = this.state.time_segments;
    time_segments.splice(index, 1);
    this.setState({ time_segments: time_segments });
  }
  _changeBuildingValues(target_property, event) {
    let building = this.state.new_building;
    building[target_property] = event.target.value;
    this.setState({ new_building: building });
  }
  _changeAvailableFeatures(feat) {
    let new_building = this.state.new_building;
    let array = new_building.available_features.split(":");

    if (array.includes(feat)) {
      array = array.filter((el) => el !== feat);
    } else {
      array = [...array, feat];
    }

    let string = array.join(":");
    if (string[0] === ":") {
      string = string.slice(1);
    }

    new_building.available_features = string;
    this.setState({ new_building: new_building });
  }
  _changeBuildingAddress(suggest) {
    if (
      suggest !== null &&
      suggest.address !== null &&
      suggest.position !== null &&
      suggest !== "undefined" &&
      suggest.address !== "undefined" &&
      suggest.position !== "undefined"
    ) {
      let building = this.state.new_building;
      building.address.address_line = suggest.address.label;
      building.address.latitude = suggest.position.lat;
      building.address.longitude = suggest.position.lng;
      building.address.city = suggest.address.city;
      building.address.region =
        suggest.address.county || suggest.address.district;
      building.address.country = suggest.address.countryName;
      this.setState({ new_building: building });
    }
  }
  getForm() {
    let estates = OrganisationStore.getEstates();
    return (
      <div className="form-layout form-layout-1 pd-y-15">
        <div className="row mg-b-5">
          <div className="col-12 col-sm-4">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Building Name: <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="Enter building name"
                type="text"
                className="form-control"
                value={this.state.new_building.name}
                onChange={this._changeBuildingValues.bind(this, "name")}
              />
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Description: <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="Enter building description"
                type="text"
                className="form-control"
                value={this.state.new_building.description}
                onChange={this._changeBuildingValues.bind(this, "description")}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Address: <span className="tx-danger">*</span>
              </label>
              <GeolocationAutocomplete
                initialValue={
                  this.state.new_building.address.address_line || ""
                }
                country="ie"
                onSuggestSelect={this._changeBuildingAddress}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Building Category: <span className="tx-danger">*</span>
              </label>
              <select
                onChange={this._changeBuildingValues.bind(this, "category")}
                value={this.state.new_building.category}
                className="form-control"
              >
                <option value={""} disabled>
                  Select Category
                </option>
                <option value={"office"}>Office</option>
                <option value={"lecture hall"}>Lecture Hall</option>
                <option value={"educational"}>Educational</option>
                <option value={"hospital"}>Hospital</option>
                <option value={"hotel"}>Hotel</option>
                <option value={"restaurant"}>Restaurant</option>
                <option value={"wholesale"}>Wholesale</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Estate: <span className="tx-danger">*</span>
              </label>
              <select
                onChange={this._changeBuildingValues.bind(this, "fk_estate_id")}
                value={this.state.new_building.fk_estate_id}
                className="form-control"
              >
                <option value={0} disabled>
                  Select Estate
                </option>
                {estates.map(function (estate, key) {
                  return (
                    <option key={key} value={estate.estate_id}>
                      {estate.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Approx Size (m²): <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="Size (Sq Meters)"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.size}
                onChange={this._changeBuildingValues.bind(this, "size")}
              />
            </div>
          </div>
          <div className="col-12 col-md-3 col-xl-2">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">Approx Cost (€/m²):</label>
              <input
                placeholder="Cost Per Sq/M"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.square_meter_cost}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "square_meter_cost"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-3 col-xl-1">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">Currency:</label>
              <select
                onChange={this._changeBuildingValues.bind(this, "currency")}
                value={this.state.new_building.currency}
                className="form-control"
              >
                <option value={0} disabled>
                  Select Currency
                </option>
                {Constants.CURRENCIES.map((currency) => {
                  return (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Electric Rate (€/kwh): <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="€/kwh"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.effective_elec_rate}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "effective_elec_rate"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Electric Carbon Rate (kgCo2e/kwh):{" "}
                <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="kgCo2e/kwh"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.effective_elec_carbon}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "effective_elec_carbon"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Gas Rate (€/kwh): <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="€/kwh"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.effective_gas_rate}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "effective_gas_rate"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Gas Carbon Rate (kgCo2e/kwh):{" "}
                <span className="tx-danger">*</span>
              </label>
              <input
                placeholder="kgCo2e/kwh"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.effective_gas_carbon}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "effective_gas_carbon"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">Water Rate (€/ltr):</label>
              <input
                placeholder="€/ltr"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.effective_water_rate}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "effective_water_rate"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Water Carbon Rate (kgCo2e/ltr):
              </label>
              <input
                placeholder="kgCo2e/ltr"
                type="number"
                className="form-control"
                defaultValue={this.state.new_building.effective_water_carbon}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "effective_water_carbon"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                HDD Base Temperature (&deg;C):
              </label>
              <input
                placeholder="&deg;C"
                type="number"
                className="form-control"
                value={this.state.new_building.hdd_base_temp}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "hdd_base_temp"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                CDD Base Temperature (&deg;C):
              </label>
              <input
                placeholder="&deg;C"
                type="number"
                className="form-control"
                value={this.state.new_building.cdd_base_temp}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "cdd_base_temp"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Humidification Base Enthalpy (&deg;C):
              </label>
              <input
                placeholder="&deg;C"
                type="number"
                className="form-control"
                value={this.state.new_building.humidification_base_enthalpy}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "humidification_base_enthalpy"
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">
                Dehumidification Base Enthalpy (&deg;C):
              </label>
              <input
                placeholder="&deg;C"
                type="number"
                className="form-control"
                value={this.state.new_building.dehumidification_base_enthalpy}
                onChange={this._changeBuildingValues.bind(
                  this,
                  "dehumidification_base_enthalpy"
                )}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group" style={{ marginBottom: "15px" }}>
              <label className="form-control-label">Available Features:</label>
            </div>
          </div>
          {Constants.AVAILABLE_FEATURES.map((feat) => {
            return (
              <div className="col-12 col-md-6 col-xl-2" key={feat}>
                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <input
                    checked={
                      this.state.new_building.available_features.indexOf(feat) >
                      -1
                    }
                    className="form-group-input"
                    type="checkbox"
                    onChange={this._changeAvailableFeatures.bind(this, feat)}
                  />
                  <label
                    style={{ marginLeft: "10px" }}
                    className="form-group-label"
                  >
                    {_.capitalize(feat)}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        {this.getTimeSegmentForm()}
        {this.getEnergyTargetsForm()}
        <div className="form-layout-footer">
          <button className="btn btn-info wd-100p" onClick={this._saveBuilding}>
            Save Building
          </button>
        </div>
      </div>
    );
  }
  getTimeSegmentForm() {
    return (
      <div className="row mg-b-5">
        <h6
          style={{
            borderBottom: "1px dashed #ced4d9",
            width: "100%",
            margin: "15px",
            paddingBottom: "10px",
          }}
        >
          Building Opening Hours
        </h6>
        <span
          onClick={this._addTimeSegment.bind(this)}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "40px",
            marginTop: "8px",
          }}
          className="btn btn-icon btn-outline-info tx-12 pd-l-5 pd-r-5 pd-t-5 pd-b-5"
        >
          Add Additional Hours
        </span>
        {this.state.time_segments.map((time_segment, key) => {
          return (
            <div key={key} className="col-12">
              <div className="form-group" style={{ marginBottom: "15px" }}>
                <div className="row">
                  <div className="col-4 col-sm-5">
                    <select
                      onChange={this._changeTimeSegment.bind(this, "day", key)}
                      value={time_segment.day}
                      className="inline-single-input ht-30"
                    >
                      <option value={0} disabled>
                        Select Day Of Week
                      </option>
                      <option value={1}>Monday</option>
                      <option value={2}>Tuesday</option>
                      <option value={3}>Wednesday</option>
                      <option value={4}>Thursday</option>
                      <option value={5}>Friday</option>
                      <option value={6}>Saturday</option>
                      <option value={7}>Sunday</option>
                    </select>
                  </div>
                  <div className="col-3 col-sm-3">
                    <select
                      onChange={this._changeTimeSegment.bind(
                        this,
                        "start",
                        key
                      )}
                      value={time_segment.start}
                      className="inline-single-input ht-30"
                    >
                      <option value={""} disabled>
                        Select Open Time
                      </option>
                      <option value={"00:00:00"}>00:00</option>
                      <option value={"00:30:00"}>00:30</option>
                      <option value={"01:00:00"}>01:00</option>
                      <option value={"01:30:00"}>01:30</option>
                      <option value={"02:00:00"}>02:00</option>
                      <option value={"02:30:00"}>02:30</option>
                      <option value={"03:00:00"}>03:00</option>
                      <option value={"03:30:00"}>03:30</option>
                      <option value={"04:00:00"}>04:00</option>
                      <option value={"04:30:00"}>04:30</option>
                      <option value={"05:00:00"}>05:00</option>
                      <option value={"05:30:00"}>05:30</option>
                      <option value={"06:00:00"}>06:00</option>
                      <option value={"06:30:00"}>06:30</option>
                      <option value={"07:00:00"}>07:00</option>
                      <option value={"07:30:00"}>07:30</option>
                      <option value={"08:00:00"}>08:00</option>
                      <option value={"08:30:00"}>08:30</option>
                      <option value={"09:00:00"}>09:00</option>
                      <option value={"09:30:00"}>09:30</option>
                      <option value={"10:00:00"}>10:00</option>
                      <option value={"10:30:00"}>10:30</option>
                      <option value={"11:00:00"}>11:00</option>
                      <option value={"11:30:00"}>11:30</option>
                      <option value={"12:00:00"}>12:00</option>
                      <option value={"12:30:00"}>12:30</option>
                      <option value={"13:00:00"}>13:00</option>
                      <option value={"13:30:00"}>13:30</option>
                      <option value={"14:00:00"}>14:00</option>
                      <option value={"14:30:00"}>14:30</option>
                      <option value={"15:00:00"}>15:00</option>
                      <option value={"15:30:00"}>15:30</option>
                      <option value={"16:00:00"}>16:00</option>
                      <option value={"16:30:00"}>16:30</option>
                      <option value={"17:00:00"}>17:00</option>
                      <option value={"17:30:00"}>17:30</option>
                      <option value={"18:00:00"}>18:00</option>
                      <option value={"18:30:00"}>18:30</option>
                      <option value={"19:00:00"}>19:00</option>
                      <option value={"19:30:00"}>19:30</option>
                      <option value={"20:00:00"}>20:00</option>
                      <option value={"20:30:00"}>20:30</option>
                      <option value={"21:00:00"}>21:00</option>
                      <option value={"21:30:00"}>21:30</option>
                      <option value={"22:00:00"}>22:00</option>
                      <option value={"22:30:00"}>22:30</option>
                      <option value={"23:00:00"}>23:00</option>
                      <option value={"23:30:00"}>23:30</option>
                      <option value={"23:59:00"}>23:59</option>
                    </select>
                  </div>
                  <div className="col-3 col-sm-3">
                    <select
                      onChange={this._changeTimeSegment.bind(this, "end", key)}
                      value={time_segment.end}
                      className="inline-single-input ht-30"
                    >
                      <option value={""} disabled>
                        Select Close Time
                      </option>
                      <option value={"00:30:00"}>00:30</option>
                      <option value={"01:00:00"}>01:00</option>
                      <option value={"01:30:00"}>01:30</option>
                      <option value={"02:00:00"}>02:00</option>
                      <option value={"02:30:00"}>02:30</option>
                      <option value={"03:00:00"}>03:00</option>
                      <option value={"03:30:00"}>03:30</option>
                      <option value={"04:00:00"}>04:00</option>
                      <option value={"04:30:00"}>04:30</option>
                      <option value={"05:00:00"}>05:00</option>
                      <option value={"05:30:00"}>05:30</option>
                      <option value={"06:00:00"}>06:00</option>
                      <option value={"06:30:00"}>06:30</option>
                      <option value={"07:00:00"}>07:00</option>
                      <option value={"07:30:00"}>07:30</option>
                      <option value={"08:00:00"}>08:00</option>
                      <option value={"08:30:00"}>08:30</option>
                      <option value={"09:00:00"}>09:00</option>
                      <option value={"09:30:00"}>09:30</option>
                      <option value={"10:00:00"}>10:00</option>
                      <option value={"10:30:00"}>10:30</option>
                      <option value={"11:00:00"}>11:00</option>
                      <option value={"11:30:00"}>11:30</option>
                      <option value={"12:00:00"}>12:00</option>
                      <option value={"12:30:00"}>12:30</option>
                      <option value={"13:00:00"}>13:00</option>
                      <option value={"13:30:00"}>13:30</option>
                      <option value={"14:00:00"}>14:00</option>
                      <option value={"14:30:00"}>14:30</option>
                      <option value={"15:00:00"}>15:00</option>
                      <option value={"15:30:00"}>15:30</option>
                      <option value={"16:00:00"}>16:00</option>
                      <option value={"16:30:00"}>16:30</option>
                      <option value={"17:00:00"}>17:00</option>
                      <option value={"17:30:00"}>17:30</option>
                      <option value={"18:00:00"}>18:00</option>
                      <option value={"18:30:00"}>18:30</option>
                      <option value={"19:00:00"}>19:00</option>
                      <option value={"19:30:00"}>19:30</option>
                      <option value={"20:00:00"}>20:00</option>
                      <option value={"20:30:00"}>20:30</option>
                      <option value={"21:00:00"}>21:00</option>
                      <option value={"21:30:00"}>21:30</option>
                      <option value={"22:00:00"}>22:00</option>
                      <option value={"22:30:00"}>22:30</option>
                      <option value={"23:00:00"}>23:00</option>
                      <option value={"23:30:00"}>23:30</option>
                      <option value={"23:59:00"}>23:59</option>
                    </select>
                  </div>
                  <div className="col-2 col-sm-1  tx-right">
                    <span
                      onClick={this._removeTimeSegment.bind(this, key)}
                      style={{ cursor: "pointer" }}
                      className="btn btn-icon btn-outline-danger tx-12 pd-l-5 pd-r-5 pd-t-5 pd-b-5"
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  getEnergyTargetsForm() {
    return (
      <div className="row mg-b-5">
        <h6
          style={{
            borderBottom: "1px dashed #ced4d9",
            width: "100%",
            margin: "15px",
            paddingBottom: "10px",
          }}
        >
          Building Energy Targets
        </h6>
        <span
          onClick={() => this.setState({ energy_target_modal_open: true })}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "40px",
            marginTop: "8px",
          }}
          className="btn btn-icon btn-outline-info tx-12 pd-l-5 pd-r-5 pd-t-5 pd-b-5"
        >
          Add Energy Target
        </span>
        <div className="col-12">
          <div className="form-group" style={{ marginBottom: "15px" }}>
            <div className="row target-container">
              {_.orderBy(
                this.state.new_building.energy_targets,
                ["target_year"],
                ["desc"]
              ).map((target, key) => {
                return (
                  <div key={key} className="target-card">
                    <div
                      className="target-delete"
                      onClick={() => this.removeEnergyTarget(target, key)}
                    >
                      <Icon name='Delete' size={30} color='red' />
                    </div>
                    {target.new_addition && (
                      <div className="target-unsaved">
                        <div>Unsaved</div>
                        <div>Change</div>
                      </div>
                    )}

                    <div className="target-year">{target.target_year}</div>
                    <div className="baseline-year">
                      {target.baseline_year
                        ? `Using ${target.baseline_year} as baseline.`
                        : "Using rolling baseline."}
                    </div>
                    <div className="reductions">
                      <div className="electricity-reductions">
                        <Icon name='Electricity' size={30} color='orange' />
                        <div className="value">
                          {target.electricity_reduction}
                          <span>%</span>
                        </div>

                        <div className="label">Electricity</div>
                        <div className="label">Reduction</div>
                      </div>
                      <div className="gas-reductions">
                        <Icon name='Flame' size={30} color='red' />
                        <div className="value">
                          {target.gas_reduction}
                          <span>%</span>
                        </div>

                        <div className="label">Gas</div>
                        <div className="label">Reduction</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getModal() {
    if (this.state.energy_target_modal_open === false) return null;

    const checkTargetYear = (year) => {
      if (!year) return true;
      if (this.state.new_baseline_year && this.state.new_baseline_year > year)
        return true;
      return this.state.new_building.energy_targets
        .map((e) => e.target_year)
        .includes(year);
    };

    const checkElectricityReduction = () => {
      if (
        this.state.new_electricity_reduction === "" ||
        typeof this.state.new_electricity_reduction !== "number"
      )
        return true;
      if (
        this.state.new_electricity_reduction < 0 ||
        this.state.new_electricity_reduction > 100
      )
        return true;
      return false;
    };

    const checkGasReduction = () => {
      if (
        this.state.new_gas_reduction === "" ||
        typeof this.state.new_gas_reduction !== "number"
      )
        return true;
      if (
        this.state.new_gas_reduction < 0 ||
        this.state.new_gas_reduction > 100
      )
        return true;
      return false;
    };

    return (
      <Modal
        hasExit
        backdropUnclickable
        style={{ minWidth: "40vw", maxWidth: "45vw", overflow: "unset" }}
        toggleOpen={() => this.setState({ energy_target_modal_open: false })}
      >
        <div className="create-energy-target-modal">
          <h5 className="title">
            Create New Energy Target for {this.state.new_building.name}
          </h5>
          <hr />

          <div className="form-container">
            <div className="form">
              <div className="form-group">
                <label className="form-control-label">
                  Target Year:<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Enter Target Year"
                  type="number"
                  className="form-control"
                  value={this.state.new_target_year}
                  onChange={(e) =>
                    this.setState({ new_target_year: +e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">
                  Baseline Year (optional):
                </label>
                <input
                  placeholder="Enter Baseline Year"
                  type="number"
                  className="form-control"
                  value={this.state.new_baseline_year}
                  onChange={(e) => {
                    this.setState({
                      new_baseline_year:
                        e.target.value === "" ? "" : +e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">
                  Electricity Reduction rate (%):
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Enter Electricity Reduction rate (%)"
                  type="number"
                  className="form-control"
                  value={this.state.new_electricity_reduction}
                  onChange={(e) =>
                    this.setState({
                      new_electricity_reduction:
                        e.target.value > 100 ? 100 : +e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-group">
                <label className="form-control-label">
                  Gas Reduction rate (%):<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  placeholder="Enter Gas Reduction rate (%)"
                  type="number"
                  className="form-control"
                  value={this.state.new_gas_reduction}
                  onChange={(e) =>
                    this.setState({
                      new_gas_reduction:
                        e.target.value > 100 ? 100 : +e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="descriptions">
              <div className="description">
                <span className="title">Target Year:</span> Please specify the
                year for which the target applies. Target Year must be after
                Baseline Year and only one target per year is allowed.
              </div>
              <div className="description">
                <span className="title">Baseline Year:</span> Year to which the
                target year will be compared to in regards to gas and
                electricity consumption. Not setting this value will result in
                rolling baseline, which will compare it to the year before the
                target year.
              </div>
              <div className="description">
                <span className="title">Electricity Reduction:</span> Rate of
                planned electricity reduction described in percentage (0-100).
              </div>
              <div className="description">
                <span className="title">Gas Reduction:</span> Rate of planned
                gas reduction described in percentage (0-100).
              </div>
            </div>
          </div>

          <div className="button-wrapper">
            <button
              className="btn btn-outline-danger"
              onClick={() => this.closeModal()}
            >
              Cancel
            </button>
            <button
              className="btn btn-outline-success"
              disabled={
                checkTargetYear(this.state.new_target_year) ||
                checkElectricityReduction() ||
                checkGasReduction()
              }
              onClick={() => this.addEnergyTarget()}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  closeModal() {
    this.setState({
      energy_target_modal_open: false,
      new_target_year: "",
      new_baseline_year: "",
      new_gas_reduction: "",
      new_electricity_reduction: "",
    });
  }

  addEnergyTarget() {
    const new_target = {
      target_year: this.state.new_target_year,
      baseline_year: this.state.new_baseline_year,
      gas_reduction: this.state.new_gas_reduction,
      electricity_reduction: this.state.new_electricity_reduction,
      new_addition: true,
    };

    this.setState({
      new_building: {
        ...this.state.new_building,
        energy_targets: [...this.state.new_building.energy_targets, new_target],
      },

      energy_target_modal_open: false,
      new_target_year: "",
      new_baseline_year: "",
      new_gas_reduction: "",
      new_electricity_reduction: "",
    });
  }

  removeEnergyTarget(target) {
    if (
      window.confirm(
        `Would you like to delete Energy Target for year ${target.target_year}?\nMake sure to press 'Update Building' to save your changes.`
      )
    ) {
      this.setState({
        building: {
          ...this.state.new_building,
          energy_targets: this.state.new_building.energy_targets.filter(
            (el) => el.target_year !== target.target_year
          ),
        },
      });
    }
  }

  render() {
    return (
      <div className="br-mainpanel br-profile-page floorplan-background" id='AddBuilding'>
        <DocumentTitle title='Add New Building' />
        <LogoSpinner loading={this.state.loading}/>
        {this.getModal()}
        <div className="br-container">
          <Row gutter={[30, 30]} className='mg-t-30'>
            <Col span={24}>
              <Title level={3}>Add New Building</Title>
              <Text type='secondary'>
                Onboard a new building to the OPNBuildings platform.
              </Text>
            </Col>
            <Col span={24}>
            <Card>
              {this.getForm()}
            </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default AddBuilding;
