import { EventEmitter } from "events";
import Dispatcher from "../dispatcher";
import Constants from "../constants";
import GeneralUtils from "../utils/GeneralUtils";
import ChartUtils from "../utils/ChartUtils";
import moment from "moment";

import { toast } from "react-toastify";


const CHANGE = "ADMIN_CHANGE";
const APPLIANCE_SAVED = "APPLIANCE_SAVED";
const APPLIANCE_UPDATED = "APPLIANCE_UPDATED";
const APPLIANCE_DELETED = "APPLIANCE_DELETED";
const CONFIG_FETCHED = "CONFIG_FETCHED";
const CONFIG_SAVED = "CONFIG_SAVED";
const BLACKLIST_FETCHED = "BLACKLIST_FETCHED";
const BLACKLIST_SAVED = "BLACKLIST_SAVED";

let _unique_points = [];
let _point_values = [];
let _mapped_point_values = [];
let _health_checks = [];
let _appliance_stats = [];
let _counter_stats = [];
let _sensor_stats = [];
let _available_appliances = [];
let _available_controllers = [];
let _available_categories = [];
let _available_properties = [];
let _config_file = "";
let _blacklist_file = [];
let _available_sensors = [];
let _available_counters = [];
let _datasource_mappings = [];

class AdminStore extends EventEmitter {
  constructor() {
    super();
    // Registers action handler with the Dispatcher.
    Dispatcher.register(this._registerToActions.bind(this));
  }

  // Switches over the action's type when an action is dispatched.
  _registerToActions(action) {
    switch (action.actionType) {
      case Constants.APPLIANCE_CONTROLLERS_FETCHED:
        this._storeApplianceControllers(action.payload);
        break;
      case Constants.UNIQUE_POINTS_FETCHED:
        this._storeUniquePoints(action.payload);
        break;
      case Constants.POINT_VALUES_FETCHED:
        this._storePointValues(action.payload);
        break;
      case Constants.MAPPED_POINT_VALUES_FETCHED:
        this._storeMappedPointValues(action.payload);
        break;
      case Constants.ADMIN_HEALTH_CHECKS_FETCHED:
        this._storeHealthChecks(action.payload);
        break;
      case Constants.APPLIANCES_FETCHED:
        this._storeAppliances(action.payload);
        break;
      case Constants.APPLIANCE_SAVED:
        this._saveAppliance(action.payload);
        break;
      case Constants.APPLIANCE_UPDATED:
        this._updateAppliance(action.payload);
        break;
      case Constants.APPLIANCE_DELETED:
        this._deleteAppliance(action.payload);
        break;
      case Constants.APPLIANCE_CONFIG_FILE_FETCHED:
        this._storeApplianceConfigFile(action.payload);
        break;
      case Constants.APPLIANCE_CONFIG_FILE_SAVED:
        this._saveApplianceConfigFile(action.payload);
        break;
      case Constants.APPLIANCE_BLACKLIST_FILE_FETCHED:
        this._storeApplianceBlacklistFile(action.payload);
        break;
      case Constants.APPLIANCE_BLACKLIST_FILE_SAVED:
        this._saveApplianceBlacklistFile(action.payload);
        break;
      case Constants.DATASOURCES_FETCHED:
        this._saveBuildingDatasources(action.payload);
        break;
      case Constants.DATASOURCE_MAPPINGS_FETCHED:
        this._saveDatasourceMappings(action.payload);
        break;
      case Constants.CSV_FILE_UPLOADED:
        this._uploadCSVFile(true);
        break;
      case Constants.CSV_FILE_UPLOAD_FAILED:
        this._uploadCSVFile(false);
        break;
      default:
        break;
    }
  }

  clear() {
    _available_categories = [];
    _available_properties = [];
    _available_controllers = [];
    _unique_points = [];
    _point_values = [];
    _health_checks = [];
    _appliance_stats = [];
    _counter_stats = [];
    _sensor_stats = [];
    _available_appliances = [];
    _config_file = "";
    _blacklist_file = [];
  }

  _storeHealthChecks(json) {
    _health_checks = [];
    _appliance_stats = [];
    _health_checks = json.services;
    _appliance_stats = json.appliances;
    _counter_stats = json.counters;
    _sensor_stats = json.sensors;
    this.emit(CHANGE);
  }

  _storeAppliances(json) {
    _available_appliances = json;
    this.emit(CHANGE);
  }

  _saveAppliance(json) {
    this.emit(APPLIANCE_SAVED);
  }

  _updateAppliance(json) {
    this.emit(APPLIANCE_UPDATED);
  }

  _deleteAppliance(json) {
    this.emit(APPLIANCE_DELETED);
  }

  _storeApplianceConfigFile(config) {
    _config_file = config;
    this.emit(CONFIG_FETCHED);
  }

  _saveApplianceConfigFile(json) {
    this.emit(CONFIG_SAVED);
  }

  _storeApplianceBlacklistFile(blacklist) {
    _blacklist_file = blacklist;
    this.emit(BLACKLIST_FETCHED);
  }

  _saveApplianceBlacklistFile(json) {
    this.emit(BLACKLIST_SAVED);
  }

  _storeApplianceControllers(json) {
    let items = [];
    for (let i = 0; i < json.length; i++) {
      items.push({ name: json[i] });
    }
    _available_controllers = items;
    this.emit(CHANGE);
  }

  _storeUniquePoints(json) {
    _unique_points = [];
    _unique_points = json;

    _available_categories = [{ name: "ALL CATEGORIES" }];
    _available_properties = [{ name: "ALL PROPERTIES" }];
    for (let i = 0; i < json.length; i++) {
      if (
        json[i].suggested_category &&
        !_available_categories.some(
          (category) =>
            category.name.toUpperCase() ===
            json[i].suggested_category.toUpperCase()
        )
      ) {
        _available_categories.push({
          name: json[i].suggested_category.toUpperCase(),
        });
      }

      if (
        json[i].suggested_property &&
        !_available_properties.some(
          (property) =>
            property.name.toUpperCase() ===
            json[i].suggested_property.toUpperCase()
        )
      ) {
        _available_properties.push({
          name: json[i].suggested_property.toUpperCase(),
        });
      }
    }
    this.emit(CHANGE);
  }

  _uploadCSVFile(result) {
    if (result === true) {
      toast.success("File uploaded successfully, the import may take some time.");
    } else {
      toast.error("File upload failed, please ensure correct file type/format and try again.");
    }
    this.emit(CHANGE);
  }

  _storePointValues(json) {
    _point_values = [];
    if (json && json.values && json.values.length > 0) {
      let name = "No Label";
      if (json.point && json.point.name) {
        name = json.point.name;
      }
      let values = ChartUtils.getLineConsumptionSeries(
        name,
        Constants.YELLOW,
        Constants.SOLID,
        2,
        true,
        true
      );
      for (let i = 0; i < json.values.length; i++) {
        values.data.push([
          moment(json.values[i].ts_sample).valueOf(),
          GeneralUtils.roundNumber(json.values[i].f_value, 2),
        ]);
      }
      _point_values.push(values);
    }
    this.emit(CHANGE);
  }

  _storeMappedPointValues(json) {
    _mapped_point_values = [];
    if (json && json.length > 0) {
      let name = "No Label";
      if (json[0].mapping) {
        name = json[0].mapping.name;
        if (json[0].mapping.entity_type) {
          name = name + " (" + json[0].mapping.entity_type.name + ")";
        }
      }
      let values = ChartUtils.getLineConsumptionSeries(
        name,
        Constants.YELLOW,
        Constants.SOLID,
        2,
        true,
        true
      );
      for (let i = 0; i < json.length; i++) {
        values.data.push([
          moment(json[i].timestamp).valueOf(),
          GeneralUtils.roundNumber(json[i].value, 2),
        ]);
      }
      _mapped_point_values.push(values);
    }
    this.emit(CHANGE);
  }

  _saveBuildingDatasources(json) {
    _available_appliances = [];
    _available_sensors = [];
    _available_counters = [];
    if (json.building_appliances) {
      for (let i = 0; i < json.building_appliances.length; i++) {
        let appliance = json.building_appliances[i];
        if (appliance.status === "ready") {
          _available_appliances.push({
            name: appliance.name,
            value: appliance.data_source.data_source_id,
          });
        }
      }
    }
    if (json.building_counters) {
      for (let i = 0; i < json.building_counters.length; i++) {
        let counter = json.building_counters[i];
        _available_counters.push({
          name: counter.device_id,
          value: counter.data_source.data_source_id,
        });
      }
    }
    if (json.building_sensors) {
      for (let i = 0; i < json.building_sensors.length; i++) {
        let sensor = json.building_sensors[i];
        _available_sensors.push({
          name: sensor.name,
          value: sensor.data_source.data_source_id,
        });
      }
    }
    this.emit(CHANGE);
  }

  _saveDatasourceMappings(json) {
    _datasource_mappings = json;
    this.emit(CHANGE);
  }

  getUniquePoints() {
    return _unique_points;
  }

  getDataSourceMappings() {
    return _datasource_mappings;
  }

  getAvailableAppliances() {
    return _available_appliances;
  }

  getAvailableSensors() {
    return _available_sensors;
  }

  getAvailableCounters() {
    return _available_counters;
  }

  getAvailableCategories() {
    return _available_categories;
  }

  getAvailableProperties() {
    return _available_properties;
  }

  getConfigFile() {
    return _config_file;
  }

  getBlacklistFile() {
    return _blacklist_file;
  }

  getAvailableControllers() {
    return _available_controllers;
  }

  getMappedPointValues() {
    return _mapped_point_values;
  }

  getPointValues() {
    return _point_values;
  }

  getHealthChecks() {
    return _health_checks;
  }

  getApplianceStats() {
    return _appliance_stats;
  }

  getCounterStats() {
    return _counter_stats;
  }

  getSensorStats() {
    return _sensor_stats;
  }

  // Hooks a React component's callback to the CHANGED, SAVED_APPLIANCE, DELETE_APPLIANCE events.
  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  addSaveApplianceListener(callback) {
    this.on(APPLIANCE_SAVED, callback);
  }

  addUpdateApplianceListener(callback) {
    this.on(APPLIANCE_UPDATED, callback);
  }

  addDeleteApplianceListener(callback) {
    this.on(APPLIANCE_DELETED, callback);
  }

  // Removes the listener from the CHANGED, SAVED_APPLIANCE, DELETE_APPLIANCE events.
  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }

  removeSaveApplianceListener(callback) {
    this.removeListener(APPLIANCE_SAVED, callback);
  }

  removeUpdateApplianceListener(callback) {
    this.removeListener(APPLIANCE_UPDATED, callback);
  }

  removeDeleteApplianceListener(callback) {
    this.removeListener(APPLIANCE_DELETED, callback);
  }

  removeSaveApplianceConfigFileListener(callback) {
    this.removeListener(CONFIG_SAVED, callback);
  }

  removeStoreApplianceConfigFileListener(callback) {
    this.removeListener(CONFIG_FETCHED, callback);
  }

  removeSaveApplianceBlacklistFileListener(callback) {
    this.removeListener(BLACKLIST_SAVED, callback);
  }

  removeStoreApplianceBlacklistFileListener(callback) {
    this.removeListener(BLACKLIST_FETCHED, callback);
  }
}

const adminStore = new AdminStore();
export default adminStore;
