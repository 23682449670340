import React from "react";
import { Modal, Button, Typography } from "antd";

/**
 * DeleteConfirmationModal Component
 *
 * Props:
 * - open: Boolean indicating if the modal is visible.
 * - onConfirm: Function to call when the user confirms deletion.
 * - onCancel: Function to call when the user cancels deletion.
 * - itemName: String representing the name of the item to be deleted.
 */
const DeleteConfirmationModal = ({ open, onConfirm, onCancel, itemName }) => {
  return (
    <Modal
      open={open}
      title="Confirm Deletion"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" danger onClick={onConfirm}>
          Delete
        </Button>,
      ]}
    >
      <Typography.Text>
        Are you sure you want to delete <strong>{itemName}</strong>? This action cannot be undone.
      </Typography.Text>
    </Modal>
  );
};

export default DeleteConfirmationModal;
